<template>
  <a-spin :spinning="spinning">
    <!-- <div class="user-container">
      <div class="table--container"> -->
      <ele-table
        ref="rt"
        :columns="columns"
        :table-data="data"
        :total-items="totalItems"
        :is-full="false"
        :actionsType="actionsType"
        :current-page="pageNumber"
        :hide-row-selection="false"
        @emitRefreshData="clearFilterAndRefresh"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      >
        <!-- 操作 -->
        <a-row :gutter="8" type="flex"
          align="middle"
          justify="space-between">
          <a-col :span="14">
            <a-input-search
              name="filterText"
              @search="searchData"
              :placeholder="l('SearchWithThreeDot')"
              enterButton
              v-model="filterText"
            />
          </a-col>
          <a-col :span="8" :offset="2">
            <a
              @click="addUser()"
              v-if="
                isGranted('Pages.Administration.OrganizationUnits.ManageUsers')
              "
            >
              <a-icon type="plus" /> {{ l("AddUser") }}
            </a>
            <a-divider
              v-if="
                isGranted('Pages.Administration.OrganizationUnits.ManageUsers')
              "
              type="vertical"
            ></a-divider>
            <a
              @click="batchDelete()"
              v-if="
                isGranted('Pages.Administration.OrganizationUnits.ManageUsers')
              "
            >
              <a-icon type="delete" />{{ l("BatchDelete") }}
            </a>
            <a-divider
              type="vertical"
              v-if="
                isGranted('Pages.Administration.OrganizationUnits.ManageUsers')
              "
            ></a-divider>
            <a :title="l('Refresh')" @click="clearFilterAndRefresh()">
              <a-icon type="reload"
            /></a>
          </a-col>
        </a-row>
      <!-- </div>
    </div> -->
    </ele-table>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { OrganizationUnitServiceProxy } from "@/shared/service-proxies";
import { ModalHelper } from "@/shared/helpers";
import Bus from "@/shared/bus/bus";
import AddMemberComponent from "../add-member/add-member";
import EleTable from "@/components/ele-table";

export default {
  name: "organization-unit-members-panel",
  mixins: [AppComponentBase],
  components: {EleTable},
  data() {
    return {
      spinning: false,
      _organizationUnitServiceProxy: null,
      selectedRowKeys: [],
      filteredInfo: null,
      sortedInfo: null,
      filterText: "",
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { maxResultCount: 50, skipCount: 0 },
      // 用户表格
      columns: [
        {
          title: this.l("UserName"),
          dataIndex: "userName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "userName" },
        },
        {
          title: this.l("RealName"),
          dataIndex: "realName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "realName" },
        },
        {
          title: this.l("AddedTime"),
          dataIndex: "addedTimeStr",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "addedTime" },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "actions" },
        },
      ],
      // 用户数据
      data: [],
    };
  },
  computed: {},
  created() {
    this._organizationUnitServiceProxy = new OrganizationUnitServiceProxy(
      this.$apiUrl,
      this.$api
    );
    // 接受树结构传过来的选中item
    Bus.$on("selectedNode", this.getTree);
    // // 添加用户成功
    // Bus.$on("saveAddMemberSuccess", data => {
    //     if (data) {
    //         this.clearFilterAndRefresh();
    //     }
    // });
    // console.log('created')
    this.initActionsType()
  },
  beforeDestroy() {
    Bus.$off("selectedNode");
  },
  methods: {
    initActionsType() {
      let _this = this;
      let obj = {
        type: "seal",
        isShow: true,
        fns: {
          delete: { granted: this.isGranted('Pages.Administration.OrganizationUnits.ManageUsers') && this.isGranted('RemoveUserFromOuWarningMessage'), name: this.l("Delete"), fn: (data) => {
            _this.removeMember(data)
          } },
        },
      };
      this.actionsType = obj;
    },
    /**
     * 选中树结构
     */
    getTree(data) {
      this.selectTree = data;
      this.getData();
    },
    /**
     * 拉取数据
     */
    getData() {
      this.spinning = true;
      this.$refs.rt.clearData();
      this._organizationUnitServiceProxy
        .getPagedOrganizationUnitUsers(
          this.selectTree.id,
          this.filterText,
          "",
          this.request.maxResultCount,
          this.request.skipCount
        )
        .finally(() => {
          this.spinning = false;
        })
        .then((result) => {
          this.data = result.items.map((o) => {
            return {
              ...o,
              addedTimeStr: o.addedTime.format("YYYY-MM-DD HH:mm:ss"),
            };
          });
          this.totalItems = result.totalCount;
          this.pagerange = [
            (this.pageNumber - 1) * this.request.maxResultCount + 1,
            this.pageNumber * this.request.maxResultCount,
          ];
          this.totalPages = Math.ceil(
            result.totalCount / this.request.maxResultCount
          );
          console.log(result);
        });
    },
    searchData() {
      this.request.skipCount = 0;
      this.getData();
    },
    handleChange(pagination, filters, sorter) {
      console.log("Various parameters", pagination, filters, sorter);
      this.filteredInfo = filters;
      this.sortedInfo = sorter;
    },
    /**
     * 清除条件并刷新
     */
    clearFilterAndRefresh() {
      this.request = { maxResultCount: 50, skipCount: 0 };
      this.filterText = "";
      this.getData();
    },
    /**
     * 分页事件
     */
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
    },
    /**
     * 移除用户
     * @param user 当前用户实体
     */
    removeMember(user) {
      console.log(user);
      const _ouId = parseInt(this.selectTree.id);
      this._organizationUnitServiceProxy.removeUser(user.id, _ouId).then(() => {
        this.$notification["success"]({
          message: this.l("SuccessfullyRemoved"),
        });
        this.clearFilterAndRefresh();
        Bus.$emit("reloadOrganizationUnitTree", true);
        // this.refreshGoFirstPage();
        // this.memberRemoved.emit([user.id]);
      });
    },
    /**
     * 清空选择
     */
    restCheckStatus() {
      this.selectedRowKeys = [];
    },
    /**
     * 批量删除
     */
    batchDelete() {
      const selectCount = this.selectedRowKeys.length;
      if (selectCount <= 0) {
        abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
        return;
      }
      this.message.confirm(
        this.l("ConfirmDeleteXItemsWarningMessage", selectCount),
        (res) => {
          if (res) {
            console.log(res);
            const _ouId = parseInt(this.selectTree.id);
            const ids = _.map(this.selectedRowKeys);
            this._organizationUnitServiceProxy
              .batchRemoveUserFromOrganizationUnit(_ouId, ids)
              .then(() => {
                // this.refreshGoFirstPage();
                this.notify.success(this.l("SuccessfullyDeleted"));
                this.clearFilterAndRefresh();
                Bus.$emit("reloadOrganizationUnitTree", true);
                // this.memberRemoved.emit(ids);
              });
          }
        }
      );
    },
    /**
     * 增加用户
     */
    addUser() {
      ModalHelper.create(AddMemberComponent, {
        organizationUnitId: parseInt(this.selectTree.id),
      }).subscribe((res) => {
        if (res) {
          this.clearFilterAndRefresh();
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.user-container {
  border: 1px solid #e8e8e8;
  margin: 20px;
  padding: 20px;
  .table-delete {
    i {
      margin-right: 10px;
    }
  }
}
.opeattion-container {
  margin: 20px 0;
}
.pagination {
  margin: 10px auto;
  text-align: right;
}
</style>
