var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('ele-table',{ref:"rt",attrs:{"columns":_vm.columns,"table-data":_vm.data,"total-items":_vm.totalItems,"is-full":false,"actionsType":_vm.actionsType,"current-page":_vm.pageNumber,"hide-row-selection":false},on:{"emitRefreshData":_vm.clearFilterAndRefresh,"emitSelectChange":_vm.updateSelectChange,"emitOnPageChange":_vm.updatePageChange,"emitShowSizeChange":_vm.updateShowSizeChange}},[_c('a-row',{attrs:{"gutter":8,"type":"flex","align":"middle","justify":"space-between"}},[_c('a-col',{attrs:{"span":14}},[_c('a-input-search',{attrs:{"name":"filterText","placeholder":_vm.l('SearchWithThreeDot'),"enterButton":""},on:{"search":_vm.searchData},model:{value:(_vm.filterText),callback:function ($$v) {_vm.filterText=$$v},expression:"filterText"}})],1),_c('a-col',{attrs:{"span":8,"offset":2}},[(
              _vm.isGranted('Pages.Administration.OrganizationUnits.ManageUsers')
            )?_c('a',{on:{"click":function($event){return _vm.addUser()}}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" "+_vm._s(_vm.l("AddUser"))+" ")],1):_vm._e(),(
              _vm.isGranted('Pages.Administration.OrganizationUnits.ManageUsers')
            )?_c('a-divider',{attrs:{"type":"vertical"}}):_vm._e(),(
              _vm.isGranted('Pages.Administration.OrganizationUnits.ManageUsers')
            )?_c('a',{on:{"click":function($event){return _vm.batchDelete()}}},[_c('a-icon',{attrs:{"type":"delete"}}),_vm._v(_vm._s(_vm.l("BatchDelete"))+" ")],1):_vm._e(),(
              _vm.isGranted('Pages.Administration.OrganizationUnits.ManageUsers')
            )?_c('a-divider',{attrs:{"type":"vertical"}}):_vm._e(),_c('a',{attrs:{"title":_vm.l('Refresh')},on:{"click":function($event){return _vm.clearFilterAndRefresh()}}},[_c('a-icon',{attrs:{"type":"reload"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }