<template>
  <a-spin :spinning="spinning">
  <section>
    <div class="modal-header">
      <div class="modal-title" v-if="pageType === 'edit'">
        <a-icon type="share-alt" />{{ l("Edit") }}:
        {{ organizationUnit.shortName }}
      </div>
      <div class="modal-title" v-else>
        <a-icon type="share-alt" />{{
          pageType === "create" ? "创建" : "查看"
        }}：扩展信息
      </div>
    </div>
    <a-form :form="form" :label-col="{ span: 9 }" :wrapper-col="{ span: 15 }">
      <a-row :gutter="24" class="form-wrapper">
        <a-col :span="12">
          <a-form-item label="组织名称">
            <a-input
              placeholder="请输入"
              allowClear
              :disabled="isDisabled"
              v-decorator="[
                'shortName',
                {
                  rules: [
                    { required: true, message: l('ThisFieldIsRequired') },
                    { max: 128, message: l('MaxLength') },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="组织性质">
            <a-select
              placeholder="请输入"
              allowClear
              :disabled="isDisabled"
              v-decorator="[
                'nature',
                {
                  rules: [
                    { required: true, message: l('ThisFieldIsRequired') },
                  ],
                },
              ]"
            >
              <a-select-option
                :value="item.key"
                v-for="item in NatureTypeData"
                :key="item.value"
              >
                {{ item.key }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24" class="form-wrapper">
        <a-col :span="12">
          <a-form-item label="编码">
            <a-input
              placeholder="请输入"
              allowClear
              :disabled="isDisabled"
              v-decorator="[
                'code',
                {
                  rules: [
                    { required: true, message: l('ThisFieldIsRequired') },
                    { max: 128, message: l('MaxLength') },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="成立时间" required>
            <a-date-picker
              :disabled="isDisabled"
              v-model="formDate"
              type="date"
              placeholder="请选择时间"
              style="width: 100%"
              @change="formDateChange"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="24" class="form-wrapper">
        <a-col :span="12">
          <a-form-item label="负责人">
            <a-input
              placeholder="请输入"
              allowClear
              :disabled="isDisabled"
              v-decorator="[
                'headName',
                {
                  rules: [
                    { max: 128, message: l('MaxLength') },
                  ],
                },
              ]"
            /> </a-form-item
        ></a-col>
        <a-col :span="12">
          <a-form-item label="手机号">
            <a-input
              placeholder="请输入"
              allowClear
              :disabled="isDisabled"
              v-decorator="[
                'phone',
                {
                  rules: [
                    { max: 11, message: l('MaxLength') },
                  ],
                },
              ]"
            /> </a-form-item
        ></a-col>
      </a-row>
      <a-row :gutter="24" class="form-wrapper">
        <a-col :span="12">
          <a-form-item label="电子邮箱">
            <a-input
              placeholder="请输入"
              allowClear
              :disabled="isDisabled"
              v-decorator="[
                'email',
                {
                  rules: [
                    { max: 128, message: l('MaxLength') },
                  ],
                },
              ]"
            /> </a-form-item
        ></a-col>
        <a-col :span="12">
          <a-form-item label="传真号">
            <a-input
              placeholder="请输入"
              allowClear
              :disabled="isDisabled"
              v-decorator="[
                'faxNumber',
                {
                  rules: [
                    { max: 128, message: l('MaxLength') },
                  ],
                },
              ]"
            /> </a-form-item
        ></a-col>
      </a-row>

      <a-row :gutter="24" class="form-wrapper">
        <a-col :span="12">
          <a-form-item label="通讯地址">
            <a-input
              placeholder="请输入"
              allowClear
              :disabled="isDisabled"
              v-decorator="[
                'address',
                {
                  rules: [
                    { required: true, message: l('ThisFieldIsRequired') },
                    { max: 128, message: l('MaxLength') },
                  ],
                },
              ]"
            /> </a-form-item
        ></a-col>
        <a-col :span="12">
          <a-form-item label="邮政编码">
            <a-input
              placeholder="请输入"
              allowClear
              :disabled="isDisabled"
              v-decorator="[
                'postalCode',
                {
                  rules: [
                    { max: 128, message: l('MaxLength') },
                  ],
                },
              ]"
            /> </a-form-item
        ></a-col>
      </a-row>

      <a-row :gutter="24" class="form-wrapper">
        <a-col :span="12">
          <a-form-item label="纳税人识别号">
            <a-input
              placeholder="请输入"
              allowClear
              :disabled="isDisabled"
              v-decorator="[
                'taxpayerNumber',
                {
                  rules: [
                    { required: true, message: l('ThisFieldIsRequired') },
                    { max: 128, message: l('MaxLength') },
                  ],
                },
              ]"
            /> </a-form-item
        ></a-col>
        <a-col :span="12"
          ><a-form-item label="备注">
            <a-input
              placeholder="请输入"
              allowClear
              :disabled="isDisabled"
              v-decorator="[
                'remark',
                {
                  rules: [
                    { max: 128, message: l('MaxLength') },
                  ],
                },
              ]"
            /> </a-form-item
        ></a-col>
      </a-row>

      <a-row :gutter="24" class="form-wrapper">
        <a-col :span="12">
          <a-form-item label="每月单人发放上限金额">
            <a-input-number
              placeholder="请输入"
              allowClear
              :disabled="isDisabled"
              :min="0.01" :step="0.01"
              v-decorator="[
                'monthlyLimitAmount',
                {
                  rules: [
                    { required: true, message: l('ThisFieldIsRequired') },
                  ],
                },
              ]"
            />
            </a-form-item>
        </a-col>
        <a-col :span="12">
          <!-- // v-if="pageType === 'preview'" -->
          <a-form-item label="附件资源">
            <a-upload
              :action="uploadUrl"
              :multiple="true"
              :data="{ subdir: this.subdirName }"
              :file-list="fileList"
              :showUploadList="{
                showPreviewIcon: true, showRemoveIcon: !isDisabled 
              }"
              @change="handleUploadChange"
            >
              <a-button :disabled="isDisabled" v-if="!isDisabled">
                <a-icon type="upload" /> 上传附件
              </a-button>
            </a-upload>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <div class="modal-footer" v-if="pageType !== 'preview'">
      <a-button :disabled="saving" @click="close()" type="button">
        <a-icon type="close-circle" />
        {{ l("Cancel") }}
      </a-button>
      <a-button :loading="saving" :type="'primary'" @click="handleSubmit()">
        <a-icon type="save" />
        {{ l("Save") }}
      </a-button>
    </div>
  </section>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalComponentBase } from "@/shared/component-base";
import { OrganizationUnitsServiceProxy } from "../services/organization-units-proxies";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";
import { ProjectServiceProxy } from "@/shared/service-proxies";

export default {
  name: "add-extend",
  mixins: [AppComponentBase, ModalComponentBase],
  data() {
    return {
      spinning: false,
      // 表单
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      extendService: null,
      formDate: null,
      // 上传文件
      fileList: [],
      uploadUrl: "",
      subdirName: "",
      pageType: "", // preview,edit,create
      isDisabled: false,
      previewFileList: [],
      Proxy: null,
      NatureTypeData: []
    };
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this.extendService = new OrganizationUnitsServiceProxy(
      this.$apiUrl,
      this.$api
    );
    this.Proxy = new ProjectServiceProxy(this.$apiUrl, this.$api);
    this.uploadUrl = AppConsts.uploadBaseUrl + "/API/Upload";
    this.subdirName = environment.production ? "abpProd" : "abpDev";
    this.GetNatureType();
  },
  mounted() {
    // 修改
    if (this.organizationUnit.id) {
      this.$nextTick(() => {
        this._getOrganizationExtendById(this.organizationUnit.id)
      });
    }
  },
  methods: {
    async _getOrganizationExtendById(id) {
      this.spinning = true;
      try {
        let res = await this.extendService.getOrganizationExtendById(id);
        // console.log(res)
        this.form.setFieldsValue({...res, monthlyLimitAmount: res.monthlyLimitAmount ? res.monthlyLimitAmount : 92000})
        this.formDate = res.foundedTime.split("T")[0];
        this.pageType = this.organizationUnit.type;
        this.isDisabled = this.organizationUnit.type === "preview";
        if (res.attachmenResources && res.attachmenResources.indexOf(",") !== -1) {
          let arr = res.split(",");
          arr.map((item, i) => {
            let n = item.split("/");
            this.fileList.push({
              uid: `-${i + 1}`,
              url: `${AppConsts.uploadBaseUrl}${item}`,
              name: n[n.length - 1],
              response: {data: {Path: item}},
              status: 'done',
            });
          });
        } else if (res.attachmenResources && res.attachmenResources !== 'undefined') {
          let n = res.attachmenResources.split("/");
          this.fileList.push({
            uid: `-1`,
            url: `${AppConsts.uploadBaseUrl}${res.attachmenResources}`,
            name: n[n.length - 1],
            response: {data: {Path: res.attachmenResources}},
            status: 'done',
          });
        }
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    // 上传组件 @change
    handleUploadChange(info) {
      let fileList = [...info.fileList];
      fileList = fileList.map((file) => {
        if (file.response) {
          console.log(file)
          file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
        }
        console.log(file)
        return file;
      });
      this.fileList = fileList;
    },
    // 日期picker @change
    formDateChange(date, dateString) {
      console.log(date, dateString);
      this.formDate = dateString;
    },
    // 提交表单
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
          if (!this.formDate) {
            this.$message.info("请选择成立日期");
            return;
          }
          if (values.phone && values.phone.length !== 11) {
            this.$message.info("请正确输入电话号码");
            return
          }
          // if (!this.fileList.length) {
          //   this.$message.info("请上传附件资源");
          //   return;
          // }
          this.createEditUnit(values);
        }
      });
    },
    // 创建
    async createEditUnit(params) {
      this.saving = true;
      let fileUrlList = [];
      this.fileList.map((item) => {
        fileUrlList.push(item.response.data.Path);
      });
      // console.info(params);
      let attachmenResources =
        fileUrlList.length > 1 ? fileUrlList.join(",") : `${fileUrlList[0]}`;
      try {
        let res = await this.extendService.saveOrganizationExtend({
          ...params,
          organizationUnitId: this.organizationUnit.parentId,
          id: this.organizationUnit.id ? this.organizationUnit.id : null,
          foundedTime: this.formDate,
          attachmenResources: attachmenResources,
        });
        this.saving = false;
        this.$notification["success"]({
          message: this.l("SavedSuccessfully"),
        });
        this.success(res);
      } catch (error) {
        this.saving = false;
      }
    },
    GetNatureType () {
      this.Proxy.getEnumKeyValueList(2).then((res) => {
        if (res && res.length > 0) {
          let array = res;
          for (let i = 0; i < array.length; i++) {
            let item = array[i];
            this.NatureTypeData.push(item);
          }
        }
      });
    }
  },
};
</script>

<style scoped lang="less">
@import "../../../../styles/common.less";

.modal-header {
  .anticon-share-alt {
    margin-right: 10px;
  }
}
.form-wrapper {
  .ant-form-item {
    margin-bottom: 14px;
  }
  .preview-list {
    margin-bottom: 0;
    padding: 0;
    li {
      list-style: none;
      line-height: 30px;
      a {
        color: @color-lightblack;
        &:hover {
          color: @color-blue;
        }
      }
    }
  }
}
</style>
