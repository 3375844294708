<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <span>{{ l("编辑招行云直连账户") }}</span>
      </div>
    </div>
    <div style="width:100%;max-height: 500px; overflow-y: auto;">
      <a-form :form="form" @submit="handleSubmit" layout="horizontal" style="width:98%">
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item :label="l('通道名称')">
              <a-input :placeholder="l('请填写通道名称')" maxlength="50" v-decorator="['name', {
                rules: [{ required: true, message: '请填写通道名称' }, { max: 50, message: '通道名称不能超过50个字符' }]
              }]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :label="l('银行卡号')">
              <a-input :placeholder="l('请填写银行卡号')" maxlength="50" v-decorator="['bankNo', {
                rules: [{ required: true, message: '请填写银行卡号' }, { max: 50, message: '银行卡号不能超过50个字符' }]
              }]" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item :label="l('业务代码')">
              <a-input :placeholder="l('请填写业务代码')" maxlength="50" v-decorator="['businessNum', {
                rules: [{ required: true, message: '请填写业务代码' }, { max: 50, message: '业务代码不能超过50个字符' }]
              }]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :label="l('用户ID')">
              <a-input :placeholder="l('请填写用户ID')" maxlength="50" v-decorator="['uID', {
                rules: [{ required: true, message: '请填写用户ID' }, { max: 50, message: '用户ID不能超过50个字符' }]
              }]" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item :label="l('网银基地址')">
              <a-input :placeholder="l('请填写网银基地址')" maxlength="200" v-decorator="['host', {
                rules: [{ required: true, message: '请填写网银基地址' }]
              }]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :label="l('对称秘钥')">
              <a-input :placeholder="l('请填写对称秘钥')" maxlength="200" v-decorator="['isvKey', {
                rules: [{ required: true, message: '请填写对称秘钥' }]
              }]" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item :label="l('SM私钥')">
              <a-input :placeholder="l('请填写SM私钥')" maxlength="200" v-decorator="['smPrivateKey', {
                rules: [{ required: true, message: '请填写SM私钥' }]
              }]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :label="l('SM公钥')">
              <a-input :placeholder="l('请填写SM公钥')" maxlength="200" v-decorator="['smPublicKey', {
                rules: [{ required: true, message: '请填写SM公钥' }]
              }]" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item :label="l('代发业务代码')">
              <a-input :placeholder="l('请填写代发业务代码')"
                v-decorator="['businessCode', { rules: [{ required: true, message: '请填写代发业务代码' }] }]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :label="l('代发业务模式编号')">
              <a-select :placeholder="l('请选择代发业务模式编号')" v-decorator="['businessModelCode']" :options="businessData"
                showSearch showClear style="width:90%">
              </a-select>
              <a @click="getData(1)" style="margin-left: 1%;">
                <a-tooltip :title="l('填写完上述数据之后，加载可经办的业务模式列表')">
                  <a-icon type="reload" />
                </a-tooltip>
              </a>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item :label="l('是否开通交易子户')">
              <a-select :placeholder="l('请选择是否开通交易子户')" @change="changeIsOpenSubAccount"
                :options="[{ value: '1', label: '是', selected }, { value: '0', label: '否' }]"
                v-decorator="['isOpenSubAccount']">
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24" v-show="openBillUnit">
          <a-col :span="12">
            <a-form-item :label="l('交易子户业务代码')">
              <a-input :placeholder="l('请填写交易子户业务代码')" v-decorator="['billUnitBusinessModelCode']" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :label="l('交易子户业务模式编号')">
              <a-select :placeholder="l('请选择交易子户业务模式编号')" v-decorator="['billUnitBusinessCode']" :options="businessData1"
                showSearch showClear style="width:90%">
              </a-select>
              <a @click="getData(2)" style="margin-left: 1%;">
                <a-tooltip :title="l('填写完上述数据之后，加载可经办的业务模式列表')">
                  <a-icon type="reload" />
                </a-tooltip>
              </a>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item :label="l('扩展参数')">
              <a-textarea :rows="4" :placeholder="l('请填写扩展参数')" v-decorator="['extJson']" style="resize:none;" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col span="24">
            <a-form-item class="btn--container" style="text-align: center">
              <a-button type="button" @click="close()">
                {{ l("Cancel") }}
              </a-button>
              <a-button type="primary" html-type="submit">
                {{ l("Save") }}
              </a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";

export default {
  mixins: [ModalComponentBase],
  name: "create-or-edit-signaccount",
  data () {
    return {
      spinning: false,
      openBillUnit: true,
      businessData: [],
      businessData1: [],
    };
  },
  beforeCreate () {
    this.form = this.$form.createForm(this, { name: "register" });
  },
  created () {
    this.fullData(); // 模态框必须,填充数据到data字段
    setTimeout(() => {
      this.form.setFieldsValue({
        isOpenSubAccount: '1'
      });
    }, 500);

  },
  methods: {
    changeIsOpenSubAccount (e) {
      this.openBillUnit = e === '1' ? true : false;
    },
    getData (type) {
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.spinning = true;
          let url = this.$apiUrl + "/api/services/app/CustomerProvideConfig/GetBusinessModeList";
          let options = {
            data: {
              ...values
            },
            method: "POST",
            url: url,
            headers: {
              "Content-Type": "application/json-patch+json",
            },
          };
          if (type === 1) {
            options.data.extJson = `{\"BusinessModelCode\":\"${values.businessCode}\"}`;
          } else {
            options.data.extJson = `{\"BusinessModelCode\":\"${values.billUnitBusinessModelCode}\"}`
          }
          this.$api
            .request(options)
            .then((response) => {
              if (response.status != 200) {
                abp.message.warn(this.l("接口异常，请稍后重试"));
                this.success(false);
                return false;
              }
              let res = response.data;
              if (type === 1) {
                this.businessData = res;
                this.form.setFieldsValue({ businessModelCode: undefined });
              } else {
                this.businessData1 = res;
                this.form.setFieldsValue({ billUnitBusinessCode: undefined });
              }
            })
            .catch((e) => {
              console.error(e);
              abp.message.warn(this.l("DefaultErrorMessage"));
            })
            .finally(() => {
              this.spinning = false;
            });
        }
      });
    },
    handleSubmit (e) {
      e.preventDefault();
      const that = this;
      this.form.validateFieldsAndScroll((err, values) => {
        if (!values.businessModelCode || values.businessModelCode === '') {
          abp.message.warn('请选择代发可经办业务模式');
          return;
        }
        if (values.isOpenSubAccount === '1') {
          if (!values.billUnitBusinessCode || values.billUnitBusinessCode === '') {
            abp.message.warn('请填写交易子户业务代码');
            return;
          }
          if (!values.billUnitBusinessModelCode || values.billUnitBusinessModelCode === '') {
            abp.message.warn('请选择交易子户业务模式编号');
            return;
          }
        }

        if (!err) {
          that.spinning = true;
          const data = {
            ...values,
            organizationUnitId: that.id
          };
          if (!data.extJson || data.extJson === '') {
            data.extJson = '{\"IsLog\":true}';
          }
          if (data.extJson && data.extJson !== '') {
            var json = JSON.parse(data.extJson);
            json["NotOpenBillUnit"] = !that.openBillUnit;
            json['BusinessCode'] = data.businessCode;
            json["BillUnitBusinessCode"] = data.billUnitBusinessCode;
            json["BillUnitBusinessModelCode"] = data.billUnitBusinessModelCode;
            data.extJson = JSON.stringify(json);

            delete data.businessCode;
            delete data.billUnitBusinessCode;
            delete data.billUnitBusinessModelCode;
          }

          const options = {
            url: `${that.$apiUrl}/api/services/app/CustomerProvideConfig/CreateMerchantSaaSAccount`,
            method: 'POST',
            data: data,
            headers: {
              "Content-Type": "application/json-patch+json",
            },
          };
          that.$api.request(options).then(response => {
            if (response.status !== 200) {
              abp.message.error(that.l("服务异常，请稍后重试"));
              return;
            }
            abp.message.success(that.l("保存成功"));
            that.success(true);
          });
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
.btn--container .ant-form-item-children {
  display: block;
  text-align: center;
}

.pleaseSelect-text {
  font-size: 14px;
  padding: 0 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0;
}
</style>
