var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('a-spin',{attrs:{"spinning":_vm.loadingDetail}},[_c('div',{staticClass:"modal-header"},[(_vm.pageType === 'edit')?_c('div',{staticClass:"modal-title"},[_c('a-icon',{attrs:{"type":"share-alt"}}),_vm._v(_vm._s(_vm.l("Edit"))+": "+_vm._s(_vm.organizationUnit.shortName)+" ")],1):_c('div',{staticClass:"modal-title"},[_c('a-icon',{attrs:{"type":"share-alt"}}),_vm._v(_vm._s(_vm.pageType === "create" ? "创建" : "查看")+":岗位管理 ")],1)]),_c('a-form',{attrs:{"form":_vm.form}},[_c('a-row',{staticClass:"form-wrapper",attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"岗位名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'positionName',
                {
                  rules: [
                    { required: true, message: _vm.l('ThisFieldIsRequired') },
                    { max: 128, message: _vm.l('MaxLength') } ],
                } ]),expression:"[\n                'positionName',\n                {\n                  rules: [\n                    { required: true, message: l('ThisFieldIsRequired') },\n                    { max: 128, message: l('MaxLength') },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"请输入","allowClear":"","disabled":_vm.isDisabled}})],1)],1),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"岗位编码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['positionCode']),expression:"['positionCode']"}],attrs:{"placeholder":"[系统生成]","allowClear":"","disabled":true}})],1)],1),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"上级岗位"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'parentId',
                {
                  rules: [],
                } ]),expression:"[\n                'parentId',\n                {\n                  rules: [],\n                },\n              ]"}],attrs:{"placeholder":"请输入","allowClear":"","options":_vm.positionOptions,"disabled":_vm.isDisabled}})],1)],1),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark']),expression:"['remark']"}],attrs:{"rows":4,"placeholder":"请输入","allowClear":"","disabled":_vm.isDisabled}})],1)],1)],1)],1),(_vm.pageType !== 'preview')?_c('div',{staticClass:"modal-footer"},[_c('a-button',{attrs:{"disabled":_vm.saving,"type":"button"},on:{"click":function($event){return _vm.close()}}},[_c('a-icon',{attrs:{"type":"close-circle"}}),_vm._v(" "+_vm._s(_vm.l("Cancel"))+" ")],1),_c('a-button',{attrs:{"loading":_vm.saving,"type":'primary'},on:{"click":function($event){return _vm.handleSubmit()}}},[_c('a-icon',{attrs:{"type":"save"}}),_vm._v(" "+_vm._s(_vm.l("Save"))+" ")],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }