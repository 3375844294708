<template>
    <a-spin :spinning="spinning">
        <div class="modal-header">
            <div class="modal-title">{{l('租户个性化信息')}} <span v-if="entity">: {{entity.systemName}}</span></div>
        </div>
        
        <a-form :form="form" @submit="handleSubmit">
            <a-form-item :label="l('后台系统名称')">
                <a-input
                    :placeholder="l('后台系统名称')"
                    v-decorator="[
                        'systemName',
                        {
                            rules: [
                                {
                                    required: true,
                                message: l('ThisFieldIsRequired'),
                                }
                            ],
                        },
                        ]" />
            </a-form-item> 
            <a-form-item :label="l('后台系统头像')">
            <a-upload
              :action="uploadUrl"
              :multiple="false"
              :data="{ subdir: this.subdirName }"
              list-type="picture"
              :file-list="fileList"
              :showUploadList="{
                showPreviewIcon: true,
                showRemoveIcon: !isDisabled,
              }"
              @change="handleUploadChange"
            >
              <a-button v-if="!isDisabled">
                <a-icon type="upload" /> 上传附件
              </a-button>
            </a-upload>
            </a-form-item>    
            <a-form-item :label="l('登录页面Logo')">
            <a-upload
              :action="uploadUrl"
              :multiple="false"
              :data="{ subdir: this.subdirName }"
              list-type="picture"
              :file-list="fileList1"
              :showUploadList="{
                showPreviewIcon: true,
                showRemoveIcon: !isDisabled,
              }"
              @change="handleUploadChange1"
            >
              <a-button v-if="!isDisabled">
                <a-icon type="upload" /> 上传附件
              </a-button>
            </a-upload>
            </a-form-item>     
            <a-form-item :label="l('租户入口链接')">
                <a-input
                    :placeholder="l('租户入口链接')"
                    v-decorator="[
                        'tenantEntranceLink',
                        {
                            rules: [
                                {
                                    required: true,
                                    message: l('ThisFieldIsRequired'),
                                }
                            ],
                        },
                        ]" />
            </a-form-item>                          
             <a-form-item :label="l('关于我们链接')">
                <a-input
                    :placeholder="l('关于我们链接')"
                    v-decorator="[
                        'tenantAboutUsLink',
                        {
                            rules: [
                                {
                                    required: true,
                                message: l('ThisFieldIsRequired'),
                                }
                            ],
                        },
                        ]" />
            </a-form-item>
            <a-form-item :label="l('隐私链接')">
                <a-input
                    :placeholder="l('隐私链接')"
                    v-decorator="[
                        'tenantPrivacyLink',
                        {
                            rules: [
                                {
                                    required: true,
                                message: l('ThisFieldIsRequired'),
                                }
                            ],
                        },
                        ]" />
            </a-form-item>
            <a-form-item :label="l('条款链接')">
                <a-input
                    :placeholder="l('条款链接')"
                    v-decorator="[
                        'tanantClauseLink',
                        {
                            rules: [
                                {
                                    required: true,
                                message: l('ThisFieldIsRequired'),
                                }
                            ],
                        },
                        ]" />
            </a-form-item>
               <a-form-item :label="l('出品公司名称')">
                <a-input
                    :placeholder="l('出品公司名称')"
                    v-decorator="[
                        'productionCompanyName',
                        {
                            rules: [
                                {
                                    required: true,
                                message: l('ThisFieldIsRequired'),
                                }
                            ],
                        },
                        ]" />
            </a-form-item>
            <a-form-item :label="l('公司网页地址')">
                <a-input
                    :placeholder="l('公司网页地址')"
                    v-decorator="[
                        'productionCompanyWebAdress',
                        {
                            rules: [
                                {
                                    required: true,
                                message: l('ThisFieldIsRequired'),
                                }
                            ],
                        },
                        ]" />
            </a-form-item>
            <br>
            <a-form-item class="btn--container">
                <a-button type="button" @click="close()">
                    {{ l("Cancel") }}
                </a-button>
                <a-button type="primary" html-type="submit">
                    {{ l('Save') }}
                </a-button>
            </a-form-item>
        </a-form>
    </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import EditionCombo from "../../shared/edition-combo/edition-combo.vue";
import {TenantServiceProxy} from "@/shared/service-proxies";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";

export default {
    mixins: [ModalComponentBase],
    name: "tenant-personalization-component",
    data() {
        return {
            spinning: false,
            // 获取到的数据
            entity: {},
            tenantId: null,
            // 上传文件
           fileList: [],
           uploadUrl: "",
           subdirName: "",
           fileList1: [],
          isDisabled: false,
        };
    },
    components: {
        EditionCombo
    },
    // watch: {
    //     entityId(val) {
    //         if (val) {
    //             console.log(val);
    //         }
    //     }
    // },
    beforeCreate() {
        this.form = this.$form.createForm(this, { name: "register" });
    },
    created() {
        this.fullData(); // 模态框必须,填充数据到data字段
        this.uploadUrl = AppConsts.uploadBaseUrl + "/API/Upload";
       this.subdirName = environment.production ? "abpProd" : "abpDev";
    },
    mounted() {
        this.getData();
    },
    methods: {
    handleUploadChange(info) {
      console.log(info);
      if (!this.entity.id) {
        let isUpload = this.beforeUpload(info.file);
        if (!isUpload) {
          return false;
        }
      }
      let fileList = [...info.fileList];
      if (fileList.length > 1) {
        this.$message.error("只能上传一张照片!");
        return false;
      }
      fileList = fileList.map((file) => {
        if (file.response) {
          file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
        }
        return file;
      });
      this.fileList = fileList;
      // console.log(this.fileList);
    },
    handleUploadChange1(info) {
      console.log(info);
      if (!this.entity.id) {
        let isUpload = this.beforeUpload(info.file);
        if (!isUpload) {
          return false;
        }
      }
      let fileList1 = [...info.fileList];
      if (fileList1.length > 1) {
        this.$message.error("只能上传一张照片!");
        return false;
      }
      fileList1 = fileList1.map((file) => {
        if (file.response) {
          file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
        }
        return file;
      });
      this.fileList1 = fileList1;
      // console.log(this.fileList);
    },
     // 上传前校验
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("只能上传jpg/png格式的照片!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片不得大于2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
        /**
         * 获取数据
         */
        getData() {
            this.spinning = true;
            this._tenantService = new TenantServiceProxy(
                this.$apiUrl,
                this.$api
            );
            this._tenantService
                .getPersonalizationForEdit(this.entityId)
                .finally(() => {
                    this.spinning = false;
                })
                .then(res => {
                    this.entity = res;
                    //setTimeout(() => {
                       this.form.setFieldsValue({                             
                                  ...res,
                            });
                        if (this.entity.systemHeadImage && this.entity.systemHeadImage.indexOf(",") !== -1) {
                                 let arr = this.entity.systemHeadImage.split(",");
                                 arr.map((item, i) => {
                                let n = item.split("/");
                                this.fileList.push({
                                uid: `-${i + 1}`,
                                url: `${AppConsts.uploadBaseUrl}${item}`,
                                name: n[n.length - 1],
                                 response: { data: { Path: item } },
                                 status: "done",
                               });
                               });
                       } else if (this.entity.systemHeadImage && this.entity.systemHeadImage !== "undefined") {
                        let n = this.entity.systemHeadImage.split("/");
                        this.fileList.push({
                        uid: `-1`,
                         url: `${AppConsts.uploadBaseUrl}${this.entity.systemHeadImage}`,
                        name: n[n.length - 1],
                        response: { data: { Path: this.entity.systemHeadImage } },
                        status: "done",
                             });
                        }   
                        
                        if (this.entity.tenantLoinLogo && this.entity.tenantLoinLogo.indexOf(",") !== -1) {
                                 let arr = this.entity.tenantLoinLogo.split(",");
                                 arr.map((item, i) => {
                                let n = item.split("/");
                                this.fileList1.push({
                                uid: `-${i + 1}`,
                                url: `${AppConsts.uploadBaseUrl}${item}`,
                                name: n[n.length - 1],
                                 response: { data: { Path: item } },
                                 status: "done",
                               });
                               });
                       } else if (this.entity.tenantLoinLogo && this.entity.tenantLoinLogo !== "undefined") {
                        let n = this.entity.tenantLoinLogo.split("/");
                        this.fileList1.push({
                        uid: `-1`,
                         url: `${AppConsts.uploadBaseUrl}${this.entity.tenantLoinLogo}`,
                        name: n[n.length - 1],
                        response: { data: { Path: this.entity.tenantLoinLogo } },
                        status: "done",
                             });
                        }                 
                    //}, 300);
                });
        },     
        /**
         * 提交表单
         */
        handleSubmit(e) {
            e.preventDefault();
            let fileUrlList = [];
           this.fileList.map((item) => {
           fileUrlList.push(item.response.data.Path);
           });
           console.log(fileUrlList);
           let images=fileUrlList.length > 1 ? fileUrlList.join(",") : `${fileUrlList[0]}`;
           let fileUrlList1 = [];
           this.fileList1.map((item) => {
           fileUrlList1.push(item.response.data.Path);
           });
           console.log(fileUrlList);
           let images1 =fileUrlList1.length > 1 ? fileUrlList1.join(",") : `${fileUrlList1[0]}`;
           this.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                   let otherobj = {
                        sysTenantId: this.entityId,
                        id : this.entity.id,
                        systemHeadImage:images,
                        tenantLoinLogo:images1
                    };
                    values = Object.assign(values, otherobj);
                    this.spinning = true;
                    this._tenantService = new TenantServiceProxy(
                        this.$apiUrl,
                        this.$api
                    );
                    this._tenantService
                        .CreateorUpdate(values)
                        .finally(() => {
                            this.spinning = false;
                        })
                        .then(res => {
                            this.$notification["success"]({
                                message: this.l("SavedSuccessfully")
                            });
                            this.success();
                        })
                        .catch(err => {

                            console.log(err);
                        });
                }
            });
        }
    }
};
</script>
<style lang="less" scoped>
/deep/.btn--container .ant-form-item-children {
    display: block;
    margin: 10px auto;
    text-align: center;
}
</style>