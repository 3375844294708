<template>
  <div class="project-auth">
    <a-spin :spinning="spinning">
      <react-table
        ref="listTable"
        :table-data="tableData"
        :total-items="totalItems"
        :showTotal="showTotalFun"
        :actions-type="actionsType"
        :columns="columns"
        :hide-row-selection="true"
        :scroll="{x: 0}"
        :current-page="pageNumber"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      >
        <a-row
          :gutter="8"
          type="flex"
          align="middle"
        >
          <!-- <a-col :span="2" style="text-align: right;">授权状态:</a-col> -->
          <a-col :span="6">
            <a-select
              style="width: 95%"
              @change="statusChange"
              placeholder="请选择"
              v-model="status"
              allowClear
            >
              <a-select-option
                :value="it.value"
                v-for="(it, i) in statusOption"
                :key="i"
              >
                {{ it.label }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="6">
            <a-input allowClear v-model="filterCode" :placeholder="l('请输入项目编码关键字')" />
          </a-col>
          <a-col :span="6">
            <a-input allowClear v-model="filterText" :placeholder="l('请输入项目名称关键字')" />
          </a-col>
          <a-col :span="6" >
            <a-button @click="search" >
              {{ l("Search") }}
            </a-button>
            <a-button  @click="clearFilterAndRefresh">{{ l("Reset") }}</a-button>
            <!-- <a-button type="primary" @click="_saveProjectAuthorized" :disabled="!selectedRows.length">{{ l("授权") }}</a-button> -->
          </a-col>
        </a-row>
      </react-table>

    </a-spin>
  </div>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CommonServiceProxy } from "@/shared/common-service";
import moment from "moment";
import ReactTable from "@/components/react-table";
// import ReactModify from "@/components/react-modify";
// import { AppConsts } from "@/abpPro/AppConsts";

export default {
  mixins: [AppComponentBase],
  props: [
    'userId'
  ],
  components: {
    ReactTable,
  },
  data() {
    return {
      spinning: false,
      commonService: null,
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 30, skipCount: 0 }, //sorting {排序项 ASC/DESC}
      currentPage: 1,
      actionsType: {},
      columns: [],
      tableData: [],
      allData: [],
      authedData: [],
      noauthedData: [],
      filterText: '',
      filterCode: '',
      status: '2',
      statusOption: [
        {label: '全部', value: '2'},
        {label: '已授权', value: '1'},
        {label: '未授权', value: '0'},
      ],
      selectedRowKeys: [],
      selectedRows: [],

    }
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this._getListByUserId()
    this.initColums()
  },
  methods: {
    initColums() {
      let _this = this
      this.columns = [
        {
          title: this.l("项目名称"),
          dataIndex: "projectName",
          align: "center",
          scopedSlots: { customRender: "projectName" },
        },
        {
          title: this.l("项目编码"),
          dataIndex: "projectCode",
          align: "center",
          scopedSlots: { customRender: "projectCode" },
        },
        {
          title: this.l("是否已授权"),
          dataIndex: "isAuthDesc",
          align: "center",
          scopedSlots: { customRender: "isAuthDesc" },
        },
        {
          title: '操作',
          dataIndex: "",
          sorter: false,
          align: "center",
          customRender: function (text, record, index) {
            let child = _this.$createElement("a",
            {
              domProps: {
                innerHTML: record.isAuth ? '取消授权' : '授权'
              },
              on: {
                click: function () {
                  if (record.isAuth) {
                    _this._deleteProjectAuthorized(record.id)
                  } else {
                    _this._saveProjectAuthorized(record.id)
                  }
                },
              },
              style: {},
              attrs: {}
            })
            let obj = {
              children: child,
              attrs: {},
              on: {},
            };
            return obj
            
          },
          scopedSlots: { customRender: "" },
        },
      ]
    },
    search() {
      this.pageNumber = 1
      this.request = { sorting: "", maxResultCount: 30, skipCount: 0 };
      this.getData();
    },
    getData() {
      this.spinning = true;
      try {
        let sIndex = this.request.skipCount
        let eIndex = this.request.skipCount + this.request.maxResultCount

        let oArr = []
        if (this.status === '0') {
          oArr = this.noauthedData
        } else if (this.status === '1') {
          oArr = this.authedData
        } else if (this.status === '2') {
          oArr = this.allData
        }

        // console.log('oArr:', oArr)

        let arr = []
        // let arr = oArr.filter((v, i) => i >= sIndex && i < eIndex)

        // console.log('arr1', arr)

        if (this.filterText) {
          this.filterText = this.filterText.trim()
          // console.log('filterText:', this.filterText)
          oArr = oArr.filter(v => v.projectName.indexOf(this.filterText) !== -1)
        }
        
        if (this.filterCode) {
          this.filterCode = this.filterCode.trim()
          // console.log('filterText:', this.filterText)
          oArr = oArr.filter(v => v.projectCode.indexOf(this.filterCode) !== -1)
        }

        arr = oArr.filter((v, i) => i >= sIndex && i < eIndex)
        // console.log('search', arr)

        this.tableData = arr

        this.totalItems = oArr.length;
        this.pagerange = [
          (this.pageNumber - 1) * this.request.maxResultCount + 1,
          this.pageNumber * this.request.maxResultCount,
        ]
        this.totalPages = Math.ceil(
          oArr.length / this.request.maxResultCount
        )

        this.spinning = false
      } catch(error) {
        console.log(error)
        this.spinning = false
      }
    },
    async _getAllProject(authedData) {
      this.spinning = true
      try {
        let res = await this.commonService.get({
          url: '/api/services/app/Project/GetAllProject',
          params: {}
        })

        let arr = res.map(item => {
          if (authedData.filter(v => v.projectId === item.id).length) {
            return {
              // ...item, 
              id: item.id,
              projectName: item.projectName,
              projectCode: item.projectCode,
              isAuth: true,
              isAuthDesc: '是'
            }
          } else {
            return {
              // ...item, 
              id: item.id,
              projectName: item.projectName,
              projectCode: item.projectCode,
              isAuth: false,
              isAuthDesc: '否'
            }
          }
        })
        this.allData = arr

        this.noauthedData = arr.filter(v => v.isAuth === false)
        
        this.getData()

        this.spinning = false
      } catch (error) {
        console.log(error)
        this.spinning = false
      }

    },
    async _getListByUserId() {
      this.spinning = true
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/ProjectAuthorized/GetListByUserId",
          params: {userId: this.userId}
        })
        this.authedData = res.items.map(item => {
          return {
            id: item.projectId,
            projectName: item.project.projectName,
            projectCode: item.project.projectCode,
            isAuth: true,
            isAuthDesc: '是',
          }
        })
        this._getAllProject(res.items)
        this.spinning = false
      } catch (error) {
        console.log(error)
        this.spinning = false
      }
    },
    async _saveProjectAuthorized(projectId) {
      this.spinning = true
      const opts = {
        projectId: projectId,
        projectType: 1,
        userId: this.userId,
      }
      try {
        let res = await this.commonService.post({
          url: '/api/services/app/ProjectAuthorized/SaveProjectAuthorized',
          params: {...opts}
        })

        this.$notification['success']({
          message: '提示',
          description: `授权成功`,
        })

        this.status = '1'
        this._getListByUserId()


        this.spinning = false
      } catch (error) {
        console.log(error)
        this.spinning = false
      }
    },
    async _deleteProjectAuthorized(projectId) {
      this.spinning = true
      const opts = {
        projectId: projectId,
        userId: this.userId,
      }
      try {
        let res = await this.commonService.post({
          url: '/api/services/app/ProjectAuthorized/DeleteProjectAuthorized',
          params: {...opts}
        })

        this.$notification['success']({
          message: '提示',
          description: `取消授权成功`,
        })

        this._getListByUserId()


        this.spinning = false
      } catch (error) {
        console.log(error)
        this.spinning = false
      }
    },
    statusChange(v) {
      this.filterText = ''
      this.filterCode = ''
      // this.pageNumber = 1
      this.status = v
      this.request = { sorting: "", maxResultCount: 30, skipCount: 0 };
      this.getData()
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.selectedRows = []
      this.filterText = ''
      this.filterCode = ''
      this.pageNumber = 1
      this.status = '2'
      this.request = { sorting: "", maxResultCount: 30, skipCount: 0 };
      // this.tableData = this.allData
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = current;
      this.request.maxResultCount = size;
      this.getData();
    },
  }
}

</script>


<style scoped lang="less">
.project-auth {
}
</style> 