import 'rxjs/add/operator/finally';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import {
  GetRoleForEditOutput,
  UpdateRolePermissionsInput,
  CreateOrUpdateRoleInput,
  ListResultDtoOfRoleListDto,
  PagedResultDtoOfRoleListDto,
  CreateOrUpdateFieldRoleDTO,
  RolesListDto,
  RowFilterCreateOrUpdateDTO,
  RowFilterAddRowFilterToRoleDTO
} from './model'

import {SwaggerException} from "@/shared/service-proxies/model";

import moment from 'moment';

export class RoleServiceProxy {
  private instance: AxiosInstance;
  private baseUrl: string;
  protected jsonParseReviver:
    | ((key: string, value: any) => any)
    | undefined = undefined;

  constructor(baseUrl?: string, instance?: AxiosInstance) {
    this.instance = instance ? instance : axios.create();
    this.baseUrl = baseUrl ? baseUrl : "";
  }

  /**
   * 批量删除角色
   * @param body (optional)
   * @return Success
   */
  batchDelete(body: number[] | undefined): Promise<void> {
    let url_ = this.baseUrl + "/api/services/app/Role/BatchDelete";
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(body);

    let options_ = <AxiosRequestConfig>{
      data: content_,
      method: "POST",
      url: url_,
      headers: {
        "Content-Type": "application/json-patch+json",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.processBatchDelete(_response);
    });
  }

  protected processBatchDelete(response: AxiosResponse): Promise<void> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      return Promise.resolve<void>(<any>null);
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException(
        "An unexpected server error occurred.",
        status,
        _responseText,
        _headers
      );
    }
    return Promise.resolve<void>(<any>null);
  }

  /**
   * @param body (optional)
   * @return Success
   */
  createOrUpdate(body: CreateOrUpdateRoleInput | undefined): Promise<void> {
    let url_ = this.baseUrl + "/api/services/app/Role/CreateOrUpdate";
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(body);

    let options_ = <AxiosRequestConfig>{
      data: content_,
      method: "POST",
      url: url_,
      headers: {
        "Content-Type": "application/json-patch+json",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.processCreateOrUpdate(_response);
    });
  }

  protected processCreateOrUpdate(response: AxiosResponse): Promise<void> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      return Promise.resolve<void>(<any>null);
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException(
        "An unexpected server error occurred.",
        status,
        _responseText,
        _headers
      );
    }
    return Promise.resolve<void>(<any>null);
  }

  /**
   * @param id (optional)
   * @return Success
   */
  delete(id: number | undefined): Promise<void> {
    let url_ = this.baseUrl + "/api/services/app/Role/Delete?";
    if (id === null) {
      throw new Error("The parameter 'id' cannot be null.");
    } else if (id !== undefined) {
      url_ += "id=" + encodeURIComponent("" + id) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "DELETE",
      url: url_,
      headers: {},
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.processDelete(_response);
    });
  }

  protected processDelete(response: AxiosResponse): Promise<void> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      return Promise.resolve<void>(<any>null);
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException(
        "An unexpected server error occurred.",
        status,
        _responseText,
        _headers
      );
    }
    return Promise.resolve<void>(<any>null);
  }

  /**
   * 服务于前端RoleComboxComponent 组件信息，
   * @param permission (optional) 权限名称
   * @return Success
   */
  getAll(permission: string | undefined): Promise<ListResultDtoOfRoleListDto> {
    let url_ = this.baseUrl + "/api/services/app/Role/GetAll?";
    if (permission === null) {
      throw new Error("The parameter 'permission' cannot be null.");
    } else if (permission !== undefined) {
      url_ += "permission=" + encodeURIComponent("" + permission) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.processGetAll(_response);
    });
  }

  protected processGetAll(
    response: AxiosResponse
  ): Promise<ListResultDtoOfRoleListDto> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      let result200: any = null;
      let resultData200 = _responseText;
      result200 = ListResultDtoOfRoleListDto.fromJS(resultData200);
      return result200;
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException(
        "An unexpected server error occurred.",
        status,
        _responseText,
        _headers
      );
    }
    return Promise.resolve<ListResultDtoOfRoleListDto>(<any>null);
  }

  /**
   * @param id (optional)
   * @return Success
   */
  getForEdit(id: number | undefined): Promise<GetRoleForEditOutput> {
    let url_ = this.baseUrl + "/api/services/app/Role/GetForEdit?";
    if (id === null) {
      throw new Error("The parameter 'id' cannot be null.");
    } else if (id !== undefined) {
      url_ += "id=" + encodeURIComponent("" + id) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.processGetForEdit(_response);
    });
  }

  protected processGetForEdit(
    response: AxiosResponse
  ): Promise<GetRoleForEditOutput> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      let result200: any = null;
      let resultData200 = _responseText;
      result200 = GetRoleForEditOutput.fromJS(resultData200);
      return result200;
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException(
        "An unexpected server error occurred.",
        status,
        _responseText,
        _headers
      );
    }
    return Promise.resolve<GetRoleForEditOutput>(<any>null);
  }

  /**
   * @param permissionNames (optional) 权限名称
   * @param filterText (optional)
   * @param sorting (optional)
   * @param maxResultCount (optional)
   * @param skipCount (optional)
   * @return Success
   */
  getPaged(
    permissionNames: string[] | undefined,
    filterText: string | undefined,
    sorting: string | undefined,
    maxResultCount: number | undefined,
    skipCount: number | undefined
  ): Promise<PagedResultDtoOfRoleListDto> {
    let url_ = this.baseUrl + "/api/services/app/Role/GetPaged?";
    if (permissionNames === null) {
      throw new Error("The parameter 'permissionNames' cannot be null.");
    } else if (permissionNames !== undefined) {
      permissionNames &&
        permissionNames.forEach((item) => {
          url_ += "permissionNames=" + encodeURIComponent("" + item) + "&";
        });
    }
    if (filterText === null) {
      throw new Error("The parameter 'filterText' cannot be null.");
    } else if (filterText !== undefined) {
      url_ += "filterText=" + encodeURIComponent("" + filterText) + "&";
    }
    if (sorting === null) {
      throw new Error("The parameter 'sorting' cannot be null.");
    } else if (sorting !== undefined) {
      url_ += "sorting=" + encodeURIComponent("" + sorting) + "&";
    }
    if (maxResultCount === null) {
      throw new Error("The parameter 'maxResultCount' cannot be null.");
    } else if (maxResultCount !== undefined) {
      url_ += "maxResultCount=" + encodeURIComponent("" + maxResultCount) + "&";
    }
    if (skipCount === null) {
      throw new Error("The parameter 'skipCount' cannot be null.");
    } else if (skipCount !== undefined) {
      url_ += "skipCount=" + encodeURIComponent("" + skipCount) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.processGetPaged(_response);
    });
  }

  protected processGetPaged(
    response: AxiosResponse
  ): Promise<PagedResultDtoOfRoleListDto> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      let result200: any = null;
      let resultData200 = _responseText;
      result200 = PagedResultDtoOfRoleListDto.fromJS(resultData200);
      return result200;
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException(
        "An unexpected server error occurred.",
        status,
        _responseText,
        _headers
      );
    }
    return Promise.resolve<PagedResultDtoOfRoleListDto>(<any>null);
  }

  /**
   * @param id (optional)
   * @return Success
   */
  getRoleForEdit(id: number | undefined): Promise<GetRoleForEditOutput> {
    let url_ = this.baseUrl + "/api/services/app/Role/GetRoleForEdit?";
    if (id === null) {
      throw new Error("The parameter 'id' cannot be null.");
    } else if (id !== undefined) {
      url_ += "id=" + encodeURIComponent("" + id) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.processGetRoleForEdit(_response);
    });
  }

  protected processGetRoleForEdit(
    response: AxiosResponse
  ): Promise<GetRoleForEditOutput> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      let result200: any = null;
      let resultData200 = _responseText;
      result200 = GetRoleForEditOutput.fromJS(resultData200);
      return result200;
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException(
        "An unexpected server error occurred.",
        status,
        _responseText,
        _headers
      );
    }
    return Promise.resolve<GetRoleForEditOutput>(<any>null);
  }

  /**
   * @param body (optional)
   * @return Success
   */
  updatePermissions(
    body: UpdateRolePermissionsInput | undefined
  ): Promise<void> {
    let url_ = this.baseUrl + "/api/services/app/Role/UpdatePermissions";
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(body);

    let options_ = <AxiosRequestConfig>{
      data: content_,
      method: "PUT",
      url: url_,
      headers: {
        "Content-Type": "application/json-patch+json",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.processUpdatePermissions(_response);
    });
  }
  protected processUpdatePermissions(response: AxiosResponse): Promise<void> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      return Promise.resolve<void>(<any>null);
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException(
        "An unexpected server error occurred.",
        status,
        _responseText,
        _headers
      );
    }
    return Promise.resolve<void>(<any>null);
  }

  /**
   * 获取当前用户的FieldRole，包含当前用户是否拥有该字段权限
   * @return Success
   */
  getUserFieldRole(): Promise<RolesListDto> {
    let url_ = this.baseUrl + "/api/services/app/Role/GetUserFieldRole";
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        "Accept": "text/plain"
      }
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.processGetUserFieldRole(_response);
    });
  }
  protected processGetUserFieldRole(response: AxiosResponse): Promise<RolesListDto> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      let result200: any = null;
      let resultData200 = _responseText;
      // result200 = RolesListDto.fromJS(resultData200);
      // return result200;
      // 不需要任何转换直接返回
      return resultData200
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException("An unexpected server error occurred.", status, _responseText, _headers);
    }
    return Promise.resolve<RolesListDto>(<any>null);
  }


  /**
   * 获取对应角色的FieldRole，包含对应角色是否拥有该字段权限
   * @param 对应角色Id
   * @return Success
   */
  getFieldRoleByRoleId(roleId: string | number): Promise<RolesListDto> {
    let url_ = this.baseUrl + `/api/services/app/Role/GetFieldRoleByRoleId?roleId=${roleId}`;
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        "Accept": "text/plain"
      }
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.processGetFieldRoleByRoleId(_response);
    });
  }
  protected processGetFieldRoleByRoleId(response: AxiosResponse): Promise<RolesListDto> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      let result200: any = null;
      let resultData200 = _responseText;
      // result200 = RolesListDto.fromJS(resultData200);
      // return result200;
      // 不需要任何转换直接返回
      return resultData200
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException("An unexpected server error occurred.", status, _responseText, _headers);
    }
    return Promise.resolve<RolesListDto>(<any>null);
  }

  /**
   * 新增或者修改FieldRole
   * @return Success
   */
  createOrUpdateFieldRole(body: CreateOrUpdateFieldRoleDTO | undefined): Promise<any> {
    let url_ = this.baseUrl + "/api/services/app/Role/CreateOrUpdateFieldRole";
    url_ = url_.replace(/[?&]$/, "");
    if (body === null || !body) {
      throw new Error("The parameter 'content' cannot be null.");
    }
    url_ = url_.replace(/[?&]$/, "");

    const content_ = body;

    let options_ = <AxiosRequestConfig>{
      data: content_,
      method: "POST",
      url: url_,
      headers: {
        "Accept": "text/plain"
      }
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.processCreateOrUpdateFieldRole(_response);
    });
  }
  protected processCreateOrUpdateFieldRole(response: AxiosResponse): Promise<void> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      return Promise.resolve<void>(<any>null);
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException("An unexpected server error occurred.", status, _responseText, _headers);
    }
    return Promise.resolve<void>(<any>null);
  }

  /**
   * 根据角色id获取所有过滤器
   * @param roleId 
   */
  rowFilterGetAll(roleId: string | number): Promise<any> {
    let url_ = this.baseUrl + `/api/services/app/RowFilter/GetAll?roleId=${roleId}`;
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        "Accept": "text/plain"
      }
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.processRowFilterGetAll(_response);
    });
  }
  protected processRowFilterGetAll(response: AxiosResponse): Promise<void> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      return _responseText
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException("An unexpected server error occurred.", status, _responseText, _headers);
    }
    return Promise.resolve<void>(<any>null);
  }

  /**
   * 获取所有过滤器
   */
  rowFilterGetAllFilter(): Promise<any> {
    let url_ = this.baseUrl + `/api/services/app/RowFilter/GetAllFilter`;
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        "Accept": "text/plain"
      }
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.processRowFilterGetAllFilter(_response);
    });
  }
  protected processRowFilterGetAllFilter(response: AxiosResponse): Promise<void> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      return _responseText
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException("An unexpected server error occurred.", status, _responseText, _headers);
    }
    return Promise.resolve<void>(<any>null);
  }


  /**
   * 创建或者修改过滤器
   * @return Success
   */
  rowFilterCreateOrUpdate(body: RowFilterCreateOrUpdateDTO | undefined): Promise<any> {
    let url_ = this.baseUrl + "/api/services/app/RowFilter/CreateOrUpdate";
    url_ = url_.replace(/[?&]$/, "");
    if (body === null || !body) {
      throw new Error("The parameter 'content' cannot be null.");
    }
    url_ = url_.replace(/[?&]$/, "");

    const content_ = body;

    let options_ = <AxiosRequestConfig>{
      data: content_,
      method: "POST",
      url: url_,
      headers: {
        "Accept": "text/plain"
      }
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.processRowFilterCreateOrUpdate(_response);
    });
  }
  protected processRowFilterCreateOrUpdate(response: AxiosResponse): Promise<void> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      return Promise.resolve<void>(<any>null);
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException("An unexpected server error occurred.", status, _responseText, _headers);
    }
    return Promise.resolve<void>(<any>null);
  }


  /**
   * 删除过滤器
   * @param ids 
   */
  rowFilterDeleteFilters(ids: number[]): Promise<any> {
    let url_ = this.baseUrl + "/api/services/app/RowFilter/DeleteFilters";
    url_ = url_.replace(/[?&]$/, "");
    if (ids === null || !ids) {
      throw new Error("The parameter 'content' cannot be null.");
    }
    url_ = url_.replace(/[?&]$/, "");

    const content_ = ids;

    let options_ = <AxiosRequestConfig>{
      data: content_,
      method: "POST",
      url: url_,
      headers: {
        "Accept": "text/plain"
      }
    };
    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.processRowFilterDeleteFilters(_response);
    });
  }
  protected processRowFilterDeleteFilters(response: AxiosResponse): Promise<void> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      return Promise.resolve<void>(<any>null);
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException("An unexpected server error occurred.", status, _responseText, _headers);
    }
    return Promise.resolve<void>(<any>null);
  }

  /**
   * 给角色添加过滤器
   * @param body 
   */
  rowFilterAddRowFilterToRole(body: RowFilterAddRowFilterToRoleDTO[]): Promise<any> {
    let url_ = this.baseUrl + "/api/services/app/RowFilter/AddRowFilterToRole";
    url_ = url_.replace(/[?&]$/, "");
    if (body === null || !body) {
      throw new Error("The parameter 'content' cannot be null.");
    }
    url_ = url_.replace(/[?&]$/, "");

    const content_ = body;

    let options_ = <AxiosRequestConfig>{
      data: content_,
      method: "POST",
      url: url_,
      headers: {
        "Accept": "text/plain"
      }
    };
    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.processRowFilterAddRowFilterToRole(_response);
    });
  }
  protected processRowFilterAddRowFilterToRole(response: AxiosResponse): Promise<void> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      return Promise.resolve<void>(<any>null);
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException("An unexpected server error occurred.", status, _responseText, _headers);
    }
    return Promise.resolve<void>(<any>null);
  }


  /**
   * 移除行过滤器
   * @param body 
   */
  rowFilterRemoveRowFilter(body: RowFilterAddRowFilterToRoleDTO[]): Promise<any> {
    let url_ = this.baseUrl + "/api/services/app/RowFilter/RemoveRowFilter";
    url_ = url_.replace(/[?&]$/, "");
    if (body === null || !body) {
      throw new Error("The parameter 'content' cannot be null.");
    }
    url_ = url_.replace(/[?&]$/, "");

    const content_ = body;

    let options_ = <AxiosRequestConfig>{
      data: content_,
      method: "POST",
      url: url_,
      headers: {
        "Accept": "text/plain"
      }
    };
    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.processRowFilterRemoveRowFilter(_response);
    });
  }
  protected processRowFilterRemoveRowFilter(response: AxiosResponse): Promise<void> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      return Promise.resolve<void>(<any>null);
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException("An unexpected server error occurred.", status, _responseText, _headers);
    }
    return Promise.resolve<void>(<any>null);
  }





















}


function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
  if (result !== null && result !== undefined) {
    throw result;
  } else {
    throw new SwaggerException(message, status, response, headers, null);
  }
}
