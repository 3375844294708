<template>
    <div>
        <div style="margin: 10px 0;" type="flex">
            <a-button :disabled="saving" @click="close()" type="button">
                <a-icon type="close-circle" />
                {{ l('Cancel') }}
            </a-button>
            <a-button :loading="saving" :type="'primary'" @click="handleSubmit">
                <a-icon type="save" />
                {{ l('Save') }}
            </a-button>
        </div>
        <!-- <a-input :placeholder="l('InXSearchPlaceHolder', l('PermissionDisplayName'))" @change="onSearch" size="default">
            <a-icon slot="addonAfter" type="reload" @click.prevent="reload($event)" />
        </a-input> -->
        <!-- defaultExpandAll -->
        <a-tree
            v-if="treeData.length"
            checkable
            
            v-model="selectedPermissionVal"
            :selectedKeys="selectedKeys"
            :checkStrictly="false"
            @check="onCheck"
            :treeData="treeData" />
    </div>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { PermissionServiceProxy } from "@/shared/service-proxies";
import { arrayService } from "@/shared/utils";

export default {
    name: "search-role-tree",
    mixins: [AppComponentBase],
    props: ["multiple", "dropDownStyle", "selectedPermission"],
    data() {
        return {
            permissionService: null,
            permissions: [],
            // loading: false,
            treeData: [],
            originTreeData: [],
            originPermission: [],
            treeDataOrgin: [],
            // selectedPermissionVal: this.selectedPermission,
            selectedPermissionVal: [],
            checkedKeys: [],
            selectedKeys: [],
            filterText: "",
            // 模糊查询
            dataList: [],
            newArr: [],
        };
    },
    computed: {},
    created() {
        this.permissionService = new PermissionServiceProxy(
            this.$apiUrl,
            this.$api
        );
    },
    mounted() {
        this.$emit("emitSpinning", '1')
        this.permissionService.getAllPermissions().then(result => {
            this.permissions = result.items;
            this.permissions.map(item => {
                item.value = item.name;
            });
            this.arrToTreeNode();
        });

        // console.log('传进来的selectedPermission', this.selectedPermission)
    },
    watch: {
        checkedKeys(val) {
            console.log("onCheck", val);
        }
    },
    methods: {
        async arrToTreeNode() {
            this.loading = true;
            this.treeData = await arrayService.arrToTreeNode(this.permissions, {
                idMapName: "name",
                parentIdMapName: "parentName",
                titleMapName: "displayName"
            });

            // console.log('this.treeData', this.treeData)

            this.originTreeData = [...this.treeData]
            this.originPermission = [...this.selectedPermission]

            
            if (!this.selectedPermissionVal.length) {
                let selectedPermissionVal = []
                let _arr = this.returnTreeArr(this.selectedPermission, this.treeData)
                // console.log('_arr', _arr)
                this.selectedPermission.map((ele, index) => {
                    if (!_arr.filter(v => v === ele).length) {
                        // 获取所有父节点
                        selectedPermissionVal.push(ele)
                    }
                })
                this.selectedPermissionVal = selectedPermissionVal
                // console.log('this.selectedPermissionVal', this.selectedPermissionVal)
                let emitAuth = await this.returnEmitArr(selectedPermissionVal, this.treeData)
                // console.log('emitAuth:', emitAuth)
                this.$emit("selectedPermissionChange", emitAuth);
            }



            // 延时设置子父节点checkbox关联状态，否则有父节点选中则全部选中了
            setTimeout(() => {
                this.loading = false;
                this.$emit("emitSpinning", '0')
            }, 3000);
            this.permissions.map(item => {
                this.dataList.push({
                    key: item.name,
                    title: item.displayName
                });
            });
            this.treeDataOrgin = [...this.treeData];
        },
        /**
         * 选中
         */
        async onCheck(val, e) {
            // console.log(val)
            // console.log(e)

            this.$emit("emitSpinning", '1')
            this.selectedPermissionVal = val;
            // this.$emit("selectedPermissionChange", val);
            let emitAuth = await this.returnEmitArr(val, this.treeData)
            // console.log('emitAuth:', emitAuth)
            this.$emit("selectedPermissionChange", emitAuth);
            this.$emit("emitSpinning", '0')
        },
        returnEmitArr(curVal, arr) {
            // 第四层子节点下勾选过的权限的父权限
            let fourNodeParentAuth = this.returnTreeArrWithParent(curVal, arr)
            // 第三层子节点下勾选过的权限的父权限
            let thirdeNodeParentAuth = this.returnTreeArrWithParent(fourNodeParentAuth, arr)
            // 第二层子节点下勾选过的权限的父权限
            let secondNodeParentAuth = this.returnTreeArrWithParent(thirdeNodeParentAuth, arr)
            
            let allAuth = [...fourNodeParentAuth, ...thirdeNodeParentAuth, ...secondNodeParentAuth, ...curVal]
            let emitAuth = [...new Set(allAuth)]
            return emitAuth
        },
        /**
         * 
         * 
         * 待改递归
         * 
         * 
         */
        returnTreeArr(val, arr) {
            // val中不含有自己下面全部子选项的item
            let spArr = []
            val.map(item => {
                for (let i = 0; i < arr.length; i++) {
                    // let ak = arr[i].key
                    // if (ak === 'Pages' || ak === 'Pages.LingGong') {
                    //     console.log('ak:', ak)
                    // }
                    if (arr[i].key === item) {
                        if (arr[i].children && arr[i].children.length) {
                            let l = arr[i].children.length
                            let childKeys = arr[i].children.map(v => {return v.key})
                            let newSetArr = [...new Set(childKeys.concat(val))]
                            // console.log('childKeys:', childKeys)
                            // console.log('newSetArr:', newSetArr)
                            
                            // 
                            if (childKeys.length !== newSetArr.length) {
                                spArr.push(item)
                            }
                        }
                    } else {
                        if (arr[i].children && arr[i].children.length) {
                            let cArr = arr[i].children
                            for (let j = 0; j < cArr.length; j++) {
                                // let bk = cArr[j].key
                                // if (bk === 'Pages.Administration.Roles' || bk === 'Pages.LGModul.ProjectPersonel.Node') {
                                //     console.log('bk:', bk)
                                // }
                                if (cArr[j].key === item) {
                                    if (cArr[j].children && cArr[j].children.length) {
                                        let l = cArr[j].children.length
                                        let childKeys = cArr[j].children.map(v => {return v.key})
                                        let newSetArr = [...new Set(childKeys.concat(val))]
                                        // console.log('childKeys:', childKeys)
                                        // console.log('newSetArr:', newSetArr)
                                        if (childKeys.length !== newSetArr.length) {
                                            spArr.push(item)
                                        }
                                    }
                                } else {
                                    if (cArr[j].children && cArr[j].children.length) {
                                        let ccArr = cArr[j].children
                                        for (let k = 0; k < ccArr.length; k++) {
                                        
                                            if (ccArr[k].key === item) {
                                                if (ccArr[k].children && ccArr[k].children.length) {
                                                
                                                let l = ccArr[k].children.length
                                                let childKeys = ccArr[k].children.map(v => {return v.key})
                                                let newSetArr = [...new Set(childKeys.concat(val))]
                                                // let ck = ccArr[k].key
                                                // if (ck === 'Pages.Administration.Roles.Create' || ck === 'Pages.Administration.Roles.Edit' || ck === 'Pages.LGModule.ProjectPersonel.NotifyList') {
                                                //     console.log('ck:', ck)
                                                //     console.log('childKeys:', childKeys)
                                                //     console.log('newSetArr:', newSetArr)
                                                // }
                                                // console.log('childKeys:', childKeys)
                                                // console.log('newSetArr:', newSetArr)
                                                    if (childKeys.length !== newSetArr.length) {
                                                        spArr.push(item)
                                                    }
                                                }
                                            } else {
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            })
            return spArr
        },
        /**
         * 
         * 
         * 待改递归
         * 
         * 
         */
        returnTreeArrWithParent(val, arr) {
            let parentArr = []
            val.map((item, index) => {
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i].key === item) {
                        if (arr[i].parentName) {
                            if (!parentArr.filter(_v => _v === arr[i].parentName).length) {
                                parentArr.push(arr[i].parentName)
                                // if (arr[i].children && arr[i].children.length) {
                                //     arr[i].children.map(_v => {
                                //         parentArr.push(_v.key)
                                //     })
                                // }
                                break
                            }
                        }
                    } else {
                        if (arr[i].children && arr[i].children.length) {
                            let cArr = arr[i].children
                            for (let j = 0; j < cArr.length; j++) {
                                if (cArr[j].key === item) {
                                    if (cArr[j].parentName) {
                                        if (!parentArr.filter(_v => _v === cArr[j].parentName).length) {
                                            parentArr.push(cArr[j].parentName)
                                            // if (cArr[j].children && cArr[j].children.length) {
                                            //     cArr[j].children.map(_v => {
                                            //         parentArr.push(_v.key)
                                            //     })
                                            // }
                                            break
                                        }
                                    }
                                } else {
                                    if (cArr[j].children && cArr[j].children.length) {
                                        let ccArr = cArr[j].children
                                        for (let k = 0; k < ccArr.length; k++) {
                                            if (ccArr[k].key === item) {
                                                if (ccArr[k].parentName) {
                                                    if (!parentArr.filter(_v => _v === ccArr[k].parentName).length) {
                                                        parentArr.push(ccArr[k].parentName)
                                                        // if (ccArr[k].children && ccArr[k].children.length) {
                                                        //     ccArr[k].children.map(_v => {
                                                        //         parentArr.push(_v.key)
                                                        //     })
                                                        // }
                                                        break
                                                    }
                                                }
                                            } else {
                                                if (ccArr[k].children && ccArr[k].children.length) {
                                                    let cccArr = ccArr[k].children
                                                    for (let l = 0; l < cccArr.length; l++) {
                                                        if (cccArr[l].key === item) {
                                                            if (cccArr[l].parentName) {
                                                                if (!parentArr.filter(_v => _v === cccArr[l].parentName).length) {
                                                                    parentArr.push(cccArr[l].parentName)
                                                                    break
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }
                }
            })
            return parentArr
        },

        /**
         * 格式化数据 模糊匹配
         */
        getParentKey(key, tree) {
            let parentKey;
            for (let i = 0; i < tree.length; i++) {
                const node = tree[i];
                if (node.children) {
                    if (node.children.some(item => item.title === key)) {
                        parentKey = node.title;
                    } else if (this.getParentKey(key, node.children)) {
                        parentKey = this.getParentKey(key, node.children);
                    }
                }
            }
            return parentKey;
        },
        /**
         * 搜索
         */
        async onSearch(e) {
            const value = e.target.value;
            this.treeData = await this.rebuildData(value, this.treeDataOrgin);
            // console.log('onSearch', this.treeData)


            // let arr1 = await this.initArr(this.originTreeData)
            // let arr2 = await this.initArr(this.treeData)
            // console.log(arr2)

            // console.log(arr1)
            // console.log(arr2)

            
            // this.sortArr(arr1, arr2)
        },
        rebuildData(value, arr) {
            let newarr = [];
            arr.forEach(element => {
                if (element.title.indexOf(value) > -1) {
                    newarr.push(element);
                } else {
                    if (element.children && element.children.length > 0) {
                        const ab = this.rebuildData(value, element.children);
                        const obj = {
                            ...element,
                            children: ab
                        };
                        if (ab && ab.length > 0) {
                            newarr.push(obj);
                        }
                    }
                }
            });
            return newarr;
        },
        /**
         * 刷新
         */
        reload() {
            this.treeData = this.treeDataOrgin;
        },
        handleSubmit() {
            this.$emit('emitHandleSubmit')
        },
        close() {
            this.$emit('emitClose')
        },
    },
    watch: {}
};
</script>

<style scoped>
</style>
