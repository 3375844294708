<template>
  <a-spin :spinning="spinning">
    <!-- <div class="user-container">
      <div class="table--container"> -->
      <ele-table
        :columns="columns"
        :table-data="data"
        :total-items="totalItems"
        :is-full="false"
        :actionsType="actionsType"
        :current-page="pageNumber"
        :hide-row-selection="true"
        @emitRefreshData="clearFilterAndRefresh"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      >
        <!-- 操作 -->
        <a-row
          :gutter="8"
          type="flex"
          align="middle"
          justify="space-between"
        >
          <a-col :span="12">
            <a-input-search
              name="filterText"
              @search="changeFilterText"
              :placeholder="l('SearchWithThreeDot')"
              enterButton
              v-model="filterText"
              v-if="
                isGranted('Pages.Administration.OrganizationUnits.ManageRoles')
              "
            />
          </a-col>
          <a-col :span="8" :offset="4" align="right">
            <a
              @click="createOrEdit(null, 'create')"
              v-if="
                isGranted('Pages.Administration.OrganizationUnits.ManageRoles')
              "
            >
              <a-icon type="plus" /> {{ l("添加岗位") }}
            </a>
            <a-divider type="vertical"></a-divider>
            <a
              :title="l('Refresh')"
              @click="clearFilterAndRefresh()"
              v-if="
                isGranted('Pages.Administration.OrganizationUnits.ManageRoles')
              "
            >
              <a-icon type="reload"
            /></a>
          </a-col>
        </a-row>
    </ele-table>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { OrganizationUnitsServiceProxy } from "../services/organization-units-proxies";
import { ModalHelper } from "@/shared/helpers";
import Bus from "@/shared/bus/bus";
import AddPositionComponent from "../add-position/add-position";
import EleTable from "@/components/ele-table";

export default {
  name: "organization-unit-position-panel",
  mixins: [AppComponentBase],
  components: {EleTable},
  props: ["selectedTree"],
  data() {
    return {
      spinning: false,
      sealService: null,
      selectedRowKeys: [],
      filteredInfo: null,
      sortedInfo: null,
      name: "",
      organizationUnitId: "",
      filterText: "",
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 50, skipCount: 0 },
      selectData: {},
      // 用户表格
      columns: [
        // {
        //   title: this.l("所属组织机构"),
        //   dataIndex: "displayName",
        //   sorter: true,
        //   align: "center",
        //   scopedSlots: { customRender: "displayName" },
        // },
        {
          title: this.l("岗位名称"),
          dataIndex: "positionName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "name" },
        },
        {
          title: this.l("岗位编码"),
          dataIndex: "positionCode",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "number" },
        },

        {
          title: this.l("Actions"),
          dataIndex: "actions",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "actions" },
        },
      ],
      // 用户数据
      data: [],
    };
  },
  computed: {},
  created() {
    this.sealService = new OrganizationUnitsServiceProxy(
      this.$apiUrl,
      this.$api
    );
    // 接受树结构传过来的选中item
    // Bus.$on("selectedNode", this.getTree);
    // // 添加用户成功
    // Bus.$on("saveAddMemberSuccess", data => {
    //     if (data) {
    //         this.clearFilterAndRefresh();
    //     }
    // });
    this.initActionsType()
  },
  beforeDestroy() {
    // Bus.$off("selectedNode");
  },
  methods: {
    initActionsType() {
      let _this = this;
      let obj = {
        type: "seal",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: this.isGranted(
                "Pages.Administration.OrganizationUnits.ManageRoles"
              ),
              name: this.l("查看"),
              icon: "profile",
              fn: (data) => {
                _this.createOrEdit(data, "preview");
              },
            },
            {
              granted: this.isGranted(
                "Pages.Administration.OrganizationUnits.ManageRoles"
              ),
              name: this.l("修改"),
              icon: "edit",
              fn: (data) => {
                _this.createOrEdit(data, "edit");
              },
            },
          ],
          // delete: { granted: this.isGranted('Pages.Customer.Delete'), name: this.l("Delete"), fn: (data) => {
          //   _this.deleteItem(data.id)
          // } },
        },
      };
      this.actionsType = obj;
    },
    /**
     * 选中树结构
     */
    getTree(data) {
      this.selectTree = data;
      // this.getData();
    },
    async changeFilterText(value) {
      this.request.skipCount = 0;
      this.filterText = value;
      await this.getData();
    },
    /**
     * 拉取数据
     */
    async getData(selectData) {
      console.log(selectData);
      if (selectData) {
        this.selectData = selectData;
      }
      this.spinning = true;
      let res = await this.sealService.getPositionPaged({
        positionName: this.name,
        organizationUnitId: this.selectData.id,
        filterText: this.filterText,
        sorting: this.request.sorting,
        maxResultCount: this.request.maxResultCount,
        skipCount: this.request.skipCount,
      });
      this.spinning = false;
      this.data = res.items.map((o) => {
        return {
          ...o,
          isDefaultStr: o.isDefault ? "是" : "否",
          // addedTimeStr: o.addedTime.format("YYYY-MM-DD HH:mm:ss"),
        };
      });
      this.totalItems = res.totalCount;
      this.pagerange = [
        (this.pageNumber - 1) * this.request.maxResultCount + 1,
        this.pageNumber * this.request.maxResultCount,
      ];
      this.totalPages = Math.ceil(res.totalCount / this.request.maxResultCount);
      // console.log(res);
    },
    /**
     * 选中table
     */
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    handleChange(pagination, filters, sorter) {
      console.log("Various parameters", pagination, filters, sorter);
      this.filteredInfo = filters;
      this.sortedInfo = sorter;
    },
    /**
     * 清除条件并刷新
     */
    clearFilterAndRefresh() {
      this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
      this.name = "";
      this.filterText = "";
      this.getData(this.selectData);
    },
    /**
     * 分页事件
     */
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
    },
    // 删除item
    async removeItem(item) {
      let { id } = item;
      this.spinning = true;
      let res = await this.extendService.deleteOrganizationSeal(id);
      this.spinning = false;
      this.getData();
      this.$notification["success"]({
        message: this.l("SuccessfullyRemoved"),
      });
      this.clearFilterAndRefresh();
    },
    /**
     * 清空选择
     */
    restCheckStatus() {
      this.selectedRowKeys = [];
    },
    // 增加修改查看
    createOrEdit(item, type) {
      ModalHelper.create(AddPositionComponent, {
        organizationUnit: {
          type: type, // edit,preview,create
          organizationUnitId: this.selectedTree.id,
          id: item ? item.id : null,
          name: item ? item.name : "",
          pictureUrls: item ? item.pictureUrls : "",
          status: item ? item.status : "",
          isDefault: item ? item.isDefault : false,
          sort: item ? item.sort : "",
        },
      }).subscribe((res) => {
        if (res) {
          this.clearFilterAndRefresh();
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.user-container {
  border: 1px solid #e8e8e8;
  margin: 20px;
  padding: 20px;
  .table-edit,
  .table-delete {
    margin-left: 3px;
    i {
      padding: 0 2px;
    }
  }
}
.opeattion-container {
  margin: 20px 0;
}
.pagination {
  margin: 10px auto;
  text-align: right;
}
</style>
