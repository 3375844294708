import "rxjs/add/operator/finally";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import {
  GetOrganizationExtendPagedDTO,
  GetOrganizationExtendByIdDTO,
  IGetOrganizationExtendByIdDTO,
  SaveOrganizationSealDTO,
  SaveOrganizationBankAccountDTO,
} from "./model";

import { SwaggerException } from "@/shared/service-proxies/model";
import moment from "moment";
import { CommonProcess } from "@/shared/common-process";

const cp = new CommonProcess()

export class OrganizationUnitsServiceProxy {
  private instance: AxiosInstance;
  private baseUrl: string;
  protected jsonParseReviver:
    | ((key: string, value: any) => any)
    | undefined = undefined;

  constructor(baseUrl?: string, instance?: AxiosInstance) {
    this.instance = instance ? instance : axios.create();
    this.baseUrl = baseUrl ? baseUrl : "";
  }

  /**
   * 获取组织架构扩展信息(分页)
   * @param name
   * @param organizationUnitId
   * @param filterText
   * @param sorting
   * @param maxResultCount
   * @param skipCount
   */
  getOrganizationExtendPaged(
    name: string | undefined,
    organizationUnitId: string | number,
    filterText: string | undefined,
    sorting: string | undefined,
    maxResultCount: string | undefined,
    skipCount: string | undefined
  ): Promise<GetOrganizationExtendPagedDTO> {
    let url_ =
      this.baseUrl +
      `/api/services/app/Organization/GetOrganizationExtendPaged?name=${name}&organizationUnitId=${organizationUnitId}&filterText=${filterText}&sorting=${sorting}&maxResultCount=${maxResultCount}&skipCount=${skipCount}`;
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }
  protected processGetOrganizationExtendPaged(
    response: AxiosResponse
  ): Promise<any> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      let result200: any = null;
      let resultData200 = _responseText;
      // result200 = GetOrganizationExtendPagedDTO.fromJS(resultData200);
      return resultData200;
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException(
        "An unexpected server error occurred.",
        status,
        _responseText,
        _headers
      );
    }
    return Promise.resolve<any>(<any>null);
  }

  /**
   * 获取单条组织架构扩展信息
   * @param id
   */
  getOrganizationExtendById(
    id: string | undefined
  ): Promise<any> {
    let url_ =
      this.baseUrl +
      "/api/services/app/Organization/GetOrganizationExtendById?";
    if (id === null) {
      throw new Error("The parameter 'id' cannot be null.");
    } else if (id !== undefined) {
      url_ += "id=" + encodeURIComponent("" + id) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }
  protected processGetOrganizationExtendById(
    response: AxiosResponse
  ): Promise<any> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      return _responseText;
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException(
        "An unexpected server error occurred.",
        status,
        _responseText,
        _headers
      );
    }
    return Promise.resolve<any>(<any>null);
  }

  /**
   * 新增或编辑组织架构扩展信息
   * @param body
   */
  saveOrganizationExtend(
    body: IGetOrganizationExtendByIdDTO | undefined
  ): Promise<any> {
    let url_ =
      this.baseUrl + `/api/services/app/Organization/SaveOrganizationExtend`;
    url_ = url_.replace(/[?&]$/, "");

    const content_ = body;

    let options_ = <AxiosRequestConfig>{
      data: content_,
      method: "POST",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };
    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }
  protected processSaveOrganizationExtend(
    response: AxiosResponse
  ): Promise<any> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      return _responseText;
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException(
        "An unexpected server error occurred.",
        status,
        _responseText,
        _headers
      );
    }
    return Promise.resolve<void>(<any>null);
  }

  /**
   * 删除组织架构扩展信息
   * @param id
   */
  deleteOrganizationExtend(id: number | undefined): Promise<void> {
    let url_ =
      this.baseUrl + "/api/services/app/Organization/DeleteOrganizationExtend?";
    if (id === null) {
      throw new Error("The parameter 'id' cannot be null.");
    } else if (id !== undefined) {
      url_ += "id=" + encodeURIComponent("" + id) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "DELETE",
      url: url_,
      headers: {},
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }
  protected processDeleteOrganizationExtend(
    response: AxiosResponse
  ): Promise<void> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      return Promise.resolve<void>(<any>null);
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException(
        "An unexpected server error occurred.",
        status,
        _responseText,
        _headers
      );
    }
    return Promise.resolve<void>(<any>null);
  }

  /**
   * 获取印章(分页)
   * @param name
   * @param organizationUnitId
   * @param filterText
   * @param sorting
   * @param maxResultCount
   * @param skipCount
   */
  getOrganizationSealPaged(
    name: string | undefined,
    organizationUnitId: string | number,
    filterText: string | undefined,
    sorting: string | undefined,
    maxResultCount: string | undefined,
    skipCount: string | undefined
  ): Promise<any> {
    let url_ =
      this.baseUrl +
      `/api/services/app/Organization/GetOrganizationSealPaged?name=${name}&organizationUnitId=${organizationUnitId}&filterText=${filterText}&sorting=${sorting}&maxResultCount=${maxResultCount}&skipCount=${skipCount}`;
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };
    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 获取印章(单个)
   * @param id
   */
  getOrganizationSealById(id: string | undefined): Promise<any> {
    let url_ =
      this.baseUrl + "/api/services/app/Organization/GetOrganizationSealById?";
    if (id === null) {
      throw new Error("The parameter 'id' cannot be null.");
    } else if (id !== undefined) {
      url_ += "id=" + encodeURIComponent("" + id) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };
    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 添加或编辑印章
   * @param body
   */
  saveOrganizationSeal(
    body: any
  ): Promise<any> {
    let url_ =
      this.baseUrl + `/api/services/app/Organization/SaveOrganizationSeal`;
    url_ = url_.replace(/[?&]$/, "");

    const content_ = body;

    let options_ = <AxiosRequestConfig>{
      data: content_,
      method: "POST",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };
    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 删除印章
   * @param id
   */
  deleteOrganizationSeal(id: number | undefined): Promise<void> {
    let url_ =
      this.baseUrl + "/api/services/app/Organization/DeleteOrganizationSeal?";
    if (id === null) {
      throw new Error("The parameter 'id' cannot be null.");
    } else if (id !== undefined) {
      url_ += "id=" + encodeURIComponent("" + id) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "DELETE",
      url: url_,
      headers: {},
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 获取银行账户(分页)
   * @param name
   * @param organizationUnitId
   * @param filterText
   * @param sorting
   * @param maxResultCount
   * @param skipCount
   */
  getOrganizationBankAccountPaged(
    name: string | undefined,
    organizationUnitId: string | number,
    filterText: string | undefined,
    sorting: string | undefined,
    maxResultCount: string | undefined,
    skipCount: string | undefined
  ): Promise<any> {
    let url_ =
      this.baseUrl +
      `/api/services/app/Organization/GetOrganizationBankAccountPaged?name=${name}&organizationUnitId=${organizationUnitId}&filterText=${filterText}&sorting=${sorting}&maxResultCount=${maxResultCount}&skipCount=${skipCount}`;
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };
    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 获取银行账户(单个)
   * @param id
   */
  getOrganizationBankAccountById(id: string | undefined): Promise<any> {
    let url_ =
      this.baseUrl +
      "/api/services/app/Organization/GetOrganizationBankAccountById?";
    if (id === null) {
      throw new Error("The parameter 'id' cannot be null.");
    } else if (id !== undefined) {
      url_ += "id=" + encodeURIComponent("" + id) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };
    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 添加或编辑银行账户
   * @param body
   */
  saveOrganizationBankAccount(
    body: SaveOrganizationBankAccountDTO | undefined
  ): Promise<any> {
    let url_ =
      this.baseUrl +
      `/api/services/app/Organization/SaveOrganizationBankAccount`;
    url_ = url_.replace(/[?&]$/, "");

    const content_ = body;

    let options_ = <AxiosRequestConfig>{
      data: content_,
      method: "POST",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };
    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 删除银行账户
   * @param id
   */
  deleteOrganizationBankAccount(id: number | undefined): Promise<void> {
    let url_ =
      this.baseUrl +
      "/api/services/app/Organization/DeleteOrganizationBankAccount?";
    if (id === null) {
      throw new Error("The parameter 'id' cannot be null.");
    } else if (id !== undefined) {
      url_ += "id=" + encodeURIComponent("" + id) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "DELETE",
      url: url_,
      headers: {},
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }

  /**
   * 获取岗位分页
   */
  getPositionPaged(params: any): Promise<any> {
    let url_ = this.baseUrl + `/api/services/app/Position/GetPaged`;

    url_ = url_.replace(/[?&]$/, "");
    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
      params,
    };
    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return cp.process(_response);
    });
  }
}
function throwException(
  message: string,
  status: number,
  response: string,
  headers: { [key: string]: any },
  result?: any
): any {
  if (result !== null && result !== undefined) {
    throw result;
  } else {
    throw new SwaggerException(message, status, response, headers, null);
  }
}
