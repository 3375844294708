<template>
  <div>
    <a-row>
      <a-col :sm="3">
        <a-button :type="'primary'" @click="createOrEdit">
          <a-icon type="plus" />
          <span>添加</span>
        </a-button>
      </a-col>
      <a-col :sm="3">
        <a-button :type="'danger'" @click="batchDelete">
          <a-icon type="delete" />
          <span>{{ l("Delete") }}</span>
        </a-button>
      </a-col>
      <a-col :sm="18">
        <!-- 搜索 -->
        <a-input-search
          name="filterText"
          :placeholder="l('InXSearchPlaceHolder', l('PermissionDisplayName'))"
          @search="onSearch"
          enterButton
          v-model="filterText"
          v-decorator="['filterText']"
        />
      </a-col>
    </a-row>

    <div class="table-content">
      <a-table
        size="small"
        :rowKey="(tableData) => tableData.id"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :columns="columns"
        :data-source="tableData"
      />
    </div>

    <div class="modal-footer">
      <a-button @click="close()" type="button">
        <!-- <a-icon type="close-circle" /> -->
        <!-- {{ l('Cancel') }} -->
        关闭
      </a-button>
      <!-- <a-button :type="'primary'">
        <a-icon type="save" />
        {{ l('Save') }}
      </a-button> -->
    </div>
  </div>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { RoleServiceProxy } from "../services/roles-proxies";
import { myArrayService } from "@/shared/utils";
import CreateEditDataAuthCompent from "./create-or-edit-data-auth/create-or-edit-data-auth";
import { ModalHelper } from "@/shared/helpers";

export default {
  name: "field-authority",
  mixins: [AppComponentBase],
  props: ["multiple", "dropDownStyle", "roleId"],
  data() {
    return {
      filterText: "",
      roleService: null,
      list: [],
      showList: [],
      columns: [
        {
          title: "过滤名称",
          dataIndex: "filterName",
          align: "center",
          scopedSlots: { customRender: "filterName" },
        },
        {
          title: "filterCode",
          dataIndex: "filterCode",
          align: "center",
          scopedSlots: { customRender: "filterCode" },
        },
        {
          title: "描述",
          dataIndex: "remark",
          align: "center",
          scopedSlots: { customRender: "remark" },
        },
      ],
      tableData: [],
      oTableData: [],
      selectedRowKeys: [],
      selectedRow: [],
    };
  },
  computed: {},
  created() {
    this.roleService = new RoleServiceProxy(this.$apiUrl, this.$api);
  },
  mounted() {
    this._rowFilterGetAll();
  },
  methods: {
    onSearch() {
      if (this.filterText) {
        let arr = [];
        this.oTableData.map((item) => {
          if (item.filterName.indexOf(this.filterName) !== -1) {
            arr.push(item);
          }
        });
        this.tableData = arr;
      }
    },
    batchDelete() {
      if (!this.selectedRow.length) {
        abp.message.warn("请选择你要删除的过滤器");
        return;
      }
      let arr = [];
      this.selectedRow.map((item) => {
        arr.push({
          RoleId: this.roleId,
          RowFilterId: item.id,
        });
      });
      let postdate = { removes: arr };
      this._rowFilterRemoveRowFilter(arr);
    },
    onSelectChange(selectedRowKeys, selectedRow) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRow = selectedRow;
    },
    _rowFilterRemoveRowFilter(arr) {
      this.$emit("emitSpinning", "1");
      this.roleService
        .rowFilterRemoveRowFilter(arr)
        .finally(() => {
          this.$emit("emitSpinning", "0");
        })
        .then((res) => {
          this.$notification["success"]({
            message: "删除成功",
          });
          this._rowFilterGetAll();
        });
    },
    _rowFilterGetAll() {
      this.$emit("emitSpinning", "1");
      this.roleService
        .rowFilterGetAll(this.roleId)
        .finally(() => {
          this.$emit("emitSpinning", "0");
        })
        .then((result) => {
          this.oTableData = result;
          this.tableData = result;
        });
    },
    _rowFilterGetAllFilter() {
      this.$emit("emitSpinning", "1");
      this.roleService
        .rowFilterGetAllFilter()
        .finally(() => {
          this.$emit("emitSpinning", "0");
        })
        .then((res) => {
          let arr = [];
          this.tableData = res;
        });
    },
    createOrEdit() {
      ModalHelper.create(
        CreateEditDataAuthCompent,
        {
          roleId: this.roleId,
          oTableData: this.oTableData,
          roleService: this.roleService,
        },
        {
          width: "768px",
        }
      ).subscribe((res) => {
        this._rowFilterGetAll();
      });
    },
    // 关闭modal
    close() {
      this.$emit("emitCloseModal");
    },
  },
  components: {
    CreateEditDataAuthCompent,
  },
};
</script>

<style lang="less" scoped>
.checklist-wrapper {
  ul {
    padding: 0;
    li {
      padding: 10px 0;
      list-style: none;
      .checkall {
        font-weight: bolder;
        padding: 10px 0;
        border-bottom: 1px solid #e9e9e9;
      }
      .checkbox-group {
        padding: 10px 0;
        border-bottom: 1px solid #e9e9e9;
      }
    }
  }
}
.table-content {
  margin-top: 20px;
  .table-edit {
    margin-right: 10px;
  }
}
.modal-footer {
  padding: 10px 0;
  margin: 0;
}
</style>