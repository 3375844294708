var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('section',[_c('div',{staticClass:"modal-header"},[(_vm.pageType === 'edit')?_c('div',{staticClass:"modal-title"},[_c('a-icon',{attrs:{"type":"share-alt"}}),_vm._v(_vm._s(_vm.l("Edit"))+": "+_vm._s(_vm.organizationUnit.shortName)+" ")],1):_c('div',{staticClass:"modal-title"},[_c('a-icon',{attrs:{"type":"share-alt"}}),_vm._v(_vm._s(_vm.pageType === "create" ? "创建" : "查看")+"：扩展信息 ")],1)]),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 9 },"wrapper-col":{ span: 15 }}},[_c('a-row',{staticClass:"form-wrapper",attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"组织名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'shortName',
              {
                rules: [
                  { required: true, message: _vm.l('ThisFieldIsRequired') },
                  { max: 128, message: _vm.l('MaxLength') } ],
              } ]),expression:"[\n              'shortName',\n              {\n                rules: [\n                  { required: true, message: l('ThisFieldIsRequired') },\n                  { max: 128, message: l('MaxLength') },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"请输入","allowClear":"","disabled":_vm.isDisabled}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"组织性质"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'nature',
              {
                rules: [
                  { required: true, message: _vm.l('ThisFieldIsRequired') } ],
              } ]),expression:"[\n              'nature',\n              {\n                rules: [\n                  { required: true, message: l('ThisFieldIsRequired') },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"请输入","allowClear":"","disabled":_vm.isDisabled}},_vm._l((_vm.NatureTypeData),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.key}},[_vm._v(" "+_vm._s(item.key)+" ")])}),1)],1)],1)],1),_c('a-row',{staticClass:"form-wrapper",attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"编码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'code',
              {
                rules: [
                  { required: true, message: _vm.l('ThisFieldIsRequired') },
                  { max: 128, message: _vm.l('MaxLength') } ],
              } ]),expression:"[\n              'code',\n              {\n                rules: [\n                  { required: true, message: l('ThisFieldIsRequired') },\n                  { max: 128, message: l('MaxLength') },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"请输入","allowClear":"","disabled":_vm.isDisabled}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"成立时间","required":""}},[_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"disabled":_vm.isDisabled,"type":"date","placeholder":"请选择时间"},on:{"change":_vm.formDateChange},model:{value:(_vm.formDate),callback:function ($$v) {_vm.formDate=$$v},expression:"formDate"}})],1)],1)],1),_c('a-row',{staticClass:"form-wrapper",attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"负责人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'headName',
              {
                rules: [
                  { max: 128, message: _vm.l('MaxLength') } ],
              } ]),expression:"[\n              'headName',\n              {\n                rules: [\n                  { max: 128, message: l('MaxLength') },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"请输入","allowClear":"","disabled":_vm.isDisabled}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"手机号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'phone',
              {
                rules: [
                  { max: 11, message: _vm.l('MaxLength') } ],
              } ]),expression:"[\n              'phone',\n              {\n                rules: [\n                  { max: 11, message: l('MaxLength') },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"请输入","allowClear":"","disabled":_vm.isDisabled}})],1)],1)],1),_c('a-row',{staticClass:"form-wrapper",attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"电子邮箱"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'email',
              {
                rules: [
                  { max: 128, message: _vm.l('MaxLength') } ],
              } ]),expression:"[\n              'email',\n              {\n                rules: [\n                  { max: 128, message: l('MaxLength') },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"请输入","allowClear":"","disabled":_vm.isDisabled}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"传真号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'faxNumber',
              {
                rules: [
                  { max: 128, message: _vm.l('MaxLength') } ],
              } ]),expression:"[\n              'faxNumber',\n              {\n                rules: [\n                  { max: 128, message: l('MaxLength') },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"请输入","allowClear":"","disabled":_vm.isDisabled}})],1)],1)],1),_c('a-row',{staticClass:"form-wrapper",attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"通讯地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'address',
              {
                rules: [
                  { required: true, message: _vm.l('ThisFieldIsRequired') },
                  { max: 128, message: _vm.l('MaxLength') } ],
              } ]),expression:"[\n              'address',\n              {\n                rules: [\n                  { required: true, message: l('ThisFieldIsRequired') },\n                  { max: 128, message: l('MaxLength') },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"请输入","allowClear":"","disabled":_vm.isDisabled}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"邮政编码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'postalCode',
              {
                rules: [
                  { max: 128, message: _vm.l('MaxLength') } ],
              } ]),expression:"[\n              'postalCode',\n              {\n                rules: [\n                  { max: 128, message: l('MaxLength') },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"请输入","allowClear":"","disabled":_vm.isDisabled}})],1)],1)],1),_c('a-row',{staticClass:"form-wrapper",attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"纳税人识别号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'taxpayerNumber',
              {
                rules: [
                  { required: true, message: _vm.l('ThisFieldIsRequired') },
                  { max: 128, message: _vm.l('MaxLength') } ],
              } ]),expression:"[\n              'taxpayerNumber',\n              {\n                rules: [\n                  { required: true, message: l('ThisFieldIsRequired') },\n                  { max: 128, message: l('MaxLength') },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"请输入","allowClear":"","disabled":_vm.isDisabled}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'remark',
              {
                rules: [
                  { max: 128, message: _vm.l('MaxLength') } ],
              } ]),expression:"[\n              'remark',\n              {\n                rules: [\n                  { max: 128, message: l('MaxLength') },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"请输入","allowClear":"","disabled":_vm.isDisabled}})],1)],1)],1),_c('a-row',{staticClass:"form-wrapper",attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"每月单人发放上限金额"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'monthlyLimitAmount',
              {
                rules: [
                  { required: true, message: _vm.l('ThisFieldIsRequired') } ],
              } ]),expression:"[\n              'monthlyLimitAmount',\n              {\n                rules: [\n                  { required: true, message: l('ThisFieldIsRequired') },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"请输入","allowClear":"","disabled":_vm.isDisabled,"min":0.01,"step":0.01}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"附件资源"}},[_c('a-upload',{attrs:{"action":_vm.uploadUrl,"multiple":true,"data":{ subdir: this.subdirName },"file-list":_vm.fileList,"showUploadList":{
              showPreviewIcon: true, showRemoveIcon: !_vm.isDisabled 
            }},on:{"change":_vm.handleUploadChange}},[(!_vm.isDisabled)?_c('a-button',{attrs:{"disabled":_vm.isDisabled}},[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 上传附件 ")],1):_vm._e()],1)],1)],1)],1)],1),(_vm.pageType !== 'preview')?_c('div',{staticClass:"modal-footer"},[_c('a-button',{attrs:{"disabled":_vm.saving,"type":"button"},on:{"click":function($event){return _vm.close()}}},[_c('a-icon',{attrs:{"type":"close-circle"}}),_vm._v(" "+_vm._s(_vm.l("Cancel"))+" ")],1),_c('a-button',{attrs:{"loading":_vm.saving,"type":'primary'},on:{"click":function($event){return _vm.handleSubmit()}}},[_c('a-icon',{attrs:{"type":"save"}}),_vm._v(" "+_vm._s(_vm.l("Save"))+" ")],1)],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }