var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('div',{staticClass:"user-container"},[_c('div',{staticClass:"table--container"},[_c('a-row',{staticClass:"opeattion-container",attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":16}},[_c('a-input-search',{attrs:{"name":"filterText","placeholder":_vm.l('SearchWithThreeDot'),"enterButton":""},on:{"search":_vm.searchData},model:{value:(_vm.filterText),callback:function ($$v) {_vm.filterText=$$v},expression:"filterText"}})],1),_c('a-col',{attrs:{"span":6,"offset":2}},[(
              _vm.isGranted('Pages.Administration.OrganizationUnits.ManageRoles')
            )?_c('a',{on:{"click":function($event){return _vm.addRole()}}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" "+_vm._s(_vm.l("CreateNewRole"))+" ")],1):_vm._e(),(
              _vm.isGranted('Pages.Administration.OrganizationUnits.ManageRoles')
            )?_c('a-divider',{attrs:{"type":"vertical"}}):_vm._e(),(
              _vm.isGranted('Pages.Administration.OrganizationUnits.ManageRoles')
            )?_c('a',{on:{"click":function($event){return _vm.batchDelete()}}},[_c('a-icon',{attrs:{"type":"delete"}}),_vm._v(_vm._s(_vm.l("BatchDelete"))+" ")],1):_vm._e(),(
              _vm.isGranted('Pages.Administration.OrganizationUnits.ManageRoles')
            )?_c('a-divider',{attrs:{"type":"vertical"}}):_vm._e(),_c('a',{attrs:{"title":_vm.l('Refresh')},on:{"click":function($event){return _vm.clearFilterAndRefresh()}}},[_c('a-icon',{attrs:{"type":"reload"}})],1)],1)],1),_c('div',{staticClass:"my-md"},[_c('a-alert',{attrs:{"type":'info',"showIcon":true}},[_c('template',{slot:"message"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.l('GridSelectedXItemsTips', _vm.selectedRowKeys.length))}}),_c('a',{staticClass:"ml-md",on:{"click":function($event){return _vm.restCheckStatus()}}},[_vm._v(_vm._s(_vm.l("ClearEmpty")))]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a',{on:{"click":function($event){return _vm.clearFilterAndRefresh()}}},[_vm._v(_vm._s(_vm.l("Refresh")))])],1)],2)],1),_c('a-table',{attrs:{"rowSelection":{
          selectedRowKeys: _vm.selectedRowKeys,
          onChange: _vm.onSelectChange,
        },"columns":_vm.columns,"rowKey":function (data) { return data.id; },"dataSource":_vm.data},on:{"change":_vm.handleChange},scopedSlots:_vm._u([{key:"actions",fn:function(text, record){return (
            _vm.isGranted('Pages.Administration.OrganizationUnits.ManageUsers')
          )?_c('span',{},[_c('a-popconfirm',{attrs:{"placement":"top","okText":_vm.l('Ok'),"cancelText":_vm.l('Cancel')},on:{"confirm":function($event){return _vm.removeMember(record)}}},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(_vm.l( "RemoveUserFromOuWarningMessage", record.userName, _vm.selectTree.displayName ))+" ")]),_c('a',{staticClass:"table-delete"},[_c('a-icon',{attrs:{"type":"delete"}}),_vm._v(_vm._s(_vm.l("Delete")))],1)],2)],1):_vm._e()}}],null,true)})],1)]),_c('a-pagination',{staticClass:"pagination",attrs:{"size":"middle","total":_vm.totalItems,"showSizeChanger":"","showQuickJumper":"","showTotal":_vm.showTotalFun},on:{"change":_vm.onChange,"showSizeChange":_vm.showSizeChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }