<template>
  <section class="organization-container">
    <!-- <page-header :title="l('OrganizationUnits')"></page-header> -->
    <a-row :gutter="8">
      <a-col
        ref="leftTree"
        :span="6"
        class="organization-unit-tree-panel"
        :style="
          'width: 29%; overflow-x: auto;overflow-y:scroll;max-height:' +
          maxHeight
        "
      >
        <organization-unit-tree-panel
          @selectedNode="selectedNodeFunc"
        ></organization-unit-tree-panel>
      </a-col>
      <a-col
        :span="18"
        class="organization-unit-tree-panel"
        :style="
          'width: 69%;overflow-y:scroll;overflow-x:hidden;max-height:' +
          maxHeight
        "
      >
        <a-row :gutter="8" class="units-header">
          <a-col :span="24" class="title">
            <a-icon type="team" />
            <span v-if="selectedTree && selectedTree.hasOwnProperty('id')">{{
              selectedTree.displayName
            }}</span>
          </a-col>
        </a-row>
        <a-row class="form">
          <!-- 暂无数据 -->
          <div
            class="no-data"
            v-if="!selectedTree || !selectedTree.hasOwnProperty('id')"
          >
            <p>{{ l("SelectAnOrganizationUnitToSeeMembers") }}</p>
          </div>
          <a-tabs
            defaultActiveKey="1"
            v-if="selectedTree && selectedTree.hasOwnProperty('id')"
            @change="tabChange"
          >
            <!-- 扩展信息管理 -->
            <a-tab-pane
              v-if="hidden2Level"
              :tab="l('扩展信息')"
              key="3"
              forceRender
            >
              <extend
                ref="extend"
                :id="selectedTree.id ? selectedTree.id : null"
                :table-key="'OrganizationExtendPagedDto'"
              ></extend>
            </a-tab-pane>
            <!-- 用户 -->
            <a-tab-pane :tab="l('Users')" key="1">
              <members></members>
            </a-tab-pane>
            <!-- 角色 -->
            <!-- <a-tab-pane :tab="l('Roles')" key="2" forceRender>
              <organization-unit-role-panel></organization-unit-role-panel>
            </a-tab-pane> -->
            <!-- 岗位管理 -->
            <a-tab-pane :tab="l('岗位管理')" key="7" forceRender>
              <position ref="position" :selected-tree="selectedTree"></position>
            </a-tab-pane>

            <!-- 印章管理 -->
            <a-tab-pane
              v-if="hidden2Level"
              :tab="l('印章')"
              key="4"
              forceRender
            >
              <seal
                ref="seal"
                :req-key="'Organization'"
                :id="selectedTree.id ? selectedTree.id : null"
                :table-key="''"
              >
              </seal>
            </a-tab-pane>
            <!-- 实名信息 -->
            <a-tab-pane
              key="5"
              :tab="l('实名信息')"
              v-if="hidden2Level"
              forceRender
            >
              <!-- <oraganiztion-realname-panel
                ref="realname"
                :req-url="realNameUrl"
                :id="1"
                :type="1"
                :table-key="'CustomerRealNamePagedDto'"
              ></oraganiztion-realname-panel> -->
              <real-name
                ref="realname"
                :req-key="'OrganizationRealName'"
                :id="selectedTree.id ? selectedTree.id : null"
                :table-key="''"
              >
              </real-name>
            </a-tab-pane>
            <!-- 银行账号管理 -->
            <a-tab-pane
              v-if="hidden2Level"
              :tab="l('银行账户')"
              key="6"
              forceRender
            >
              <bank-account
                ref="account"
                :req-key="'Organization'"
                :id="selectedTree.id ? selectedTree.id : null"
                :table-key="''"
              >
              </bank-account>
            </a-tab-pane>
            <!-- 发票信息管理 -->
            <a-tab-pane :tab="l('发票信息管理')" key="8" forceRender>
              <invoice
                ref="invoice"
                :id="selectedTree.id ? selectedTree.id : null"
                :table-key="''"
              >
              </invoice>
            </a-tab-pane>
            <!-- 发放管理 -->
            <a-tab-pane key="9" forceRender v-if="hidden2Level">
              <span slot="tab">
                <a-icon type="file-protect" />
                {{ l("发放管理") }}
              </span>
              <provide
                ref="provide"
                :id="selectedTree.id ? selectedTree.id : null"
                :table-key="''"
              ></provide>
            </a-tab-pane>
          </a-tabs>
        </a-row>
      </a-col>
    </a-row>
  </section>
</template>

<script>
import { PagedListingComponentBase } from "@/shared/component-base";
import OrganizationUnitTreePanel from "./organization-unit-tree-panel/organization-unit-tree-panel";
import Members from "./members/members";
import OrganizationUnitRolePanel from "./organization-unit-role-panel/organization-unit-role-panel";
import Extend from "./extend/extend";
import Seal from "@/components/seal";
import BankAccount from "@/components/bank-account";
import Position from "./position/position";
import RealName from "@/components/real-name/real-name";
import Invoice from "./invoice/invoice";
import Provide from "./provide/provide";

export default {
  name: "organization-units",
  mixins: [PagedListingComponentBase],
  components: {
    OrganizationUnitTreePanel,
    Members,
    OrganizationUnitRolePanel,
    Extend,
    Seal,
    BankAccount,
    Position,
    Provide,
    // "oraganiztion-realname-panel": OrganizationUnitRealNamePanel,
    RealName,
    Invoice,
  },
  data() {
    return {
      // 选择的树结构
      selectedTree: {},
      activeKey: '-1',
      hidden2Level: true,
      maxHeight: "200px",
      // realNameUrl: {
      //   getPaged: "OrganizationRealName/GetPaged",
      //   save: "Customer/SaveCustomerBankAccount",
      //   getItem: "Customer/GetCustomerBankAccountById",
      //   delete: "Customer/DeleteCustomerBankAccount",
      // },
    };
  },
  mounted() {
    this.selectedTree = {};
    var height = window.innerHeight - 125;
    this.maxHeight = height + "px";
  },
  methods: {
    tabChange(activeKey) {
      // console.log(this.$refs);
      console.log('activeKey:', activeKey)
      this.activeKey = activeKey;
      switch (activeKey) {
        case "1":
          // do sth...
          // Bus.$emit("selectedNode", this.selectedTree);
          break;
        case "2":
          // do sth...
          break;
        case "3":
          this.$refs.extend.getData(this.selectedTree.id);
          break;
        case "4":
          this.$refs.seal.getData(
            [{ key: "organizationUnitId", value: this.selectedTree.id }],
            "/api/services/app/Organization/GetOrganizationSealPaged"
          );
          break;
        case "5":
          // console.log(this.selectedTree.id);
          // this.$refs.realname.getData(this.realNameUrl.getPaged, this.selectedTree.id);
          this.$refs.realname.getData(
            [{ key: "organizationId", value: this.selectedTree.id }],
            "/api/services/app/OrganizationRealName/GetPaged"
          );
          break;
        case "6":
          this.$refs.account.getData(
            [{ key: "organizationUnitId", value: this.selectedTree.id }],
            "/api/services/app/Organization/GetOrganizationBankAccountPaged"
          );
          break;
        case "7":
          this.$refs.position.getData(this.selectedTree);
          break;
        case "8":
          this.$refs.invoice.getData([
            { key: "organizationUnitId", value: this.selectedTree.id },
          ]);
        case "9":
          this.$refs.provide.getData([
            { key: "organizationId", value: this.selectedTree.id },
          ]);
          break;
      }
    },
    /**
     * 选择树结构
     */
    selectedNodeFunc(data) {
      console.log(data);
      this.hidden2Level = data && data.parentId == null;
      //   console.log(this.hidden2Level);
      this.selectedTree = data;

      this.tabChange(this.activeKey);
    },
  },
};
</script>

<style scoped lang="less">
.organization-container {
  min-height: 600px;
}
.organization-unit-tree-panel {
  background-color: #fff;
  border: 1px solid #e8e8e8;
}
.units-header {
  height: 50px;
  border-bottom: 1px solid #e8e8e8;
  > .title {
    line-height: 50px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    margin-left: 20px;
  }
  p {
    line-height: 50px;
    &.left {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      margin-left: 20px;
    }
    a {
      margin-left: 10px;
    }
  }
}
/deep/.ant-tabs-bar {
  margin: 0 20px;
}
// 暂无数据
.no-data {
  border: 1px solid #e8e8e8;
  margin: 20px;
  p {
    text-align: center;
    margin-bottom: 0;
    line-height: 120px;
  }
}
</style>
