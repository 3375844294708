<template>
  <a-spin :spinning="spinning">
    <div class="">
      <div class="">
        <!-- table -->
        <!-- <react-table
          :table-data="tableData"
          :total-items="totalItems"
          :tableKey="tableKey"
          :showTotal="showTotalFun"
          :actions-type="actionsType"
          :columns="columns"
          :scroll="{ x: 0 }"
          :hide-row-selection="true"
          :current-page="pageNumber"
          @emitRefreshData="clearFilterAndRefresh"
          @emitSortData="updateSortData"
          @emitSelectChange="updateSelectChange"
          @emitOnPageChange="updatePageChange"
          @emitShowSizeChange="updateShowSizeChange"
        > -->
        <ele-table
          :columns="columns"
          :table-data="tableData"
          :total-items="totalItems"
          :is-full="false"
          :actionsType="actionsType"
          :current-page="pageNumber"
          :hide-row-selection="true"
          @emitRefreshData="clearFilterAndRefresh"
          @emitSelectChange="updateSelectChange"
          @emitOnPageChange="updatePageChange"
          @emitShowSizeChange="updateShowSizeChange"
        >
          <a-row
            v-if="!tableData.length"
            :gutter="8"
            class="opeattion-container"
            type="flex"
            align="middle"
            justify="space-between"
          >
            <!-- <a-col :span="16">
              <a-input-search
                name="filterText"
                @search="getData(id)"
                :placeholder="l('SearchWithThreeDot')"
                enterButton
                v-model="name"
              />
            </a-col> -->
            <a-col :span="24" align="right">
              <a
                @click="createOrEdit(null, 'create')"
                v-if="
                  isGranted('Pages.Administration.OrganizationUnits.ManageRoles')
                "
              >
                <a-icon type="plus" /> {{ l("添加扩展信息") }}
              </a>
              <!-- <a-divider type="vertical"></a-divider>
              <a :title="l('Refresh')" @click="clearFilterAndRefresh()">
                <a-icon type="reload"
              /></a> -->
            </a-col>
          </a-row>
        </ele-table>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { OrganizationUnitServiceProxy } from "@/shared/service-proxies";
import { OrganizationUnitsServiceProxy } from "../services/organization-units-proxies";

import { ModalHelper } from "@/shared/helpers";
import Bus from "@/shared/bus/bus";
import AddExtendComponent from "../add-extend/add-extend";
import { AppConsts } from "@/abpPro/AppConsts";
import EleTable from "@/components/ele-table";

export default {
  name: "organization-unit-role-panel",
  mixins: [AppComponentBase],
  components: { EleTable },
  props: ["id", "tableKey"],
  data() {
    return {
      spinning: false,
      _organizationUnitServiceProxy: null,
      extendService: null,
      selectedRowKeys: [],
      filteredInfo: null,
      sortedInfo: null,
      name: "",
      organizationUnitId: "",
      filterText: "",
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 50, skipCount: 0 },
      // 用户表格
      columns: [
        {
          title: this.l("组织简称"),
          dataIndex: "shortName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "shortName" },
        },
        {
          title: this.l("每月单人发放上限金额"),
          dataIndex: "MonthlyLimitAmount",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "shortName" },
        },
        {
          title: this.l("组织性质"),
          dataIndex: "nature",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "nature" },
        },
        {
          title: this.l("负责人"),
          dataIndex: "headName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "headName" },
        },
        {
          title: this.l("手机号"),
          dataIndex: "phone",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "phone" },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          sorter: false,
          align: "center",
          fixed: "right",
          scopedSlots: { customRender: "actions" },
        },
      ],
      // 用户数据
      tableData: [],
      imgBaseUrl: AppConsts.uploadBaseUrl,
    };
  },
  computed: {},
  created() {
    this._organizationUnitServiceProxy = new OrganizationUnitServiceProxy(
      this.$apiUrl,
      this.$api
    );
    this.extendService = new OrganizationUnitsServiceProxy(
      this.$apiUrl,
      this.$api
    );
    this.initActionsType();
  },
  beforeDestroy() {},
  methods: {
    // 查看附件
    openFile(item) {
      // console.log(item);
      let attachmenResources = item.attachmenResources;
      if (attachmenResources.indexOf(",") !== -1) {
        let arr = attachmenResources.split(",");
        arr.map((val) => {
          window.open(`${AppConsts.uploadBaseUrl}${val}`);
        });
      } else {
        window.open(`${AppConsts.uploadBaseUrl}${attachmenResources}`);
      }
    },
    // 拉取数据
    async getData(id) {
      this.spinning = true;
      try {
        let res = await this.extendService.getOrganizationExtendPaged(
          this.name,
          id ? id : this.id,
          this.filterText,
          this.request.sorting,
          this.request.maxResultCount,
          this.request.skipCount
        );
        this.spinning = false;
        this.tableData = res.items.map((o) => {
          return {
            ...o,
            MonthlyLimitAmount: o.monthlyLimitAmount ? o.monthlyLimitAmount : '92000.00',
          };
        });
        this.totalItems = res.totalCount;
        this.pagerange = [
          (this.pageNumber - 1) * this.request.maxResultCount + 1,
          this.pageNumber * this.request.maxResultCount,
        ];
        this.totalPages = Math.ceil(
          res.totalCount / this.request.maxResultCount
        );
        // console.log(res);
      } catch (error) {
        this.spinning = false;
      }
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
      this.name = "";
      this.filterText = "";
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    // 删除
    async deleteItem(newV) {
      let { id } = newV.item;
      this.spinning = true;
      try {
        let res = await this.extendService.deleteOrganizationExtend(id);
        this.spinning = false;
        this.$notification["success"]({
          message: this.l("SuccessfullyRemoved"),
        });
        this.clearFilterAndRefresh();
      } catch (error) {
        this.spinning = false;
      }
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
    },
    // 初始化需要显示的func btn
    initActionsType() {
      let _this = this;
      let obj = {
        type: "seal",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: this.isGranted(
                "Pages.Administration.OrganizationUnits.ManageRoles"
              ),
              name: this.l("查看"),
              icon: "profile",
              fn: (data) => {
                _this.createOrEdit(data, "preview");
              },
            },
            {
              granted: this.isGranted(
                "Pages.Administration.OrganizationUnits.ManageRoles"
              ),
              name: this.l("修改"),
              icon: "edit",
              fn: (data) => {
                _this.createOrEdit(data, "edit");
              },
            },
          ],
          // delete: { granted: this.isGranted('Pages.Customer.Delete'), name: this.l("Delete"), fn: (data) => {
          //   _this.deleteItem(data.id)
          // } },
        },
      };
      this.actionsType = obj;
    },
    // 增加修改查看
    createOrEdit(item, type) {
      ModalHelper.create(AddExtendComponent, {
        organizationUnit: {
          type: type, // edit,preview,create
          parentId: this.id,
          id: item ? item.id : null,
        },
        // width: '900px',
      }).subscribe((res) => {
        console.info(res);
        if (res) {
          this.clearFilterAndRefresh();
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.user-container {
  border: 1px solid #e8e8e8;
  margin: 20px;
  padding: 20px;
  .table-edit,
  .table-delete {
    margin-left: 3px;
    i {
      padding: 0 2px;
    }
  }
}
.opeattion-container {
  margin: 20px 0;
}
.pagination {
  margin: 10px auto;
  text-align: right;
}
</style>
