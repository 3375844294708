import { render, staticRenderFns } from "./edition-combo.vue?vue&type=template&id=f2f11d8e&scoped=true&"
import script from "./edition-combo.vue?vue&type=script&lang=js&"
export * from "./edition-combo.vue?vue&type=script&lang=js&"
import style0 from "./edition-combo.vue?vue&type=style&index=0&id=f2f11d8e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2f11d8e",
  null
  
)

export default component.exports