<template>
  <section>
    <a-spin :spinning="loadingDetail">
      <div class="modal-header">
        <div class="modal-title" v-if="pageType === 'edit'">
          <a-icon type="share-alt" />{{ l("Edit") }}:
          {{ organizationUnit.shortName }}
        </div>
        <div class="modal-title" v-else>
          <a-icon type="share-alt" />{{
            pageType === "create" ? "创建" : "查看"
          }}:岗位管理
        </div>
      </div>
      <a-form :form="form">
        <a-row :gutter="24" class="form-wrapper">
          <a-col :span="24">
            <a-form-item label="岗位名称">
              <a-input
                placeholder="请输入"
                allowClear
                :disabled="isDisabled"
                v-decorator="[
                  'positionName',
                  {
                    rules: [
                      { required: true, message: l('ThisFieldIsRequired') },
                      { max: 128, message: l('MaxLength') },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="岗位编码">
              <a-input
                placeholder="[系统生成]"
                allowClear
                :disabled="true"
                v-decorator="['positionCode']"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="上级岗位">
              <a-select
                placeholder="请输入"
                allowClear
                :options="positionOptions"
                :disabled="isDisabled"
                v-decorator="[
                  'parentId',
                  {
                    rules: [],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="备注">
              <a-textarea
                :rows="4"
                placeholder="请输入"
                allowClear
                :disabled="isDisabled"
                v-decorator="['remark']"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

      <div class="modal-footer" v-if="pageType !== 'preview'">
        <a-button :disabled="saving" @click="close()" type="button">
          <a-icon type="close-circle" />
          {{ l("Cancel") }}
        </a-button>
        <a-button :loading="saving" :type="'primary'" @click="handleSubmit()">
          <a-icon type="save" />
          {{ l("Save") }}
        </a-button>
      </div>
    </a-spin>
  </section>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalComponentBase } from "@/shared/component-base";
import { OrganizationPositionServiceProxy } from "../services/positions";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";

export default {
  name: "add-position",
  mixins: [AppComponentBase, ModalComponentBase],
  data() {
    return {
      // 表单
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      extendService: null,
      formDate: null,
      // 上传文件
      fileList: [],
      uploadUrl: "",
      subdirName: "",
      pageType: "", // preview,edit,create
      isDisabled: false,
      previewFileList: [],
      positionOptions: [],
      loadingDetail: false,
    };
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this.extendService = new OrganizationPositionServiceProxy(
      this.$apiUrl,
      this.$api
    );
    this.uploadUrl = AppConsts.uploadBaseUrl + "/API/Upload";
    this.subdirName = environment.production ? "abpProd" : "abpDev";
  },
  async mounted() {
    this.loadingDetail = true;
    await this._getOrganizationPositionOptions(
      this.organizationUnit.organizationUnitId
    );
    // 修改
    if (this.organizationUnit) {
      var detail = await this._getPositionDetailById(this.organizationUnit.id);
      // this._getOrganizationExtendById(this.organizationUnit.id)
      console.log(detail);
      this.$nextTick(() => {
        this.form.setFieldsValue({ ...detail });
      });
      this.pageType = this.organizationUnit.type;
      this.isDisabled = this.organizationUnit.type === "preview";
    }
    this.loadingDetail = false;
  },
  methods: {
    async _getPositionDetailById(id) {
      let res = await this.extendService.getPositionDetailById({ id });
      return res;
    },
    async _getOrganizationPositionOptions(id) {
      var response = await this.extendService.getPositionOption({
        organizationUnitId: id,
      });
      this.positionOptions = response;
    },
    // 上传组件 @change
    handleUploadChange(info) {
      let fileList = [...info.fileList];
      fileList = fileList.map((file) => {
        if (file.response) {
          console.log(file);
          file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
        }
        console.log(file);
        return file;
      });
      this.fileList = fileList;
    },
    // 日期picker @change
    formDateChange(date, dateString) {
      console.log(date, dateString);
      this.formDate = dateString;
    },
    // 提交表单
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values) {
            if(this.organizationUnit.id === values.parentId){
              this.$notification["error"]({
                message: this.l("PositionParentIdError"),
              });  
              return false;
            }
            this.createEditUnit(values);
          }
        }
      });
    },
    // 创建
    async createEditUnit(params) {
      this.saving = true;
      let fileUrlList = [];
      this.fileList.map((item) => {
        fileUrlList.push(item.response.data.Path);
      });
      let res = await this.extendService.savePosition({
        ...params,
        organizationUnitId: this.organizationUnit.organizationUnitId,
        id: this.organizationUnit.id ? this.organizationUnit.id : null,
      });
      this.saving = false;
      this.$notification["success"]({
        message: this.l("SavedSuccessfully"),
      });
      this.success(res);
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../../styles/common.less";

.modal-header {
  .anticon-share-alt {
    margin-right: 10px;
  }
}
.form-wrapper {
  .ant-form-item {
    margin-bottom: 14px;
  }
  .preview-list {
    margin-bottom: 0;
    padding: 0;
    li {
      list-style: none;
      line-height: 30px;
      a {
        color: @color-lightblack;
        &:hover {
          color: @color-blue;
        }
      }
    }
  }
}
</style>
