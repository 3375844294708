var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.l("CreateNewTenant")))])]),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":_vm.l('TenancyName')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'tenancyName',
          {
            rules: [
              {
                required: true,
                message: _vm.l('ThisFieldIsRequired'),
              } ],
          } ]),expression:"[\n          'tenancyName',\n          {\n            rules: [\n              {\n                required: true,\n                message: l('ThisFieldIsRequired'),\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":_vm.l('TenancyName')}})],1),_c('a-form-item',{attrs:{"label":_vm.l('DisplayTenancyName')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [
              {
                required: true,
                message: _vm.l('ThisFieldIsRequired'),
              } ],
          } ]),expression:"[\n          'name',\n          {\n            rules: [\n              {\n                required: true,\n                message: l('ThisFieldIsRequired'),\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":_vm.l('Name')}})],1),_c('a-form-item',{attrs:{"label":_vm.l('工作流')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'workflow',
          {
            rules: [
              {
                required: true,
                message: _vm.l('ThisFieldIsRequired'),
              } ],
            initialValue: undefined,
          } ]),expression:"[\n          'workflow',\n          {\n            rules: [\n              {\n                required: true,\n                message: l('ThisFieldIsRequired'),\n              },\n            ],\n            initialValue: undefined,\n          },\n        ]"}],attrs:{"placeholder":_vm.l('请选择工作流')}},_vm._l((_vm.workflowOpts),function(it,i){return _c('a-select-option',{key:i,attrs:{"value":it.value}},[_vm._v(" "+_vm._s(it.label)+" ")])}),1)],1),_c('a-checkbox',{attrs:{"checked":_vm.UseHostDatabasechecked},on:{"change":_vm.UseHostDatabaseChange}},[_vm._v(_vm._s(_vm.l("UseHostDatabase")))]),(!_vm.UseHostDatabasechecked)?_c('a-form-item',{attrs:{"label":_vm.l('DatabaseConnectionString')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'connectionString',
          {
            rules: [
              {
                required: true,
                message: _vm.l('ThisFieldIsRequired'),
              } ],
          } ]),expression:"[\n          'connectionString',\n          {\n            rules: [\n              {\n                required: true,\n                message: l('ThisFieldIsRequired'),\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":_vm.l('DatabaseConnectionString') + _vm.l('Optional')}})],1):_vm._e(),_c('a-form-item',{attrs:{"label":_vm.l('AdminEmailAddress')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'adminEmailAddress',
          {
            rules: [
              {
                type: 'email',
                message: _vm.l('NotEmail'),
              },
              {
                required: true,
                message: _vm.l('ThisFieldIsRequired'),
              } ],
          } ]),expression:"[\n          'adminEmailAddress',\n          {\n            rules: [\n              {\n                type: 'email',\n                message: l('NotEmail'),\n              },\n              {\n                required: true,\n                message: l('ThisFieldIsRequired'),\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":_vm.l('EmailAddress')}})],1),_c('a-checkbox',{attrs:{"checked":_vm.passwordchecked},on:{"change":_vm.passwordChange}},[_vm._v(_vm._s(_vm.l("UseDefaultPassword", "123qwe")))]),(!_vm.passwordchecked)?_c('a-form-item',{attrs:{"label":_vm.l('Password'),"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'adminPassword',
          {
            rules: [
              {
                required: true,
                message: _vm.l('ThisFieldIsRequired'),
              },
              {
                max: 32,
                message: _vm.l('maxlength'),
              },
              {
                min: 6,
                message: _vm.l('minlength'),
              },
              {
                validator: _vm.validateToNextPassword,
              } ],
          } ]),expression:"[\n          'adminPassword',\n          {\n            rules: [\n              {\n                required: true,\n                message: l('ThisFieldIsRequired'),\n              },\n              {\n                max: 32,\n                message: l('maxlength'),\n              },\n              {\n                min: 6,\n                message: l('minlength'),\n              },\n              {\n                validator: validateToNextPassword,\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":_vm.l('Password'),"type":"password"}})],1):_vm._e(),(!_vm.passwordchecked)?_c('a-form-item',{attrs:{"label":_vm.l('ConfirmPassword'),"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'confirmPassword',
          {
            rules: [
              {
                required: true,
                message: _vm.l('ThisFieldIsRequired'),
              },
              {
                validator: _vm.validateEqual,
              } ],
          } ]),expression:"[\n          'confirmPassword',\n          {\n            rules: [\n              {\n                required: true,\n                message: l('ThisFieldIsRequired'),\n              },\n              {\n                validator: validateEqual,\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":_vm.l('ConfirmPassword'),"type":"password"}})],1):_vm._e(),_c('a-form-item',{attrs:{"label":_vm.l('Edition'),"has-feedback":""}},[_c('edition-combo',{attrs:{"placeholder":_vm.l('PleaseSelect'),"selectedEdition":_vm.selectedEdition},on:{"selectedEditionChange":function($event){return _vm.selectedEditionChange($event)}}})],1),(_vm.isShowIsUnlimited)?_c('a-checkbox',{attrs:{"checked":_vm.IsUnlimitedchecked},on:{"change":function($event){return _vm.checkedChange('IsUnlimitedchecked', $event)}}},[_vm._v(_vm._s(_vm.l("IsUnlimited")))]):_vm._e(),(!_vm.IsUnlimitedchecked && _vm.isShowIsUnlimited)?_c('a-form-item',{attrs:{"label":_vm.l('SubscriptionEndDateUtc')}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'subscriptionEndUtc',
          {
            rules: [
              {
                required: true,
                message: _vm.l('ThisFieldIsRequired'),
              } ],
          } ]),expression:"[\n          'subscriptionEndUtc',\n          {\n            rules: [\n              {\n                required: true,\n                message: l('ThisFieldIsRequired'),\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":"请选择日期"},on:{"change":_vm.subscriptionEndDateUtconChange}})],1):_vm._e(),_c('br'),_c('a-checkbox',{attrs:{"disabled":_vm.IsInTrialPerioddisabled,"checked":_vm.isInTrialPeriod},on:{"change":function($event){return _vm.checkedChange('isInTrialPeriod', $event)}}},[_vm._v(_vm._s(_vm.l("IsInTrialPeriod")))]),_c('br'),_c('a-checkbox',{attrs:{"checked":_vm.shouldChangePasswordOnNextLogin},on:{"change":function($event){return _vm.checkedChange('shouldChangePasswordOnNextLogin', $event)}}},[_vm._v(_vm._s(_vm.l("ShouldChangePasswordOnNextLogin")))]),_c('br'),_c('a-checkbox',{attrs:{"checked":_vm.sendActivationEmail},on:{"change":function($event){return _vm.checkedChange('sendActivationEmail', $event)}}},[_vm._v(_vm._s(_vm.l("SendActivationEmail")))]),_c('br'),_c('a-checkbox',{attrs:{"checked":_vm.isActive},on:{"change":function($event){return _vm.checkedChange('isActive', $event)}}},[_vm._v(_vm._s(_vm.l("IsActive")))]),_c('a-form-item',{staticClass:"btn--container"},[_c('a-button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.close()}}},[_vm._v(_vm._s(_vm.l("Cancel")))]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(_vm._s(_vm.l("Save")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }