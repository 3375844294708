<template>
    <a-spin :spinning="spinning">
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" />
                <span v-if="id && type!=1">{{ l('EditRole') }}</span>
                <span v-if="!id && type!=1">{{ l('CreateNewRole') }}</span>
                <span v-if="id!=null && type===1">{{ l('权限设置') }}</span>
            </div>
        </div>
        <!-- 提示 -->
        <a-alert :message="l('Note_RefreshPageForPermissionChanges')" type="info" closable showIcon />
        <!-- tab切换 -->
        <a-tabs defaultActiveKey="1" @change="tabChange">
            <!-- 角色名称v-if="type!=1"-->
            <a-tab-pane key="1" v-if="type!=1">
                <span slot="tab">
                    <a-icon type="medicine-box" />
                    {{ l('RoleName') }}
                </span>
                <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
                    <a-form-item :label="l('RoleName')">
                        <a-input :placeholder="l('RoleName')"
                                 v-decorator="['displayName', { rules: [
                                { required: true, message: l('ThisFieldIsRequired') },
                                { message: l('MaxLength'),max:64  }
                             ] }]" />
                </a-form-item>
                    <!-- <a-form-item :label="l('RoleName')">
                        <a-switch checkedChildren="开" unCheckedChildren="关" defaultChecked />
                    </a-form-item> -->
                    <!-- <a-form-item>
                        <span slot="label">
                            {{ l('Default') }}&nbsp;
                            <a-tooltip :title="l('DefaultRole_Description')">
                                <a-icon type="question-circle-o" />
                            </a-tooltip>
                        </span>
                        <a-switch v-decorator="['isDefault', {valuePropName: 'checked', initialValue: false} ]" :checkedChildren="l('Yes')" :unCheckedChildren="l('No')" />
                    </a-form-item> -->
                </a-form>
            </a-tab-pane>
            <!-- 功能权限 -->
            <a-tab-pane key="2" v-if="type===1">
                <span slot="tab">
                    <a-icon type="safety-certificate" />
                    {{ l('页面权限') }}
                </span>                
                <search-role-tree
                    v-if="searchRoleTreeShow"
                    :multiple="true"
                    :dropDownStyle="{ 'max-height': '500px' }"
                    :selectedPermission="selectedPermission"
                    @selectedPermissionChange="refreshGoFirstPage"
                    @emitSpinning="updateSpinning"
                    @emitHandleSubmit="handleSubmit"
                    @emitClose="close"
                >
                </search-role-tree>
            </a-tab-pane>

            <!-- 字段权限 -->
            <a-tab-pane key="3" v-if="id!=null && type===1">
                <span slot="tab">
                    <a-icon type="setting" />
                    <!-- {{ l('PermissionsForField') }} -->
                    字段权限
                </span>
                <field-authority :multiple="true"
                                 :roleId="id"
                                 :dropDownStyle="{ 'max-height': '500px' }"
                                 @selectedPermissionChange="refreshGoFirstPage"
                                 @emitSpinning="updateSpinning"
                                 @emitCloseModal="close"
                                 @emitCloseModalSuccess="successClose">
                </field-authority>
            </a-tab-pane>

            <!-- 数据权限 -->
            <a-tab-pane key="4" v-if="id!=null && type===1">
                <span slot="tab">
                    <a-icon type="unordered-list" />
                    <!-- {{ l('PermissionsForField') }} -->
                    数据权限
                </span>
                <data-authority :roleId="id"
                                @emitSpinning="updateSpinning"
                                @emitCloseModal="close"
                                @emitCloseModalSuccess="successClose">
                </data-authority>
            </a-tab-pane>
        </a-tabs>
        <!-- 按钮 -->
        <div class="modal-footer" v-show="curTabKey === '1' || curTabKey === '2'">
            <a-button :disabled="saving" @click="close()" type="button">
                <a-icon type="close-circle" />
                {{ l('Cancel') }}
            </a-button>
            <a-button :loading="saving" :type="'primary'" @click="handleSubmit()" v-if="type!=1">
                <a-icon type="save" />
                {{ l('Save') }}
            </a-button>
        </div>
    </a-spin>
</template>

<script>
    import { AppComponentBase } from "@/shared/component-base";
    import { ModalComponentBase } from "@/shared/component-base";
    import { RoleServiceProxy } from "@/shared/service-proxies";
    import Bus from "@/shared/bus/bus";
    import SearchRoleTree from "../search-role-tree/index.vue";
    import FieldAuthority from "../field-authority/field-authority"
    import DataAuthority from "../data-authority/data-authority"


    export default {
        name: "create-or-edit-role",
        mixins: [AppComponentBase, ModalComponentBase],
        data() {
            return {
                spinning: false,
                // 表单
                formLayout: "horizontal",
                form: this.$form.createForm(this, { name: "coordinated" }),
                // 选中的权限过滤
                selectedPermission: [],
                role: {
                    // id: 1,
                    // displayName: "ceshi"
                },
                curTabKey: '1',
                searchRoleTreeShow: false,
            };
        },
        components: {
            SearchRoleTree,
            FieldAuthority,
            DataAuthority
        },
        created() {
            this.fullData(); // 模态框必须,填充数据到data字段
            this._roleServiceProxy = new RoleServiceProxy(this.$apiUrl, this.$api);
            this.getData();
        },
        methods: {
            /**
             * 获取数据
             */
            getData() {
                this.spinning = true;
                this._roleServiceProxy
                    .getForEdit(this.id ? this.id : undefined)
                    .finally(() => {
                        this.spinning = false;
                        this.searchRoleTreeShow = true
                    })
                    .then(result => {
                        console.log(result);
                        this.form.setFieldsValue({
                            displayName: result.role.displayName,
                            isDefault: result.role.isDefault
                        });
                        let sameObj = result.permissions.filter(obj =>
                            result.grantedPermissionNames.some(
                                obj1 => obj1 == obj.name
                            )
                        );
                        sameObj.map(item => {
                            this.selectedPermission.push(item.name);
                        });
                    });
            },
            /**
             * 提交表单
             */
            handleSubmit() {
                this.form.validateFields((err, values) => {
                    if (!err) {
                        console.log("Received values of form: ", values);
                        let params = {
                            role: {
                                displayName: values.displayName,
                                id: this.id,
                                isDefault: values.isDefault
                                    ? values.isDefault
                                    : false
                            },
                            grantedPermissionNames: this.selectedPermission
                        };
                        console.log(params)
                        this.saving = true;
                        this.spinning = true;
                        this._roleServiceProxy
                            .createOrUpdate(params)
                            .finally(() => {
                                this.saving = false;
                                this.spinning = false;
                            })
                            .then(() => {
                                this.notify.success(this.l("SavedSuccessfully"));
                                this.success(true);
                            });
                    }
                });
            },
            /**
             * 选择完权限过滤
             */
            refreshGoFirstPage(data) {
                this.selectedPermission = data;
            },
            /**
             * onTabChange
             */
            tabChange(activeKey) {
                this.curTabKey = activeKey
            },
            successClose() {
                this.success(true);
            },
            /**
             * 字段权限组件的spinning控制
             */
            updateSpinning(newV) {
                this.spinning = newV === '1' ? true : false
            }
        }
    };
</script>

<style scoped lang="less">
    .modal-header {
        .anticon

    {
        margin-right: 10px;
    }

    }

    .pagination {
        margin: 10px auto;
        text-align: right;
    }
</style>
