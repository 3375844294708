<template>
  <a-spin :spinning="spinning">

    <h3>添加数据权限</h3>
    <div class="input-wrapper">
      <a-row type="flex" justify="space-between">
        <a-col :sm="11">
          <a-input placeholder="过滤器名称" v-model="curEditItem.filterName" />
        </a-col>
        <a-col :sm="11">
          <a-input placeholder="filterCode" v-model="curEditItem.filterCode" />
        </a-col>
      </a-row>

      <a-row :style="{marginTop: '10px'}" type="flex" justify="space-between">
        <a-col :sm="20">
          <a-input placeholder="过滤器描述" v-model="curEditItem.remark" />
        </a-col>
        <a-col :sm="4" :style="{textAlign: 'right'}">
          <a-button :loading="saving" :type="'primary'" @click="saveItem">
            <a-icon type="save" />{{ l('Save') }}
          </a-button>
        </a-col>
      </a-row>

    </div>

    <a-divider></a-divider>

    <div class="table-content">
       <a-table
        bordered
        size="small"
        :rowKey="tableData => tableData.id"
        :row-selection="rowSelection"
        :columns="columns"
        :data-source="tableData"
      >
        <span slot="actions" slot-scope="text, record">
          <!-- 修改 -->
          <a class="table-edit" @click="editItem(record)">
            <a-icon type="edit" />{{ l("Edit") }}
          </a>
          <!-- 删除 -->
          <a-popconfirm
            placement="top"
            class="table-delete"
            :okText="l('Ok')"
            :cancelText="l('Cancel')"
            @confirm="deleteItem(record)"
          >
            <template slot="title">
              {{ l("ConfirmDeleteWarningMessage") }}
            </template>
            <a class="table-delete">
              <a-icon type="delete" />{{ l("Delete") }}
            </a>
          </a-popconfirm>
        </span>

      </a-table>
    </div>


    <div class="modal-footer">
      <a-button @click="close()" type="button" :style="{marginRight: '10px'}">
        <!-- <a-icon type="close-circle" /> -->
        {{ l('Cancel') }}
      </a-button>
      <a-button :type="'primary'" @click="addItems">
        <!-- <a-icon type="save" /> -->
        <!-- {{ l('Save') }} -->
        确定
      </a-button>
    </div>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalComponentBase } from "@/shared/component-base";
import Bus from "@/shared/bus/bus";

export default {
  name: 'create-or-edit-data-auth',
  mixins: [AppComponentBase, ModalComponentBase],
  data() {
    return {
      spinning: false,
      roleId: null,
      roleService: null,
      curEditItem: {
        filterCode: '',
        filterName: '',
        remark: ''
      },
      columns: [
        {
          title: '过滤名称',
          dataIndex: 'filterName',
          align: "center",
          scopedSlots: { customRender: 'filterName' }
        },
        {
          title: 'filterCode',
          dataIndex: 'filterCode',
          align: "center",
          scopedSlots: { customRender: 'filterCode' }
        },
        {
          title: '描述',
          dataIndex: 'remark',
          align: "center",
          scopedSlots: { customRender: 'remark' }
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          align: "center",
          scopedSlots: { customRender: 'actions' }
        }
      ],
      tableData: [],
      oTableData: [],
      showList: [],
      selectedRowKeys: [],
      selectedRow: []
    }
    
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRow) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectedRow = selectedRow
          console.log(this.selectedRow)
        },
        getCheckboxProps: record => ({
          props: {
            disabled: this.oTableData.filter(item => item.filterCode === record.filterCode).length > 0,
            filterCode: record.filterCode
          }
        })
      }
    }
  },
  create() {
  },
  mounted() {
    this.roleService = this.modelData.roleService
    this.roleId = this.modelData.roleId
    this.oTableData = this.modelData.oTableData
    this._rowFilterGetAllFilter()
  },
  methods: {
    editItem(item){
      this.curEditItem = Object.assign({}, item)
    },
    saveItem() {
      if (this.curEditItem.filterName === null || this.curEditItem.filterName === '') {
        abp.message.warn('请填写过滤名称')
        return
      } else if (this.curEditItem.filterCode === null || this.curEditItem.filterCode === '') {
        abp.message.warn('请填写filterCode')
        return
      }
      this._rowFilterCreateOrUpdate()
    },
    deleteItem(item) {
      const ids = [item.id]
      this._rowFilterDeleteFilters(ids)
    },
    addItems() {
      if (!this.selectedRow.length) {
        abp.message.warn('请选择你要添加的过滤器')
        return
      }
      let arr = []
      this.selectedRow.map(item => {
        arr.push({
          roleId: this.roleId,
          rowFilterId: item.id
        })
      })
      // console.log(this.selectedRow)
      this._rowFilterAddRowFilterToRole(arr)
    },
    _rowFilterAddRowFilterToRole(arr) {
      this.spinning = true
      this.roleService.rowFilterAddRowFilterToRole(arr)
        .finally(() => {
          this.spinning = false
        })
        .then((res) => {
          this.$notification["success"]({
            message: '添加成功'
          })
          this.success(true);
        })
    },
    _rowFilterDeleteFilters(ids) {
      this.spinning = true
      this.roleService.rowFilterDeleteFilters(ids)
        .finally(() => {
          this.spinning = false
        })
        .then((res) => {
          this.$notification["success"]({
            message: '删除成功'
          })
          this.curEditItem = {
            filterCode: '',
            filterName: '',
            remark: ''
          }
          this._rowFilterGetAllFilter()
        })
    },
    _rowFilterCreateOrUpdate() {
      this.spinning = true
      const opts = {
        filterName: this.curEditItem.filterName,
        filterCode: this.curEditItem.filterCode,
        roleId: this.curEditItem.roleId,
        isEnabled: this.curEditItem.isEnabled,
        remark: this.curEditItem.remark,
        filterType: this.curEditItem.filterType,
        roleName: this.curEditItem.roleName,
        id: this.curEditItem.id
      }
      // console.log(opts)
      this.roleService.rowFilterCreateOrUpdate(opts)
        .finally(() => {
          this.spinning = false
        })
        .then((res) => {
          this.$notification["success"]({
            message: this.l("SavedSuccessfully")
          })
          this.curEditItem = {
            filterCode: '',
            filterName: '',
            remark: ''
          }
          this._rowFilterGetAllFilter()
          // this.success(true);
        })
    },
   _rowFilterGetAllFilter() {
     this.spinning = true
     this.roleService.rowFilterGetAllFilter()
      .finally(() => {
        this.spinning = false
      })
      .then((res) => {
        this.tableData = res
      })
   }
  }
}

</script>

<style scoped lang="less">
h3{
  // padding: 10px 0;
}
.input-wrapper{
  // margin-top: 20p;
  padding: 5px 0;
}

.table-content{
  .table-edit{
    margin-right: 10px;
  }
}

.modal-footer{
  padding: 10px 0;
  margin: 0;
}
</style>