<template>
  <a-spin :spinning="spinning">
    <!-- 标题 -->
    <div class="modal-header">
      <div class="modal-title">
        <a-icon type="user" />
        <span v-if="id && type!=1&& type!=2">{{ l("修改用户") }}</span>
        <span v-if="!id">{{ l("CreateNewUser") }}</span>
        <span v-if="id && type==1">{{ l("灵工项目授权") }}</span>
         <span v-if="id && type==2">{{ l("灵工项目授权") }}</span>
      </div>
    </div>
    <!-- tab切换 -->
    <a-tabs  @change="tabChange">
      <!-- 用户信息 -->
      <a-tab-pane key="1" v-if="type!=1&&type!=2">
        <span slot="tab">
          <a-icon type="user" />
          {{ l("UserInformations") }}
        </span>
        <a-form
          :form="form"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 24 }"
          layout="horizontal"
          labelAlign="left">
          <!-- 用户名 -->
          <a-form-item :label="l('UserName')">
            <a-input
              :placeholder="l('UserName')"
              v-decorator="[
                'userName',
                {
                  rules: [
                    { required: true, message: l('ThisFieldIsRequired') },
                    { message: l('MaxLength'), max: 32 },
                  ],
                },
              ]"
            />
          </a-form-item>
          <!-- 用户姓名 -->
          <a-form-item :label="l('RealName')">
            <a-input
              :placeholder="l('RealName')"
              v-decorator="[
                'realName',
                {
                  rules: [
                    //{ required: true, message: l('ThisFieldIsRequired') },
                    { message: l('MaxLength'), max: 32 },
                  ],
                },
              ]"
            />
          </a-form-item>
          <!-- 头像 -->
          <!-- <a-form-item :label="l('UploadProfilePicture')">
            <a-upload
              name="file"
              :action="uploadPictureUrl"
              listType="picture-card"
              :fileList="fileList"
              :beforeUpload="beforeUpload"
              :data="{ subdir: this.subdirName }"
              accept="image/*"
              @preview="handlePreview"
              @change="uploadPictureChange($event)"
            >
              <div v-if="fileList.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  {{ l("UploadProfilePicture") }}
                </div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-item> -->
          <!-- 邮箱 -->
          <a-form-item :label="l('EmailAddress')">
            <a-input
              :placeholder="l('EmailAddress')"
              v-decorator="[
                'emailAddress',
                {
                  rules: [
                    {
                      type: 'email',
                      message: l('InvalidEmailAddress'),
                    },
                    {
                      required: true,
                      message: l('ThisFieldIsRequired'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
          <!-- 电话号码 -->
          <a-form-item :label="l('PhoneNumber')">
            <a-input
              :placeholder="l('PhoneNumber')"
              v-decorator="[
                'phoneNumber',
                {
                  rules: [{ message: l('MaxLength'), max: 24 }],
                },
              ]"
            />
          </a-form-item>
          <!-- 使用随机密码 -->
          <a-form-item style='display:none'>
            <span slot="label">
              {{ l("SetRandomPassword") }}
            </span>
            <a-switch
              v-decorator="['setRandomPassword', { valuePropName: 'checked' }]"
              :unCheckedChildren="l('No')"
              :checkedChildren="l('Yes')"   
              @change="isSetRandomPassword = !isSetRandomPassword"
            />
          </a-form-item>
           <!-- 是否修改密码 -->
          <a-form-item v-if="id">
            <span slot="label">
              {{ l("是否修改密码") }}
            </span>
            <a-switch
              v-decorator="['editPassword', { valuePropName: 'checked' }]"
              :unCheckedChildren="l('No')"
              :checkedChildren="l('Yes')"   input
              @change="isEditPassWord = !isEditPassWord"
            />
          </a-form-item>
          <!-- 密码 -->
          <a-form-item :label="l('Password')" v-if="!isEditPassWord || !id">
            <a-input
              :placeholder="l('Password')"
              type="text"
              v-decorator="[
                'password',
                {
                  rules: [
                    { required: true, message: l('ThisFieldIsRequired') },
                    { message: l('MinLength'), min: 6 },
                    { message: l('MaxLength'), max: 32 },
                  ],
                },
              ]"
            />
          </a-form-item>
          <!-- 密码核对 -->
          <!-- <a-form-item :label="l('PasswordRepeat')" v-if="!isSetRandomPassword">
            <a-input
              :placeholder="l('PasswordRepeat')"
              type="password"
              v-decorator="[
                'PasswordRepeat',
                {
                  rules: [
                    {
                      required: true,
                      message: l('ThisFieldIsRequired'),
                    },
                    {
                      validator: compareToFirstPassword,
                    },
                  ],
                },
              ]"
            />
          </a-form-item> -->
          <a-row :gutter="24">
            <!-- 下次登录需要修改密码 -->
            <!--<a-col :span="15" class="">
              <a-form-item>
                <span slot="label">
                  {{ l("ShouldChangePasswordOnNextLogin") }}
                </span>
                <a-switch
                  v-decorator="['needToChangeThePassword', { valuePropName: 'checked' }]"
                  :checkedChildren="l('Yes')"
                  :unCheckedChildren="l('No')"
                />
              </a-form-item>
            </a-col>-->
            <!-- 发送激活右键 -->
            <!-- <a-col :span="9" class="">
              <a-form-item>
                <span slot="label">
                  {{ l("SendActivationEmail") }}
                </span>
                <a-switch
                  v-decorator="['sendActivationEmail', { valuePropName: 'checked' }]"
                  :checkedChildren="l('Yes')"
                  :unCheckedChildren="l('No')"
                />
              </a-form-item>
            </a-col> -->
          </a-row>
          <a-row :gutter="24">
            <!-- 激活 -->
            <a-col :sm="15" class="" style="display:none">
              <a-form-item>
                <span slot="label">
                  {{ l("Active") }}
                </span>
                <a-switch
                  v-decorator="['isActive', { valuePropName: 'checked' }]"
                  :checkedChildren="l('Yes')"
                  :unCheckedChildren="l('No')"
                />
              </a-form-item>
            </a-col>
            <!-- 启用锁定 -->
            <a-col :sm="9" class="">
              <a-form-item>
                <span slot="label">
                  {{ l("IsLockoutEnabled") }}
                </span>
                <!-- { valuePropName: 'checked' } -->
                <a-switch
                  v-decorator="['isLockoutEnabled', { valuePropName: 'checked' }]"
                  :checkedChildren="l('Yes')"
                  :unCheckedChildren="l('No')"
                />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-tab-pane>
      <!-- 角色 -->
      <a-tab-pane key="2" v-if="type!=1&&type!=2">
        <a-badge slot="tab" :count="checkedRoles.length">
          <a-icon type="medicine-box" />
          {{ l("Roles") }}
        </a-badge>
        <a-checkbox-group v-model="checkedRoles" :options="roleList"></a-checkbox-group>
      </a-tab-pane>
      <!-- 组织单元 -->
      <a-tab-pane key="3" v-if="type!=1&&type!=2">
        <span slot="tab">
          <a-icon type="share-alt" />
          {{ l("OrganizationUnits") }}
        </span>
        <organization-unit-tree
          :multiple="true"
          :dropDownStyle="{ 'max-height': '500px' }"
          :oData="organizationList"
          @selectedUnitChange="refreshGoFirstPage"
        >
        </organization-unit-tree>
      </a-tab-pane>
      <!-- 组织单元 -->
      <a-tab-pane key="4"  v-if="type!=1&&type!=2">
        <span slot="tab">
          <a-icon type="share-alt" />
          用户岗位
        </span>
        <a-select
          mode="multiple"
          :value="userPosition"
          style="width: 100%"
          placeholder="请选择"
          :filter-option="filterOption"
          option-filter-prop="children"
          show-search
          @deselect="deselectPosition"
          @select="selectPosition"
          :options="allUserPosition"
        >
        </a-select>
      </a-tab-pane>

      <a-tab-pane key="5" v-if="id && type===1">
        <span slot="tab">
          <a-icon type="share-alt" />
          灵工项目授权
        </span>
        <!-- <a-select
          mode="multiple"
          :value="authedLGProject"
          style="width: 100%"
          placeholder="请选择"
          :filter-option="filterOption"
          option-filter-prop="children"
          show-search
          @deselect="deselectProjectAuth"
          @select="selectLGProjectAuth"
          :options="allLingGongProject"
        >
        </a-select> -->
        <project-auth :user-id="id"></project-auth>
      </a-tab-pane>

      <a-tab-pane key="6" v-if="id  && type===2">
        <span slot="tab">
          <a-icon type="share-alt" />
          非自然人项目授权
        </span>
        <a-select
          mode="multiple"
          :value="authedUPProject"
          style="width: 100%"
          placeholder="请选择"
          :filter-option="filterOption"
          option-filter-prop="children"
          show-search
          @deselect="deselectProjectAuth"
          @select="selectUPProjectAuth"
          :options="allUnaturalProject"
        >
        </a-select>
      </a-tab-pane>

      <a-tab-pane key="7" v-if="id && type===1">
        <span slot="tab">
          <a-icon type="share-alt" />
          标签授权
        </span>
        <tag-auth :user-id="id"></tag-auth>
      </a-tab-pane>
    </a-tabs>
    <!-- 按钮 -->
    <div class="modal-footer" v-if="tabIndex !== '5' && tabIndex !== '7'">
      <a-button :disabled="saving" @click="close()" type="button">
        <a-icon type="close-circle" />
        {{ l("Cancel") }}
      </a-button>
      <a-button :loading="saving" :type="'primary'" @click="handleSubmit()" v-if="type!=1 && type!=2">
        <a-icon type="save" />
        {{ l("Save") }}
      </a-button>
    </div>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalComponentBase } from "@/shared/component-base";
import { AppConsts } from "@/abpPro/AppConsts";
import {
  ProfileServiceProxy,
  UserServiceProxy,
  ProjectServiceProxy,
} from "@/shared/service-proxies";

import projectAuthApi from "../../../../shared/service-proxies/project/auth/project-auth-proxies";
import { ProjectServiceProxy as UPProjectServiceProxy } from "@/app/unnaturalperson/project/services/project-proxy.ts";
import { OrganizationPositionServiceProxy } from "../services/positions";
import OrganizationUnitTree from "../../shared/organization-unit-tree/organization-unit-tree";
import { environment } from "@/environments/environment";
import ProjectAuth from '../project-auth/project-auth'
import TagAuth from '../tag-auth/tag-auth'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default {
  name: "create-or-edit-user",
  mixins: [AppComponentBase, ModalComponentBase],
  components: {
    OrganizationUnitTree,
    ProjectAuth,
    TagAuth,
  },
  data() {
    return {
      spinning: false,
      id: "",
      // 表单
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      // 用户
      user: {},
      previewVisible: false,
      previewImage: "",
      // 上传图片
      fileList: [],
      uploadPictureUrl: AppConsts.uploadBaseUrl + "/API/Upload",
      subdirName: environment.production ? "abpProd" : "abpDev",
      maxProfilPictureBytesValue: AppConsts.maxProfilPictureMb,
      uploadHeaders: {},
      _profileServiceProxy: "",
      _userServiceProxy: "",
      // 是否设置随机密码
      isSetRandomPassword: false,
      isEditPassWord: true,
      // 角色list
      roleList: [],
      checkedRoles: [],
      // 选择组织单源数据
      organizationList: {},
      selectedUnit: [],
      positionService: null,
      userPosition: [],
      allUserPosition: [],
      allLingGongProject: [],
      allUnaturalProject: [],
      lgprojectSerivceProxy: null,
      upprojectSerivceProxy: null,
      authedLGProject: [],
      authedUPProject: [],
      tabIndex: '1',
    };
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    Object.assign(this.uploadHeaders, {
      Authorization: "Bearer " + abp.auth.getToken(),
    });
    this._profileServiceProxy = new ProfileServiceProxy(this.$apiUrl, this.$api);
    this.lgprojectSerivceProxy = new ProjectServiceProxy(this.$apiUrl, this.$api);
    this.upprojectSerivceProxy = new UPProjectServiceProxy(this.$apiUrl, this.$api);
    this._userServiceProxy = new UserServiceProxy(this.$apiUrl, this.$api);
    this.positionService = new OrganizationPositionServiceProxy(this.$apiUrl, this.$api);
    this.init();
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    /**
     * 初始化
     */
    async init() {
      this.spinning = true;
      // let allLgProject = await this.lgprojectSerivceProxy.GetAll();
      // this.allLingGongProject = allLgProject.map((item) => {
      //   return {
      //     title: item.projectName + "(" + item.projectCode + ")",
      //     value: item.id,
      //     label: item.projectName + "(" + item.projectCode + ")",
      //   };
      // });

      let allUPProject = await this.upprojectSerivceProxy.GetAll();
      this.allUnaturalProject = allUPProject.map((item) => {
        return {
          title: item.projectName + "(" + item.projectCode + ")",
          value: item.id,
          label: item.projectName + "(" + item.projectCode + ")",
        };
      });
      await this.loadUserAuthProjects();
      let allPosition = await this.positionService.getPositionOption();
      this.allUserPosition = allPosition;
      await this.getUserOption();
      this._userServiceProxy
        .getForEditTree(this.id)
        .finally(() => (this.spinning = false))
        .then((result) => {
          this.roleList = result.roles.map((item) => {
            return { label: item.roleDisplayName, value: item.roleId };
          });
          console.log('result:', result)
          this.organizationList = Object.assign(result, {
            // selectedOrganizationUnits: this.selectedUnit,
            selectedOrganizationUnits: this.returnIds(result.allOrganizationUnits, result.memberedOrganizationUnits),
          });
          this.checkedRoles = result.roles
            .filter((item) => item.isAssigned)
            .map((item) => item.roleId);
          // 设置表单
          this.$nextTick(() => {
            this.form.setFieldsValue({
              userName: result.user.userName,
              realName: result.user.realName,
              emailAddress: result.user.emailAddress,
              phoneNumber: result.user.phoneNumber,
              //password: result.user.password ? true : false,
              setRandomPassword: result.user.needToChangeThePassword ? true : false,
              sendActivationEmail: result.user.sendActivationEmail ? true : false,
              isActive: result.user.isActive ? true : false,
              isLockoutEnabled: result.user.isLockoutEnabled ? true : false,
            });
          });
          // 设置头像
          if (result.user.profilePictureId) {
            this.getProfilePicture(result.user.profilePictureId);
          }
        });
    },
    // 把selectedOrganizationUnits把code转换成id
    returnIds(oArr, codes) {
      let ids = []
      oArr.map(item => {
        if (codes.filter(v => v === item.code).length) {
          ids.push(item.id)
        }
      })
      return ids
    },
    async getUserOption() {
      let userp = await this.positionService.getUserPositionOption({
        userId: this.id,
      });
      this.userPosition = userp.map((value, index) => {
        return value.value;
      });
    },
    /**
     * 验证密码输入
     */
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("password")) {
        callback(this.l("PasswordsDontMatch"));
      } else {
        callback();
      }
    },
    /**
     * 提交表单
     */
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(this.fileList);
          let parmas = {
            assignedRoleNames: [],
            organizationUnits: this.selectedUnit,
            user: {},
          };
          // 处理头像
          Object.assign(values, {
            // profilePictureId: this.id
            //   ? this.fileList.length
            //     ? this.fileList[0].name
            //     : ""
            profilePictureId: "",
            needToChangeThePassword: values.needToChangeThePassword
              ? values.needToChangeThePassword
              : false,
            id: this.id,
          });
          parmas.user = values;
          // 处理角色
          parmas.assignedRoleNames = this.organizationList.roles
            .filter((obj) => this.checkedRoles.some((item) => item === obj.roleId))
            .map((item) => item.roleName);
          this.spinning = true;
          this._userServiceProxy
            .createOrUpdate(parmas)
            .finally(() => {
              this.spinning = false;
            })
            .then(() => {
              this.notify.success(this.l("SavedSuccessfully"));
              this.success(true);
            });
        }
      });
    },
    /**
     * 上传图片
     */
    beforeUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif";
      if (!isJPG) {
        abp.message.error(this.l("OnlySupportPictureFile"));
      }
      const isLtXM = file.size / 1024 / 1024 < this.maxProfilPictureBytesValue;
      console.log(isLtXM);
      console.log(this.maxProfilPictureBytesValue);
      if (!isLtXM) {
        abp.message.error(
          this.l("ProfilePicture_Warn_SizeLimit", this.maxProfilPictureBytesValue)
        );
      }
      const isValid = isJPG && isLtXM;
      return isValid;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      console.log(file);
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    uploadPictureChange({ fileList }) {
      console.log(fileList);
      this.fileList = fileList;
    },
    /**
     * 通过头像Id获取头像
     * @param profilePictureId 头像Id
     */
    getProfilePicture(profilePictureId) {
      if (profilePictureId) {
        this.spinning = true;
        this._profileServiceProxy
          .getProfilePictureById(profilePictureId)
          .finally(() => (this.spinning = false))
          .then((result) => {
            if (result && result.profilePicture) {
              let profilePreviewImage = "data:image/jpeg;base64," + result.profilePicture;
              // 把图像加到头像列表 显示
              this.fileList = [
                {
                  uid: -1,
                  name: profilePictureId,
                  status: "done",
                  url: profilePreviewImage,
                },
              ];
            }
          });
      }
    },
    /**
     * 选择完权限过滤
     */
    refreshGoFirstPage(data) {
      this.selectedUnit = data;
      console.log(this.selectedUnit);
    },

    async loadUserAuthProjects() {
      let curUserAuthedProject = await projectAuthApi.GetListByUserId(this.id);
      let curUserAuthedLGProject = curUserAuthedProject.filter(
        (item) => item.projectType == 1
      );
      let curUserAuthedUPProject = curUserAuthedProject.filter(
        (item) => item.projectType == 2
      );

      this.authedLGProject = curUserAuthedLGProject.map((item) => {
        return item.projectId;
      });

      this.authedUPProject = curUserAuthedUPProject.map((item) => {
        return item.projectId;
      });
    },
    async selectProjectAuth(value, type) {
      this.spinning = true;
      let auth = {
        userId: this.id,
        projectId: value,
        projectType: type,
      };
      let result = await projectAuthApi.add(auth);
      await this.loadUserAuthProjects();
      this.spinning = false;
    },

    async selectLGProjectAuth(value) {
      this.spinning = true;
      await this.selectProjectAuth(value, 1);
      abp.message.success("授权成功！");
      this.spinning = false;
    },
    async selectUPProjectAuth(value) {
      this.spinning = true;
      await this.selectProjectAuth(value, 2);
      abp.message.success("授权成功！");
      this.spinning = false;
    },

    async deselectProjectAuth(value) {
      this.spinning = true;
      let result = await projectAuthApi.remove(value, this.id);
      await this.loadUserAuthProjects();
      abp.message.success("取消授权成功！");
      this.spinning = false;
    },
    selectPosition(value) {
      this.spinning = true;
      let res = this.positionService
        .addUserPosition({
          userId: this.id,
          positionId: value,
        })
        .finally(() => (this.spinning = false))
        .then(async (result) => {
          await this.getUserOption();
        });
    },
    deselectPosition(value) {
      this.spinning = true;
      let res = this.positionService
        .removeUserPosition({
          userId: this.id,
          positionId: value,
        })
        .finally(() => (this.spinning = false))
        .then(async (result) => {
          await this.getUserOption();
        });
    },
    tabChange(index) {
      this.tabIndex = index
    }
  },
};
</script>

<style scoped lang="less">
.anticon-user {
  margin-right: 10px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.halfwidth {
  /deep/ .ant-form-item-label {
    width: 30%;
  }
}
.next-password {
  /deep/ .ant-form-item-label {
    width: 45%;
  }
}
</style>
