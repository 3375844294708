<template>
  <div class="project-auth">
    <a-spin :spinning="spinning">
      <template v-for="(tag, index) in tags">
        <a-tooltip :key="index" :title="tag">
          <a-tag style="padding: 6px 8px; font-size: 13px;" :key="tag" color="blue" :closable="true" @close="() => handleClose(tag)">
            {{ tag }}
          </a-tag>
        </a-tooltip>
      </template>
      <a-input
        v-if="inputVisible"
        ref="input"
        type="text"
        size="small"
        :style="{ width: '120px', height: '34px',  }"
        :value="inputValue"
        @change="handleInputChange"
        @blur="handleInputConfirm"
      />
      <a-tag v-else style="padding: 6px 8px; font-size: 13px; background: #fff; borderStyle: dashed; color: #108ee9;" @click="showInput">
        <a-icon type="plus" /> 新增
      </a-tag>

      <a-row style="margin-top: 20px;">
        <a-col :span="24" align="right">
          <a-button :loading="spinning" :type="'primary'" @click="handleSubmit()">
            <a-icon type="save" />
            {{ l("Save") }}
          </a-button>
        </a-col>
      </a-row>

    </a-spin>
  </div>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CommonServiceProxy } from "@/shared/common-service";
// import moment from "moment";

export default {
  mixins: [AppComponentBase],
  props: [
    'userId'
  ],
  name: 'tag-auth',
  components: {
  },
  data() {
    return {
      spinning: false,
      commonService: null,
      tags: [],
      inputVisible: false,
      inputValue: '',
    }
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
  },
  mounted() {
    this._getLabels()
  },
  methods: {
    handleSubmit() {
      this._updateLabels(this.tags.length === 1 ? this.tags[0] : this.tags.join(','))
    },
    async _getLabels() {
      this.spinning = true
      try {
        let res = await this.commonService.get({
          url: '/api/services/app/User/GetLabels',
          params: {
            userId: this.userId,
          }
        })
        if (res && res.indexOf(',') === -1) {
          this.tags = [res]
        } else if (res && res.indexOf(',') !== -1) {
          this.tags = res.split(',')
        }
        this.spinning = false
      } catch (error) {
        console.log(error)
        this.spinning = false
      }
    },
    async _updateLabels(lables) {
      this.spinning = true
      try {
        let res = await this.commonService.put({
          url: '/api/services/app/User/UpdateLabels',
          params: {
            userId: this.userId,
            lables: lables,
          }
        })
        this.$notification.success({
          message: '提示',
          description:
            `保存成功`,
        });
        this._getLabels()
        this.spinning = false
      } catch (error) {
        console.log(error)
        this.spinning = false
      }
    },
    handleClose(removedTag) {
      const tags = this.tags.filter(tag => tag !== removedTag);
      console.log(tags);
      this.tags = tags;
      // this._updateLabels(tags.length === 1 ? tags[0] : tags.join(','))
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(function() {
        this.$refs.input.focus();
      });
    },

    handleInputChange(e) {
      this.inputValue = e.target.value;
    },

    handleInputConfirm() {
      const inputValue = this.inputValue.trim();
      console.log('inputValue:::', inputValue)
      console.log('inputValue:::', inputValue)

      let tags = this.tags;
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue];
        // console.log(tags);
        Object.assign(this, {
          tags,
          inputVisible: false,
          inputValue: '',
        });
        // this._updateLabels(tags.length === 1 ? tags[0] : tags.join(','))
      } else if (inputValue === '' && tags.indexOf(inputValue) !== -1) {
        this.$notification.warning({
          message: '提示',
          description:
            `标签：${inputValue}已存在`,
        });
      } else {
        Object.assign(this, {
          inputVisible: false,
          inputValue: '',
        });
      }
    },
  }
}

</script>


<style scoped lang="less">
.tag-auth {
  .tagStyle{
    padding: 3px 6px;
    font-size: 1;
  }
}
</style> 