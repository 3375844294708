<template>
    <h1>tenant-settings</h1>
</template>

<script>
    export default {
        name: "tenant-settings"
    }
</script>

<style scoped>

</style>
