<template>
  <a-spin :spinning="spinning">
    <!-- <react-table
      :table-data="tableData"
      :total-items="totalItems"
      :showTotal="showTotalFun"
      :actions-type="actionsType"
      :columns="columns"
      :hide-row-selection="true"
      :scroll="{ x: 800 }"
      :current-page="pageNumber"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSortData="updateSortData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    > -->
    <ele-table
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="false"
      :actionsType="actionsType"
      :current-page="pageNumber"
      :hide-row-selection="true"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <a-row :gutter="8" type="flex" align="middle">
        <a-col :span="12">
          <a-input-search
            name="name"
            @search="changeFilterText()"
            :placeholder="l('SearchWithThreeDot')"
            enterButton
            v-model="name"
          />
        </a-col>
        <a-col :span="8" :offset="4" align="right">
          <a @click="createOrEdit(null, 'create')">
            <a-icon type="plus" /> {{ l("添加账户") }}
          </a>
          <a-divider type="vertical"></a-divider>
          <a :title="l('Refresh')" @click="clearFilterAndRefresh()">
            <a-icon type="reload" />
          </a>
        </a-col>
      </a-row>
    </ele-table>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { CommonServiceProxy } from "@/shared/common-service";
import { ModalHelper } from "@/shared/helpers";
import EleTable from "@/components/ele-table";
import ReactModify from "@/components/react-modify";

export default {
  name: "bank-account",
  mixins: [AppComponentBase],
  props: ["id", "reqKey", "tableKey"],
  components: { EleTable },
  data() {
    return {
      spinning: false,
      commonService: null,
      selectedRowKeys: [],
      filteredInfo: null,
      sortedInfo: null,
      name: "",
      organizationUnitId: "",
      filterText: "",
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 50, skipCount: 0 },
      // 用户表格
      columns: [
        {
          title: this.l("开户行"),
          dataIndex: "name",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "name" },
        },
        {
          title: this.l("账户"),
          dataIndex: "account",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "account" },
        },
        {
          title: this.l("分行名称"),
          dataIndex: "branchName",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "branchName" },
        },
        {
          title: this.l("状态"),
          dataIndex: "status",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "status" },
          customRender: (text, record, index) => {
            var array = this.l("CommonStatus").split(",");
            switch (text) {
              case 1:
                return array[0];
              case 2:
                return array[1];
            }
            return "";
          },
        },
        {
          title: this.l("是否默认"),
          dataIndex: "isDefault",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "isDefault" },
          customRender: (text) => {
            if (text == true) {
              return "是";
            }
            return "否";
          },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          sorter: false,
          align: "center",
          scopedSlots: { customRender: "actions" },
        },
      ],
      // 用户数据
      tableData: [],
      actionsType: {},
      getDataParams: [],
      changeOpt: [],
    };
  },
  computed: {},
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initActionsType();
  },
  beforeDestroy() {},
  methods: {
    // 拉取数据
    async getData(opt, url) {
      this.spinning = true;
      let params = {
        name: this.name,
        filterText: this.filterText,
        sorting: this.request.sorting,
        skipCount: this.request.skipCount,
        maxResultCount: this.request.maxResultCount,
      };
      if (opt && opt.length) {
        if (!this.getDataParams.length) this.getDataParams = opt;
        opt.map((it) => {
          if (it.key && it.value) {
            params[it.key] = it.value;
          }
        });
      } else if (this.getDataParams && this.getDataParams.length) {
        this.getDataParams.map((it) => {
          if (it.key && it.value) {
            params[it.key] = it.value;
          }
        });
      }
      this.changeOpt = opt;
      try {
        let res = await this.commonService.get({
          url: url
            ? url
            : this.reqKey === "Organization"
            ? "/api/services/app/Organization/GetOrganizationBankAccountPaged"
            : "/api/services/app/Customer/GetCustomerBankAccountPaged",
          params: params,
        });
        this.spinning = false;
        // this.tableData = res.items.map((o) => {
        //     return {
        //         ...o,
        //         isDefaultStr: o.this.tableData = res.items ? "是" : "否",
        //     };
        // });
        this.tableData = res.items;
        this.totalItems = res.totalCount;
        this.pagerange = [
          (this.pageNumber - 1) * this.request.maxResultCount + 1,
          this.pageNumber * this.request.maxResultCount,
        ];
        this.totalPages = Math.ceil(
          res.totalCount / this.request.maxResultCount
        );
      } catch (error) {
        this.spinning = false;
      }
    },
    async changeFilterText(value) {
      this.request.skipCount = 0;
      this.filterText = value;
      await this.getData(this.changeOpt);
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
      this.name = "";
      this.filterText = "";
      this.getData(this.changeOpt);
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    // 删除
    async deleteItem(params) {
      this.spinning = true;
      try {
        let res = await this.commonService.delete({
          url:
            this.reqKey === "Organization"
              ? "/api/services/app/Organization/DeleteOrganizationBankAccount"
              : "/api/services/app/Customer/DeleteCustomerBankAccount",
          params: params,
        });
        this.spinning = false;
        this.getData();
        this.$notification["success"]({
          message: this.l("SuccessfullyRemoved"),
        });
        this.clearFilterAndRefresh();
      } catch (error) {
        this.spinning = false;
      }
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
    },
    // 初始化需要显示的func btn
    initActionsType() {
      let _this = this;
      let obj = {
        type: "bank-account",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: true,
              name: this.l("查看"),
              icon: "edit",
              fn: (data) => {
                _this.createOrEdit(data, "preview");
              },
            },
            {
              granted: true,
              name: this.l("Edit"),
              icon: "profile",
              fn: (data) => {
                _this.createOrEdit(data, "edit");
              },
            },
          ],
          delete: {
            granted: true,
            name: this.l("Delete"),
            fn: (data) => {
              _this.deleteItem(data.id);
            },
          },
        },
      };
      this.actionsType = obj;
    },
    async _getStatusType(type) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/Project/GetEnumNameValueArray",
          params: { enumType: type },
        });
        this.spinning = false;
        return res.map((item) => {
          return {
            label: item.key,
            value: parseInt(item.value),
          };
        });
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    // 增加修改查看
    async createOrEdit(item, type) {
      const list = [
        {
          label: "开户行",
          value: "name",
          type: "commonInput",
          disabled: false,
          required: true,
          rules: [{ max: 128, message: this.l("MaxLength") }],
          changeFn: () => {},
        },
        {
          label: "账户",
          value: "account",
          type: "commonInput",
          disabled: false,
          required: true,
          rules: [{ max: 128, message: this.l("MaxLength") }],
          changeFn: () => {},
        },
        {
          label: "分行名称",
          value: "branchName",
          type: "commonInput",
          disabled: false,
          required: true,
          rules: [{ max: 128, message: this.l("MaxLength") }],
          changeFn: () => {},
        },
        {
          label: "是否默认",
          value: "isDefault",
          type: "switch",
          disabled: false,
          required: true,
          rules: [],
          changeFn: () => {},
        },
        {
          label: "状态",
          value: "status",
          type: "commonSelect",
          disabled: false,
          required: true,
          rules: [{ required: true, message: "请选择状态" }],
          defaultValue: "",
          defaultSelectList: await this._getStatusType(3),
          changeFn: () => {},
        },
      ];
      let reqParams = [];
      if (item && item.id) reqParams.push({ key: "id", value: item.id });
      let otherPrams = {};
      ModalHelper.create(
        ReactModify,
        {
          params: {
            type: type, // edit,preview,create
            data: list,
            reqParams: [
              ...reqParams,
              {
                key: `${
                  this.reqKey === "Organization"
                    ? "organizationUnitId"
                    : "customerId"
                }`,
                value: this.id,
              },
            ],
            reqUrl: {
              getById:
                this.reqKey === "Organization"
                  ? "/api/services/app/Organization/GetOrganizationBankAccountById"
                  : "/api/services/app/Customer/GetCustomerBankAccountById",
              save:
                this.reqKey === "Organization"
                  ? "/api/services/app/Organization/SaveOrganizationBankAccount"
                  : "/api/services/app/Customer/SaveCustomerBankAccount",
            },
            otherParams: {},
            getItemfn: async (res, form, options) => {
              form.setFieldsValue({ ...res });
              if (options) {
                // ...
              }
            },
            submitfn: async (values, queryParams) => {
              let obj = {};
              queryParams.reqParams.map((item) => {
                obj[item.key] = item.value;
              });
              Object.assign(values, obj);
              // console.log(values)
              try {
                let res = await this.commonService.post({
                  url: queryParams.reqUrl.save,
                  params: { ...values },
                });
                return true;
              } catch (error) {
                return false;
              }
            },
          },
        },
        {
          isChange: true,
          width: "800px",
        }
      ).subscribe((res) => {
        if (res) {
          // this.clearFilterAndRefresh();
          this.getData();
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.user-container {
  border: 1px solid #e8e8e8;
  margin: 20px;
  padding: 20px;
  .table-edit,
  .table-delete {
    margin-left: 3px;
    i {
      padding: 0 2px;
    }
  }
}

.opeattion-container {
  margin: 20px 0;
}

.pagination {
  margin: 10px auto;
  text-align: right;
}
</style>
