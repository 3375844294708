<template>
  <div>
    <a-row>
      <a-col :sm="8">
        <a-button :type="'primary'" @click="_createOrUpdateFieldRole">
          <a-icon type="save" />
          <span>{{ l("Save") }}</span>
        </a-button>
        <a-button :type="'default'" @click="selectAll">
          <span>{{ l("一键全选") }}</span>
        </a-button>
      </a-col>
      <a-col :sm="16">
        <a-input-search
          name="filterText"
          :placeholder="l('InXSearchPlaceHolder', l('PermissionDisplayName'))"
          @search="onSearch"
          enterButton
          v-model="filterText"
          v-decorator="['filterText']"
        />
      </a-col>
    </a-row>

    <div class="checklist-wrapper">
      <ul>
        <li v-for="(item, index) in showList" :key="index">
          <template v-if="item.isDisplay">
            <div class="checkall">
              <a-checkbox :checked="item.isCheck" @change="onCheckAllChange($event, index)">
                {{item.name}}
              </a-checkbox>
            </div>
            <div class="checkbox-group">
              <a-checkbox-group v-model="submitList[index].fields" :options="displayList[index].dis">
                <!-- <a-row>
                  <a-col :span="6" v-for="(it, i) in item.items" :key="i">
                    <a-checkbox :value="it.key" :checked="it['isCheck']"  @change="onChange($event, index)">
                      {{it.name ? it.name : '--'}}
                    </a-checkbox>
                  </a-col>
                </a-row> -->
              </a-checkbox-group>
            </div>
          </template>
        </li>
      </ul>
    </div>

    <div class="modal-footer">
      <a-button @click="close()" type="button" :style="{marginRight: '10px'}">
        <a-icon type="close-circle" />
        {{ l('Cancel') }}
      </a-button>
      <a-button :type="'primary'" @click="_createOrUpdateFieldRole">
        <a-icon type="save" />
        {{ l('Save') }}
      </a-button>
    </div>

  </div>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
// import { PermissionServiceProxy } from "@/shared/service-proxies";
import { RoleServiceProxy } from '../services/roles-proxies'

import { myArrayService } from "@/shared/utils";

export default {
  name: "field-authority",
  mixins: [AppComponentBase],
  props: ["multiple", "dropDownStyle", "roleId"],
  data() {
    return {
      roleService: null,
      list: [],
      showList: [],
      // loading: false,
      submitList: [],
      displayList: [],
      filterText: ''
    };
  },
  computed: {},
  created() {
    this.roleService = new RoleServiceProxy(
      this.$apiUrl,
      this.$api
    );
  },
  mounted() {
    this._getFieldRoleByRoleId()
  },
  methods: {
    // 一键全选
    selectAll() {
      let arr = [...this.showList]
      arr.map(v => {
        v.isCheck = true
        if (v.items && v.items.length) {
          v.items.map(_v => {
            _v.isCheck = true
          })
        }
      })
      this.initShowLists(arr)
    },
    // 获取data
    _getFieldRoleByRoleId() {
      this.$emit('emitSpinning', '1')
      this.roleService.getFieldRoleByRoleId(this.roleId)
        .finally(() => {
          this.$emit('emitSpinning', '0')
        })
        .then((result) => {
          const list = result.map(item => {
            return {
              ...item,
              isDisplay: true,
            }
          })
          this.list = list
          this.initShowLists(list)
        })
    },
    // 初始化list
    initShowLists(result) {
      this.showList = []
      this.displayList = []
      this.submitList = []
      result.map((item, i) => {
        let submitListObj = {
          name: item.key,
          fields: []
        }
        let arr = []
        if (item.items.length) {
          let _this = this
          item.items.map(it => {
            // Id默认勾选
            if (it.key === 'Id') {
              it.isCheck = true
            }
            if (it.isCheck === true) {
              submitListObj.fields.push(it.key)
            }
            arr.push({
              label: it.name ? it.name : it.key,
              value: it.key,
              onChange: ($event) => {
                _this.onChange($event)
              }
            })
          })
        }
        this.submitList.push(submitListObj)
        this.displayList.push({dis: arr})
      })
      this.showList = result
      this.submitList.map((item, i) => {
        if (item.fields.length === this.showList[i].items.length) {
          this.showList[i].isCheck = true
        }
      })
    },
    // 搜索
    onSearch() {
      if (this.filterText) {
        // let arr = []
        // this.list.map(item => {
        //   if (item.name.indexOf(this.filterText) !== -1) {
        //     arr.push(item)
        //   }
        // })
        // // console.log(arr)
        // this.initShowLists(arr)
    
        this.showList.map(item => {
           if (item.name.indexOf(this.filterText) !== -1) {
             item.isDisplay = true
           } else {
             item.isDisplay = false
           }
        })

        this.initShowLists(this.showList)
      }
    },
    // title 全选/多选
    onCheckAllChange(e, index) {
      this.showList[index].isCheck = e.target.checked
      if (e.target.checked) {
        let arr = []
        this.showList[index].items.map(item => {
          arr.push(item.key)
        })
        this.submitList[index].fields = arr
      } else {
        this.submitList[index].fields = []
      }
    },
    onChange(e) {
      let curChildKey = e.target.value
      let getParentKey = this.getParentKey(curChildKey)
      if (getParentKey !== null) {
        let submitList = JSON.parse(JSON.stringify(this.submitList))
        let curParentKey = getParentKey.key
        let index = getParentKey.index
        submitList.map((item, i) => {
          if (item.name === curParentKey) {
            let arr = item.fields.filter(it => it === curChildKey)
            if (arr.length) {
              item.fields = item.fields.filter(it => it !== curChildKey)
            } else {
              let fields = item.fields
              fields.push(curChildKey)
              this.$set(item, 'fields', fields)
            }
          }
        })
        if (submitList[index].fields.length === this.showList[index].items.length) {
          this.showList[index].isCheck = true
        } else {
          this.showList[index].isCheck = false
        }
        this.submitList = submitList
        // console.log(this.submitList)
      }
    },
    getParentKey(childKey) {
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].items.length) {
          for (let j = 0; j < this.list[i].items.length; j++) {
            if (this.list[i].items[j].key === childKey) {
              return {key: this.list[i].key, index: i}
            }
          }
        }
      }
      return null
    },
    // 刷新
    reload() {
    },
    // 关闭modal
    close() {
      this.$emit('emitCloseModal')
    },
    // 保存
    _createOrUpdateFieldRole() {
      let flag = 0
      // console.log(this.submitList.length)
      for (let i = 0; i < this.submitList.length; i++) {
        if (this.submitList[i].fields.length) flag++
      }
      if (!flag) {
        abp.message.warn(this.l("PleaseSelectAtLeastOneItem"))
        return
      }
      this.$emit('emitSpinning', '1')
      let opts = {
        roleId: this.roleId,
        items: this.submitList,
      }
      // console.log(opts)
      this.roleService.createOrUpdateFieldRole(opts)
        .finally(() => {
          this.$emit('emitSpinning', '0')
        })
        .then((result) => {
          this.notify.success(this.l("SavedSuccessfully"))
          this.$emit('emitCloseModalSuccess')
        })
    },
  },
  watch: {}
};
</script>

<style lang="less" scoped>
.checklist-wrapper{
  ul{
    padding: 0;
    li{
      padding: 10px 0;
      list-style: none;
      .checkall{
        font-weight: bolder;
        padding: 10px 0;
        border-bottom: 1px solid #E9E9E9;
      }
      .checkbox-group{
        padding: 10px 0;
        border-bottom: 1px solid #E9E9E9;
      }
    }
  }
}
.modal-footer{
  padding: 10px 0;
  margin: 0;
}
</style>