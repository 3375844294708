import moment from "moment";
export interface IPagedResultDtoOfBlogListDto {
  totalCount: number;
  items: RolesListDto[] | undefined;
}

export class UpdateRolePermissionsInput implements IUpdateRolePermissionsInput {
  roleId: number;
  grantedPermissionNames: string[] | undefined;

  constructor(data?: IUpdateRolePermissionsInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.roleId = _data["roleId"];
      if (Array.isArray(_data["grantedPermissionNames"])) {
        this.grantedPermissionNames = [] as any;
        for (let item of _data["grantedPermissionNames"]) {
          this.grantedPermissionNames.push(item);
        }
      }
    }
  }

  static fromJS(data: any): UpdateRolePermissionsInput {
    data = typeof data === "object" ? data : {};
    let result = new UpdateRolePermissionsInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === "object" ? data : {};
    data["roleId"] = this.roleId;
    if (Array.isArray(this.grantedPermissionNames)) {
      data["grantedPermissionNames"] = [];
      for (let item of this.grantedPermissionNames) {
        data["grantedPermissionNames"].push(item);
      }
    }
    return data;
  }

  clone(): UpdateRolePermissionsInput {
    const json = this.toJSON();
    let result = new UpdateRolePermissionsInput();
    result.init(json);
    return result;
  }
}

export interface IUpdateRolePermissionsInput {
  roleId: number;
  grantedPermissionNames: string[] | undefined;
}

export class GetRoleForEditOutput implements IGetRoleForEditOutput {
  role: RoleEditDto;
  permissions: FlatPermissionDto[] | undefined;
  grantedPermissionNames: string[] | undefined;

  constructor(data?: IGetRoleForEditOutput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.role = _data["role"]
        ? RoleEditDto.fromJS(_data["role"])
        : <any>undefined;
      if (Array.isArray(_data["permissions"])) {
        this.permissions = [] as any;
        for (let item of _data["permissions"]) {
          this.permissions.push(FlatPermissionDto.fromJS(item));
        }
      }
      if (Array.isArray(_data["grantedPermissionNames"])) {
        this.grantedPermissionNames = [] as any;
        for (let item of _data["grantedPermissionNames"]) {
          this.grantedPermissionNames.push(item);
        }
      }
    }
  }

  static fromJS(data: any): GetRoleForEditOutput {
    data = typeof data === "object" ? data : {};
    let result = new GetRoleForEditOutput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === "object" ? data : {};
    data["role"] = this.role ? this.role.toJSON() : <any>undefined;
    if (Array.isArray(this.permissions)) {
      data["permissions"] = [];
      for (let item of this.permissions) {
        data["permissions"].push(item.toJSON());
      }
    }
    if (Array.isArray(this.grantedPermissionNames)) {
      data["grantedPermissionNames"] = [];
      for (let item of this.grantedPermissionNames) {
        data["grantedPermissionNames"].push(item);
      }
    }
    return data;
  }

  clone(): GetRoleForEditOutput {
    const json = this.toJSON();
    let result = new GetRoleForEditOutput();
    result.init(json);
    return result;
  }
}

export interface IGetRoleForEditOutput {
  role: RoleEditDto;
  permissions: FlatPermissionDto[] | undefined;
  grantedPermissionNames: string[] | undefined;
}

export class FlatPermissionDto implements IFlatPermissionDto {
  parentName: string | undefined;
  name: string | undefined;
  displayName: string | undefined;
  description: string | undefined;
  isGrantedByDefault: boolean;

  constructor(data?: IFlatPermissionDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.parentName = _data["parentName"];
      this.name = _data["name"];
      this.displayName = _data["displayName"];
      this.description = _data["description"];
      this.isGrantedByDefault = _data["isGrantedByDefault"];
    }
  }

  static fromJS(data: any): FlatPermissionDto {
    data = typeof data === 'object' ? data : {};
    let result = new FlatPermissionDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["parentName"] = this.parentName;
    data["name"] = this.name;
    data["displayName"] = this.displayName;
    data["description"] = this.description;
    data["isGrantedByDefault"] = this.isGrantedByDefault;
    return data;
  }

  clone(): FlatPermissionDto {
    const json = this.toJSON();
    let result = new FlatPermissionDto();
    result.init(json);
    return result;
  }
}

export interface IFlatPermissionDto {
  parentName: string | undefined;
  name: string | undefined;
  displayName: string | undefined;
  description: string | undefined;
  isGrantedByDefault: boolean;
}

export class CreateOrUpdateRoleInput implements ICreateOrUpdateRoleInput {
  role: RoleEditDto;
  grantedPermissionNames: string[] | undefined;

  constructor(data?: ICreateOrUpdateRoleInput) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
    if (!data) {
      this.role = new RoleEditDto();
    }
  }

  init(_data?: any) {
    if (_data) {
      this.role = _data["role"]
        ? RoleEditDto.fromJS(_data["role"])
        : new RoleEditDto();
      if (Array.isArray(_data["grantedPermissionNames"])) {
        this.grantedPermissionNames = [] as any;
        for (let item of _data["grantedPermissionNames"]) {
          this.grantedPermissionNames.push(item);
        }
      }
    }
  }

  static fromJS(data: any): CreateOrUpdateRoleInput {
    data = typeof data === "object" ? data : {};
    let result = new CreateOrUpdateRoleInput();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === "object" ? data : {};
    data["role"] = this.role ? this.role.toJSON() : <any>undefined;
    if (Array.isArray(this.grantedPermissionNames)) {
      data["grantedPermissionNames"] = [];
      for (let item of this.grantedPermissionNames) {
        data["grantedPermissionNames"].push(item);
      }
    }
    return data;
  }

  clone(): CreateOrUpdateRoleInput {
    const json = this.toJSON();
    let result = new CreateOrUpdateRoleInput();
    result.init(json);
    return result;
  }
}

export interface ICreateOrUpdateRoleInput {
  role: RoleEditDto;
  grantedPermissionNames: string[] | undefined;
}

export class RoleEditDto implements IRoleEditDto {
  id: number | undefined;
  displayName: string | undefined;
  isDefault: boolean;

  constructor(data?: IRoleEditDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data["id"];
      this.displayName = _data["displayName"];
      this.isDefault = _data["isDefault"];
    }
  }

  static fromJS(data: any): RoleEditDto {
    data = typeof data === "object" ? data : {};
    let result = new RoleEditDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === "object" ? data : {};
    data["id"] = this.id;
    data["displayName"] = this.displayName;
    data["isDefault"] = this.isDefault;
    return data;
  }

  clone(): RoleEditDto {
    const json = this.toJSON();
    let result = new RoleEditDto();
    result.init(json);
    return result;
  }
}

export interface IRoleEditDto {
  id: number | undefined;
  displayName: string | undefined;
  isDefault: boolean;
}

export class ListResultDtoOfRoleListDto implements IListResultDtoOfRoleListDto {
  items: RoleListDto[] | undefined;

  constructor(data?: IListResultDtoOfRoleListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      if (Array.isArray(_data["items"])) {
        this.items = [] as any;
        for (let item of _data["items"]) {
          this.items.push(RoleListDto.fromJS(item));
        }
      }
    }
  }

  static fromJS(data: any): ListResultDtoOfRoleListDto {
    data = typeof data === "object" ? data : {};
    let result = new ListResultDtoOfRoleListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === "object" ? data : {};
    if (Array.isArray(this.items)) {
      data["items"] = [];
      for (let item of this.items) {
        data["items"].push(item.toJSON());
      }
    }
    return data;
  }

  clone(): ListResultDtoOfRoleListDto {
    const json = this.toJSON();
    let result = new ListResultDtoOfRoleListDto();
    result.init(json);
    return result;
  }
}

export interface IListResultDtoOfRoleListDto {
  items: RoleListDto[] | undefined;
}

export class RoleListDto implements IRoleListDto {
  name: string | undefined;
  displayName: string | undefined;
  isStatic: boolean;
  isDefault: boolean;
  creationTime: moment.Moment;
  id: number;

  constructor(data?: IRoleListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.name = _data["name"];
      this.displayName = _data["displayName"];
      this.isStatic = _data["isStatic"];
      this.isDefault = _data["isDefault"];
      this.creationTime = _data["creationTime"]
        ? moment(_data["creationTime"].toString())
        : <any>undefined;
      this.id = _data["id"];
    }
  }

  static fromJS(data: any): RoleListDto {
    data = typeof data === "object" ? data : {};
    let result = new RoleListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === "object" ? data : {};
    data["name"] = this.name;
    data["displayName"] = this.displayName;
    data["isStatic"] = this.isStatic;
    data["isDefault"] = this.isDefault;
    data["creationTime"] = this.creationTime
      ? this.creationTime.toISOString()
      : <any>undefined;
    data["id"] = this.id;
    return data;
  }

  clone(): RoleListDto {
    const json = this.toJSON();
    let result = new RoleListDto();
    result.init(json);
    return result;
  }
}
export interface IRoleListDto {
  name: string | undefined;
  displayName: string | undefined;
  isStatic: boolean;
  isDefault: boolean;
  creationTime: moment.Moment;
  id: number;
}

export class PagedResultDtoOfRoleListDto
  implements IPagedResultDtoOfRoleListDto {
  totalCount: number;
  items: RoleListDto[] | undefined;

  constructor(data?: IPagedResultDtoOfRoleListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.totalCount = _data["totalCount"];
      if (Array.isArray(_data["items"])) {
        this.items = [] as any;
        for (let item of _data["items"]) {
          this.items.push(RoleListDto.fromJS(item));
        }
      }
    }
  }

  static fromJS(data: any): PagedResultDtoOfRoleListDto {
    data = typeof data === "object" ? data : {};
    let result = new PagedResultDtoOfRoleListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === "object" ? data : {};
    data["totalCount"] = this.totalCount;
    if (Array.isArray(this.items)) {
      data["items"] = [];
      for (let item of this.items) {
        data["items"].push(item.toJSON());
      }
    }
    return data;
  }

  clone(): PagedResultDtoOfRoleListDto {
    const json = this.toJSON();
    let result = new PagedResultDtoOfRoleListDto();
    result.init(json);
    return result;
  }
}
export interface IPagedResultDtoOfRoleListDto {
  totalCount: number;
  items: RoleListDto[] | undefined;
}


export class RolesListDto implements IRolesListDto {
  /** 一级权限key */
  key: string | undefined;
  /** 一级权限名称 */
  name: string | undefined;
  /** 编号 */
  isCheck: boolean | undefined;
  /** 二级权限列表 */
  items: object[] | undefined;

  constructor(data?: IRolesListDto) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }

  init(_data?: any) {
    if (_data) {

      this.key = _data['key']
      this.name = _data['name']
      this.isCheck = _data['isCheck']
      this.items = _data['items']

      if (Array.isArray(_data["items"])) {
        this.items = [] as any;
        for (let item of _data["items"]) {
          this.items.push(RolesListDto.fromJS(item));
        }
      }
    }
  }

  static fromJS(data: any): RolesListDto {
    data = typeof data === 'object' ? data : [];
    let result = new RolesListDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};

    data['key'] = this.key
    data['name'] = this.name
    data['isCheck'] = this.isCheck
    data['items'] = this.items

    if (Array.isArray(this.items)) {
      let arr = []
      for (let item of this.items) {
        arr.push(item);
      }
      data['items'] = arr
    }
    return data;
  }

  clone(): RolesListDto {
    const json = this.toJSON();
    let result = new RolesListDto();
    result.init(json);
    return result;
  }
}
export interface IRolesListDto {
  /** 一级权限key */
  key: string | undefined;
  /** 一级权限名称 */
  name: string | undefined;
  /** 编号 */
  isCheck: boolean | undefined;
  /** 二级权限列表 */
  items: object[] | undefined;
}


export class CreateOrUpdateFieldRoleDTO implements CreateOrUpdateFieldRole {
  roleId: string | number | undefined;
  items: CreateOrUpdateFieldRoleItems[] | undefined;

  constructor(data?: CreateOrUpdateFieldRole) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.roleId = _data["roleId"];
      this.items = _data["items"];
    }
  }

  static fromJS(data: any): CreateOrUpdateFieldRoleDTO {
    data = typeof data === 'object' ? data : {};
    let result = new CreateOrUpdateFieldRoleDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["roleId"] = this.roleId;
    data["items"] = this.items;
    return data;
  }

  clone(): CreateOrUpdateFieldRoleDTO {
    const json = this.toJSON();
    let result = new CreateOrUpdateFieldRoleDTO();
    result.init(json);
    return result;
  }
}
export interface CreateOrUpdateFieldRole {
  roleId: string | number | undefined
  items: object[] | undefined
}
export interface CreateOrUpdateFieldRoleItems {
  name: string | undefined,
  fields: string[] | undefined
}


export class RowFilterCreateOrUpdateDTO implements RowFilterCreateOrUpdate {
  filterName: string | undefined;
  filterCode: string | undefined;
  roleId: number | undefined;
  isEnabled: boolean | undefined;
  remark: string | undefined;
  filterType: string | undefined;
  roleName: string | undefined;
  id: number | undefined;

  constructor(data?: RowFilterCreateOrUpdateDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.filterName = _data["filterName"];
      this.filterCode = _data["filterCode"];
      this.roleId = _data["roleId"];
      this.isEnabled = _data["isEnabled"];
      this.remark = _data["remark"];
      this.filterType = _data["filterType"];
      this.roleName = _data["roleName"];
      this.id = _data["id"];
    }
  }

  static fromJS(data: any): RowFilterCreateOrUpdateDTO {
    data = typeof data === 'object' ? data : {};
    let result = new RowFilterCreateOrUpdateDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["filterName"] = this.filterName;
    data["filterCode"] = this.filterCode;
    data["filterName"] = this.filterName;
    data["roleId"] = this.roleId;
    data["isEnabled"] = this.isEnabled;
    data["remark"] = this.remark;
    data["filterType"] = this.filterType;
    data["roleName"] = this.roleName;
    data["id"] = this.id;
    return data;
  }

  clone(): RowFilterCreateOrUpdateDTO {
    const json = this.toJSON();
    let result = new RowFilterCreateOrUpdateDTO();
    result.init(json);
    return result;
  }
}
export interface RowFilterCreateOrUpdate {
  filterName: string | undefined
  filterCode: string | undefined
  roleId: number | undefined
  isEnabled: boolean | undefined
  remark: string | undefined
  filterType: string | undefined
  roleName: string | undefined
  id: number | undefined
}

export class RowFilterAddRowFilterToRoleDTO implements RowFilterAddRowFilterToRole {
  roleId: number | undefined;
  rowFilterId: number | undefined;

  constructor(data?: RowFilterAddRowFilterToRoleDTO) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.roleId = _data["rowFilterId"];
      this.rowFilterId = _data["rowFilterId"];
    }
  }

  static fromJS(data: any): RowFilterAddRowFilterToRoleDTO {
    data = typeof data === 'object' ? data : {};
    let result = new RowFilterAddRowFilterToRoleDTO();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["roleId"] = this.roleId;
    data["rowFilterId"] = this.rowFilterId;
    return data;
  }

  clone(): RowFilterAddRowFilterToRoleDTO {
    const json = this.toJSON();
    let result = new RowFilterAddRowFilterToRoleDTO();
    result.init(json);
    return result;
  }
}

export interface RowFilterAddRowFilterToRole {
  roleId: number | undefined
  rowFilterId: number | undefined
}