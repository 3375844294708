var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.spinning},on:{"click":_vm.clearMenu}},[_c('a-row',{staticClass:"header",attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":12}},[_c('p',{staticClass:"left"},[_c('a-icon',{attrs:{"type":"share-alt"}}),_vm._v(" "+_vm._s(_vm.l("OrganizationTree"))+" ")],1)]),(
        _vm.isGranted(
          'Pages.Administration.OrganizationUnits.ManageOrganizationTree'
        )
      )?_c('a-col',{attrs:{"span":12}},[_c('p',[_c('a',{on:{"click":function($event){return _vm.addUnit(null)}}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" "+_vm._s(_vm.l("AddRootUnit"))+" ")],1),_c('a',{attrs:{"title":_vm.l('Refresh')},on:{"click":function($event){return _vm.getData()}}},[_c('a-icon',{attrs:{"type":"reload"}})],1)])]):_vm._e()],1),_c('a-row',{staticClass:"tree"},[_c('a-tree',{attrs:{"treeData":_vm.treeData,"showIcon":"","defaultExpandAll":""},on:{"select":_vm.onSelect,"rightClick":_vm.onRightClick,"expand":_vm.onExpand},scopedSlots:_vm._u([{key:"custom",fn:function(ref){
      var expanded = ref.expanded;
return [_c('a-icon',{attrs:{"type":expanded ? 'folder-open' : 'folder'}})]}}])},[_c('a-icon',{attrs:{"slot":"folder","type":"folder"},slot:"folder"}),_c('a-icon',{attrs:{"slot":"file","type":"file"},slot:"file"}),_c('a-icon',{attrs:{"slot":"folder-open","type":"folder-open"},slot:"folder-open"})],1),(_vm.NodeTreeItem)?_c('div',{staticClass:"right-click-item",style:(_vm.tmpStyle)},[_c('ul',[(
            _vm.isGranted('Pages.Administration.OrganizationUnits.ManageUsers')
          )?_c('li',{on:{"click":function($event){return _vm.editUnit()}}},[_c('a-icon',{attrs:{"type":"edit"}}),_c('span',[_vm._v(" "+_vm._s(_vm.l("Edit"))+" ")])],1):_vm._e(),(
            _vm.isGranted('Pages.Administration.OrganizationUnits.ManageUsers')
          )?_c('li',{on:{"click":function($event){return _vm.addSubUnit()}}},[_c('a-icon',{attrs:{"type":"plus"}}),_c('span',[_vm._v(" "+_vm._s(_vm.l("AddSubUnit"))+" ")])],1):_vm._e(),(
            _vm.isGranted('Pages.Administration.OrganizationUnits.ManageUsers')
          )?_c('li',{on:{"click":function($event){$event.stopPropagation();return _vm.deleteProp($event)}}},[_c('a-popconfirm',{attrs:{"placement":"right","title":_vm.l('ConfirmDeleteWarningMessage'),"okText":_vm.l('Ok'),"cancelText":_vm.l('Cancel')},on:{"confirm":_vm.deleteconfirm,"cancel":_vm.clearMenu}},[_c('a-icon',{attrs:{"type":"delete"}}),_c('span',[_vm._v(" "+_vm._s(_vm.l("Delete"))+" ")])],1)],1):_vm._e()])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }