import "rxjs/add/operator/finally";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { SwaggerException } from "@/shared/service-proxies/model";

export class OrganizationPositionServiceProxy {
  private instance: AxiosInstance;
  private baseUrl: string;
  protected jsonParseReviver:
    | ((key: string, value: any) => any)
    | undefined = undefined;

  constructor(baseUrl?: string, instance?: AxiosInstance) {
    this.instance = instance ? instance : axios.create();
    this.baseUrl = baseUrl ? baseUrl : "";
  }

  /**
   * 删除银行账户
   * @param id
   */
  removeUserPosition(params: any | undefined): Promise<void> {
    let url_ = this.baseUrl + "/api/services/app/Position/RemoveUserPosition";

    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "DELETE",
      url: url_,
      headers: {},
      params,
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      let res =this.requestProcessed(_response);
   
      return res;
    });
  }

  /**
   * 删除银行账户
   * @param id
   */
  addUserPosition(data: any | undefined): Promise<void> {
    let url_ = this.baseUrl + "/api/services/app/Position/AddUserPosition";
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "POST",
      url: url_,
      headers: {},
      data,
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      let res =this.requestProcessed(_response);

      return res;
    });
  }

  /**
   * 获取岗位选项
   */
  getPositionOption(params: any): Promise<any> {
    let url_ = this.baseUrl + `/api/services/app/Position/GetPositionOptions`;

    url_ = url_.replace(/[?&]$/, "");
    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
      params,
    };
    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.requestProcessed(_response);
    });
  }
  /**
   * 获取用户岗位选项
   */
  getUserPositionOption(params: any): Promise<any> {
    let url_ = this.baseUrl + `/api/services/app/Position/GetUserPosition`;
    url_ = url_.replace(/[?&]$/, "");
    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
      params,
    };
    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.requestProcessed(_response);
    });
  }
  protected requestProcessed(response: AxiosResponse): Promise<any> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      let result200: any = null;
      let resultData200 = _responseText;
      // result200 = GetOrganizationExtendPagedDTO.fromJS(resultData200);
      return resultData200;
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException(
        "An unexpected server error occurred.",
        status,
        _responseText,
        _headers
      );
    }
    return Promise.resolve<any>(<any>null);
  }
}

function throwException(
  message: string,
  status: number,
  response: string,
  headers: { [key: string]: any },
  result?: any
): any {
  if (result !== null && result !== undefined) {
    throw result;
  } else {
    throw new SwaggerException(message, status, response, headers, null);
  }
}
