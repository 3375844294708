//import "rxjs/add/operator/finally";
import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {FileDto, SwaggerException} from "@/shared/service-proxies/model";
import apiHttpClient from '@/shared/utils/api-http-client';
import {AppConsts} from '@/abpPro/AppConsts';
import {throwError} from "rxjs";
import {UrlHelper} from "../../../helpers";
import {message} from "ant-design-vue";
import {formatInternalValue} from "ant-design-vue/lib/vc-tree-select/src/util";
import objectHelper from "../../../helpers/objectHelper";

let projectAuthApi = {
    instance: apiHttpClient,
    namespaces: "UnnaturalPersonProjectAuthorized", // UnnaturalPersonProjectAuthorized
    async GetListByUserId(userId) {
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/" + this.namespaces + "/GetListByUserId";
        let options_ = {
            method: "GET",
            url: url_,
            params:{userId:userId},
            headers: {
                Accept: "application/json",
            },
        };
        console.log(options_)
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data.items
    },
     async add(auth){
         let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/" + this.namespaces + "/SaveProjectAuthorized";
         let options_ = {
             method: "post",
             url: url_,
             headers: {
                 Accept: "application/json",
                 "Content-Type": "application/json-patch+json",
             },
             data: auth
         };
         console.log(options_)
         const _response = await this.getInstance().request(options_);
         console.log(_response)
         if (_response.status != 200) {
             console.log('请求异常')
             console.log(_response)
             throwError(_response.data)
             //todo toastProjectIdFilter
         }
         return _response.data
     },
      async remove(projectId,userId){
          let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/" + this.namespaces + "/DeleteProjectAuthorized";
          let options_ = {
              method: "post",
              url: url_,
              headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json-patch+json",
              },
              data: {projectId,userId}
          }
          ;
          const _response = await this.getInstance().request(options_);
          console.log(_response)
          if (_response.status != 200) {
              console.log('请求异常')
              console.log(_response)
              throwError(_response.data)
          }
          return _response.data
      },
    initParams(url, params) {
        if (params.length == 0)
            return url

        url += '?'
        for (let index in params) {
            url += params[index].name + '=' + params[index].value + '&'
        }
        return url.substring(0, url.length - 1)
    },
    getInstance: function () {
        return this.instance = this.instance ? this.instance : axios.create()
    },
}

export default projectAuthApi